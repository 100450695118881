// 비밀번호 재설정
.findpw-page {
	.subject {
		margin-bottom: 48px;
		font-size: $font-size-md;
		font-weight: 500;
		line-height: 130%;
		letter-spacing: -0.24px;
	}

	.subject-area {
		margin-bottom: 48px;

		.subject {
			margin-bottom: 4px;
		}

		.subtext {
			font-size: $font-size-base;
			font-weight: 400;
			line-height: 130%;
			letter-spacing: -0.24px;
			color: $font-gray;
		}
	}

	.form-group {
		min-height: 126px;
	}

	.form-item {
		&:last-child {
			min-height: 239px;
		}
		&:first-child {
			min-height: auto;
		}
	}

	.error-text {
		& + .form-item {
			margin-top: 40px;
		}
	}

	.input-group-form {
		.form-control {
			& + .guide-text,
			& + .error-text,
			& + .waiting-text {
				margin-top: 9px;
			}
		}
		& + .error-text,
		& + .waiting-text {
			margin-top: 9px;
		}

		.guide-text {
			& + .form-control {
				margin-top: 12px;
			}

			&.error {
				color: $font-red;
			}
		}
	}

	.certi-number-area {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 9px;

		* {
			font-size: $font-size-sm;
			font-weight: 400;
			line-height: 130%;
			letter-spacing: -0.14px;
			color: $primary500;
		}

		&.waiting {
			p {
				color: $font-green;
			}
		}

		&.invalid {
			span,
			p {
				color: $font-red;
			}
		}
	}
}
