.modal-opened {
	// position: fixed;
	// width: 100%;
	// .wrapper {
	// 	margin-right: 24px;
	// }
	overflow: hidden;
}

.modal-popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1100;
	display: flex;
	justify-content: center;
	align-items: center;

	.dimed {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.3);
		// backdrop-filter: blur(10px);
	}

	.popup-inner {
		position: relative;
		z-index: 1100;
		transition-duration: 0.3s;
		transition-property: transform;
		// will-change: transform;
		background-color: #fff;
		overflow: hidden;
		border-radius: 20px;

		box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.2);

		&.hide {
			opacity: 0;
			visibility: hidden;

			.popup-inner {
				transform: translateY(-50px);
			}
		}

		&.show {
			opacity: 1;
			visibility: visible;
			transition-duration: 0.3s;

			.popup-inner {
				transform: translateY(0);
			}
		}

		.popup-footer {
			padding: 24px 24px;

			.btn-group {
				flex-wrap: nowrap;
				gap: 8px;
				width: 100%;

				.btn {
					flex: 1 1 auto;
					min-width: calc(50% - 3px);
					max-width: 100%;
				}
			}
		}
	}

	.btn-close-popup {
		position: absolute;
		z-index: 100;
		right: 24px;
		top: 20px;
		width: 24px;
		height: 24px;
		border-radius: 0;
		background: url(#{$url-img}/ico-close-gray@2x.png) center center no-repeat;
		background-size: 24px 24px;
	}

	@include media-breakpoint-down(sm) {
		.popup-inner {
			// width: auto !important;
			max-height: calc(100% - 40px) !important;
			margin: 0 20px;
		}
	}
}

.modal-bottom-sheet {
	height: 100%;
	align-items: flex-end;

	.popup-header,
	.popup-footer {
		flex: 0 0 auto;
	}

	.popup-inner {
		display: flex;
		flex-direction: column;

		width: 100%;
		height: auto;
		max-height: calc(100vh - 56px);
		border-radius: 16px 16px 0 0;
	}

	.popup-header {
		display: flex;
		align-items: center;
		height: 51px + 8px;
		padding: 8px 50px 0 24px;
	}

	.popup-title {
		font-size: 18px;
		font-weight: 700;
	}

	.popup-body {
		flex: 1 1 auto;
		padding: 0 24px 24px;
		overflow-y: auto;
		overflow-x: hidden;
		@include media-breakpoint-up(lg) {
			padding-right: 8px;
		}
	}

	&.no-dimed {
		height: auto;
		top: auto;

		.dimed {
			display: none;
		}
	}

	.btn-group-flex {
		.btn[type="reset"] {
			flex: 0 0 auto;
			padding: 0 45px;
		}
	}

	.form-group {
		padding-bottom: 40px;
	}
}

.modal-full {
	.popup-inner {
		border-radius: 0;
		width: 100%;
		height: 100%;

		display: flex;
		flex-direction: column;

		// transition-duration: 0s !important;
	}

	.btn-close-popup {
		top: 15px;
		right: 14px;
	}

	.popup-header {
		padding: 14px 48px 0 24px;
		.popup-title {
			font-size: 18px;
			font-weight: 700;
		}
	}

	.popup-body {
		flex: 1 1 auto;
		padding: 2px 24px 12px;
		overflow-y: scroll;
		@include media-breakpoint-up(lg) {
			padding-right: 8px;
		}
	}
	.btn-group-flex {
		gap: 0;

		.btn-lightgray {
			flex: 0 0 128px;
		}
	}
}

.modal-sm {
	.popup-inner {
		display: flex;
		flex-direction: column;

		width: calc(100% - 40px);
		max-width: 480px;
		height: auto;
		min-height: 278px;
		max-height: calc(100vh - 160px);
		border-radius: 20px;
	}

	.popup-header {
		position: relative;
		display: flex;
		align-items: center;
		min-height: 56px + 8px;
		padding: 8px 50px 8px 24px;

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background: $gray300;
		}
	}

	.popup-title {
		font-size: $font-size-lg;
		font-weight: 700;
		color: $font-default;
		line-height: 140%;
	}

	.popup-body {
		flex: 1 1 auto;
		padding: 0 24px 24px;
		overflow-y: scroll;
		@include media-breakpoint-up(lg) {
			padding-right: 8px;
		}
	}

	&.no-dimed {
		height: auto;
		top: auto;

		.dimed {
			display: none;
		}
	}

	.btn-group-flex {
		.btn[type="reset"] {
			flex: 0 0 auto;
			padding: 0 45px;
		}
	}
}

.modal-md {
	.popup-inner {
		display: flex;
		flex-direction: column;

		width: calc(100% - 40px);
		max-width: 800px;
		height: auto;
		min-height: 278px;
		max-height: calc(100vh - 160px);
		border-radius: 20px;
	}

	.popup-header {
		display: flex;
		align-items: center;
		height: 56px + 8px;
		min-height: 64px;
		padding: 8px 72px 8px 24px;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background: $gray300;
		}
	}

	.popup-title {
		font-size: 18px;
		font-weight: 700;
	}

	.popup-body {
		flex: 1 1 auto;
		padding: 24px;
		overflow-y: scroll;

		& + .popup-footer {
			margin-top: -24px;
			padding-top: 24px;
		}

		@include media-breakpoint-up(lg) {
			padding-right: 8px;
		}
	}

	&.no-dimed {
		height: auto;
		top: auto;

		.dimed {
			display: none;
		}
	}

	.btn-group-flex {
		.btn[type="reset"] {
			flex: 0 0 auto;
			padding: 0 45px;
		}
	}
}

.modal-default {
	.popup-inner {
		width: 400px;
		max-width: calc(100% - 40px);
		border-radius: 16px;

		display: flex;
		flex-direction: column;
		// transition-duration: 0s !important;
	}

	.btn-close-popup {
		top: 20px;
		right: 24px;
	}

	.popup-header {
		display: flex;
		align-items: center;
		min-height: 64px;
		padding: 14px 24px;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background: $gray300;
		}

		.popup-title {
			font-size: 18px;
			font-weight: 700;
		}
	}

	.popup-body {
		flex: 1 1 auto;
		padding: 24px 24px 16px;
		overflow-y: scroll;
		@include media-breakpoint-up(lg) {
			padding-right: 8px;
		}
	}

	.btn-group-flex {
		gap: 0;

		.btn-lightgray {
			flex: 0 0 128px;
		}
	}
}

.modal-confirm {
	.popup-inner {
		width: 400px;

		display: flex;
		flex-direction: column;
		// transition-duration: 0s !important;

		.popup-footer {
			padding: 24px 36px 36px;
		}
	}

	.popup-header {
		padding: 36px 36px 0;
		.popup-title {
			font-size: $font-size-lg;
			font-weight: 700;
		}
	}

	.popup-body {
		flex: 1 1 auto;
		padding: 12px 36px 16px;
	}
}

.modal-alert {
	.popup-inner {
		width: 400px;

		.popup-footer {
			padding: 24px 36px 36px;
		}
	}
	.popup-header {
		padding: 36px 36px 0;
	}
	.popup-body {
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 12px 36px 16px;
		max-height: calc(100vh - 300px);
		overflow-y: auto;
	}

	.icon {
		width: 56px;
		height: 56px;
		margin: 0 auto 4px;
	}

	.popup-title {
		font-size: $font-size-lg;
		font-weight: 700;
		line-height: 25px;
		letter-spacing: -0.36px;
	}

	.message {
		font-size: $font-size-base;
		text-align: center;
	}

	.sub-message {
		display: inline-block;
		margin: 4px 0 20px;
		font-size: $font-size-sm;
		font-weight: 400;
		color: $font-gray;
	}
}

.layer-popup {
	position: absolute;
	z-index: 1000;
	transition-duration: 0.3s;
	transition-property: opacity;

	&.hide {
		opacity: 0;
		visibility: hidden;
	}

	&.show {
		opacity: 1;
		visibility: visible;
	}

	.popup-inner {
		position: relative;
		z-index: 1100;
		transition-duration: 0.3s;
		transition-property: transform;
		background-color: $bg-white;
		border-radius: 20px;
		border: 1px solid $gray300;
		box-shadow: 0 6px 9px rgba(#000, 0.2);
		overflow: hidden;

		width: 400px;
		height: auto;

		&:focus {
			outline: 0;
			// box-shadow: 0 0 20px 5px rgba(#fff, .2);
		}

		.modal-popup.hide & {
			transform: scale(0.9);
		}

		.modal-popup.show & {
			transform: scale(1);
		}
	}

	.popup-header {
		justify-content: flex-start;
		height: 50px;
		padding: 0 19px;
		background-color: $bg-gray;

		.popup-title {
			display: flex;
			align-items: center;
			padding-top: 6px;
			color: $default;
			font-weight: 600;

			.ico {
				margin-top: -4px;
				margin-right: 4px;
			}
		}

		~ .btn-close-popup {
			top: 0;
			right: 0;
			width: 50px;
			height: 50px;
			// background-image: url(#{$url-img}/ico-times-gray@2x.png);
			background-size: 20px auto;
		}
	}

	.popup-body {
		padding: 24px;
		overflow-y: scroll;
		@include media-breakpoint-up(lg) {
			padding-right: 8px;
		}

		.btn-group {
			margin-top: 12px;
		}
		.tab-content {
			display: block;
		}
	}
}

.search-popup {
	.popup-header {
		display: none;
	}
	.popup-body {
		padding: 0;
		overflow: hidden;
	}
	.btn-close-popup {
		top: 20px;
		right: 24px;
	}
	.search-bar {
		height: 64px;
		padding: 0 24px;
		border-bottom: 1px solid $gray300;
		form {
			height: 100%;
		}
		.btn-search {
			position: absolute;
			top: 20px;
			left: 24px;
		}
		.form-control-md {
			min-width: calc(100% - 48px);
			height: 100%;
			padding: 0 24px 0 48px;
			border: 0;
		}
	}
	.scroll-inner {
		overflow: hidden;
	}

	// 검색 결과
	.search-result {
		display: flex;
		align-items: center;
		align-items: flex-start;
		justify-content: center;
		min-height: 234px;
		max-height: 536px;
		padding: 0 8px 8px 24px;
		overflow: scroll;
		@include scrollbar;

		.default-nodata {
			display: flex;
			align-items: center;
			min-height: 210px;
			height: 100%;
			padding-top: 12px;
			font-size: $font-size-xl;
			font-weight: 400;
			text-align: center;
			color: $font-lightgray;
		}
		.result-nodata {
			display: flex;
			align-items: center;
			min-height: 210px;
			height: 100%;
			padding-top: 12px;
			font-size: $font-size-xl;
			font-weight: 400;
			text-align: center;
			color: $font-lightgray;
		}

		.search-data {
			margin-right: 2px;
			font-weight: 500;
			color: $font-default;
		}
		.result-data {
			display: flex;
			flex-direction: column;
			width: 100%;

			.title3 {
				margin: 0;
			}
		}
		.result-header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 18px 12px;
			border-bottom: 1px solid $gray200;
		}
		.guide-text {
			color: $font-gray;
			font-size: $font-size-xs;
			font-weight: 400;
		}

		.list-result {
			& > li {
				height: 72px;
				border-bottom: 1px solid $gray200;

				a {
					display: flex;
					flex-direction: row;
					align-items: center;
					height: 100%;
					padding: 18px 12px;

					&:hover {
						background-color: $bg-lightgray;
					}
				}
			}
		}
		.service-type {
			flex: 0 0 auto;
			+ .wrok-name {
				margin-left: 12px;
			}
		}
		.wrok-name {
			flex: 1 1 auto;
			display: flex;
			align-items: center;
			width: calc(100% - 208px);
			font-size: $font-size-base;
			font-weight: 500;
			color: $font-default;
			.file-name {
				// min-width: calc(100% - 85px);
				@include text-ellipsis(1, 1.4);
			}
			.label-shared {
				flex: 0 0 auto;
				display: flex;
				align-items: center;
				gap: 2px;
				margin-left: 8px;
				padding: 6px 8px 6px 6px;
				border-radius: 100px;
				border: 1px solid $gray300;
				background: $bg-lightgray;
				color: $font-gray;
				font-size: $font-size-xs;
				font-weight: 500;
			}
		}

		.last-date {
			flex: 0 0 auto;
			display: inline-block;
			width: 160px;
			font-size: $font-size-sm;
			font-weight: 400;
			color: $font-gray;
			text-align: center;
		}
	}
}
