.img2html-work-detail-page {
	@include media-breakpoint-up(lg) {
		.img2html-container {
			display: flex;
			height: 100%;
			.col {
				flex: 1 1 auto;
				width: 50%;
				overflow: hidden;
			}
		}

		.col-viewer {
			max-width: 50%;
		}
	}

	.col-viewer {
		background: $gray50;
		overflow: hidden;
	}

	.col-editor {
		display: flex;
		flex-direction: column;
		height: 100%;
		.preview {
			flex: 1 1 auto;
			border: 0;
			width: 100%;
		}
		.form-control {
			height: 200px;
		}
	}

	.handle {
		flex: 0 0 auto;
		width: 8px;
		background: $gray200;
	}

	.loading-area {
		height: 100%;
		background: $gray50;
	}

	@include media-breakpoint-down(md) {
		.col-viewer {
			height: calc(50vh - #{$titlebar-height});
		}

		.col-editor {
			min-height: calc(50vh - 80px);
		}
	}
}

// 에디터 영역
.editor-wrap {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	height: 100%;
	.edit-area {
		flex: 1 1 auto;
		// padding: 16px 0 16px 16px;
		overflow-y: scroll;
	}
}

// 공유하기 팝업
.share-popup {
	.box {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 24px 0 12px;
	}
	.ico {
		flex: 0 0 auto;
		+ span {
			margin-left: 12px;
		}
	}
	.share-file {
		flex: 1 1 auto;
		font-weight: 500;
		@include text-ellipsis;
	}
}
