//
// Icons
//

.ico {
	@include ir;
}

/* 범용 비규격 아이콘  */
@include ico2x("search-gray", 16px, 16px);

/* 레이아웃 공통 아이콘 */
// sidebar
@include ico2x("dashboard-md", 16px, 16px);
@include ico2x("alarm-md", 16px, 16px);
@include ico2x("help-md", 16px, 16px);
@include ico2x("community-md", 16px, 16px);
@include ico2x("resource-md", 16px, 16px);
@include ico2x("workspace-md", 16px, 16px);
@include ico2x("recentwork-md", 16px, 16px);
@include ico2x("delete-md", 16px, 16px);
@include ico2x("img2html-md", 36px, 36px);
@include ico2x("contrast-md", 36px, 36px);
@include ico2x("seo-md", 36px, 36px);
@include ico2x("linter-md", 36px, 36px);
@include ico2x("table-md", 36px, 36px);
@include ico2x("tool-md-primary", 16px, 16px);
@include ico2x("arrow-down-sm", 8px, 8px);
@include ico2x("arrow-up-sm", 8px, 8px);
@include ico2x("arrow-right-sm-gray", 12px, 12px);
@include ico2x("link-sm", 12px, 12px);
@include ico2x("link-md", 16px, 16px);
@include ico2x("search-lg", 24px, 24px);

@include ico2x("file-pdf", 20px, 20px);
@include ico2x("pencil-md-gray", 16px, 16px);
@include ico2x("handshake-md-gray", 16px, 16px);
@include ico2x("tool-md-white", 16px, 16px);

// popup
@include ico2x("download-light-xs", 16px, 16px);
@include ico2x("download-dark-xs", 16px, 16px);
@include ico2x("notify-xs", 16px, 16px);
@include ico2x("close-xs", 12px, 12px);
@include ico2x("close-xs-dark", 8px, 8px);
@include ico2x("handle-sm-gray", 12px, 12px);
@include ico2x("thumb-up-white", 16px, 16px);
@include ico2x("thumb-up-dark", 16px, 16px);
@include ico2x("thumb-down-white", 16px, 16px);
@include ico2x("thumb-down-dark", 16px, 16px);

// page
@include ico2x("img2html-sm", 20px, 20px);
@include ico2x("contrast-sm", 20px, 20px);
@include ico2x("seo-sm", 20px, 20px);
@include ico2x("linter-sm", 20px, 20px);
@include ico2x("table-sm", 20px, 20px);
@include ico2x("listview", 20px, 20px);
@include ico2x("cardview", 20px, 20px);
@include ico2x("tool-lg-primary", 20px, 20px);

@include ico2x("img2html-lg", 43px, 43px);
@include ico2x("contrast-lg", 43px, 43px);
@include ico2x("seo-lg", 43px, 43px);
@include ico2x("linter-lg", 43px, 43px);
@include ico2x("table-lg", 43px, 43px);
@include ico2x("arrow-right-lg", 24px, 24px);
@include ico2x("caret-up-darkgray-md", 24px, 24px);
@include ico2x("more-action", 16px, 16px);

@include ico2x("edit-sm", 16px, 16px);
@include ico2x("cloud-sm", 16px, 16px);

@include ico2x("shared-md", 16px, 16px);
@include ico2x("shared-sm", 12px, 12px);
@include ico2x("download-white-sm", 16.5px, 16.5px);
@include ico2x("cancel-xs", 12px, 12.5px);
@include ico2x("cancel-black-xs", 12px, 12.5px);
@include ico2x("delete-xs", 12px, 12.5px);
@include ico2x("delete-white-xs", 12px, 12.5px);
@include ico2x("add-sm", 12px, 12px);

// tool pages
@include ico2x("service-img2html-lg", 40px, 40px);
@include ico2x("service-contrast-lg", 40px, 40px);
@include ico2x("service-seo-lg", 40px, 40px);
@include ico2x("service-linter-lg", 40px, 40px);
@include ico2x("service-table-lg", 40px, 40px);
@include ico2x("tool-md", 16px, 16px);
@include ico2x("question-md", 16px, 16px);
@include ico2x("copy-xs", 12px, 12px);
@include ico2x("copy-sm", 16px, 16px);
@include ico2x("copy-white-sm", 16px, 16px);
@include ico2x("copy-gray-sm", 16px, 16px);
@include ico2x("arrow-left-white", 16px, 16px);
@include ico2x("check-green", 16px, 16px);
@include ico2x("warning-sm", 16px, 16px);
@include ico2x("info-md", 20px, 20px);
@include ico2x("info-sm", 16px, 16px);
@include ico2x("export-white-sm", 16px, 16px);
@include ico2x("cancel-md", 16px, 16px);
@include ico2x("zoomin-xs", 12px, 12px);
@include ico2x("zoomout-xs", 12px, 12px);
@include ico2x("reset-xs", 12px, 12px);
@include ico2x("pass", 32px, 32.5px);
@include ico2x("checking-red", 12px, 12.5px);
@include ico2x("checking-white", 12px, 12.5px);
@include ico2x("exclamation-mark", 12px, 12.5px);
@include ico2x("fail-sm", 16.5px, 16px);
@include ico2x("pass-sm", 16px, 16px);
@include ico2x("background-sm", 16px, 16px);
@include ico2x("text-color-sm", 16px, 16px);
@include ico2x("arrow-right-sm", 16px, 16px);
@include ico2x("workspace-black-sm", 16px, 16px);
@include ico2x("check-white-xs", 12px, 12px);
@include ico2x("close-black-md", 24px, 24px);
@include ico2x("share-sm", 16px, 16px);
@include ico2x("share-white-sm", 16px, 16px);
@include ico2x("download-primary-sm", 16px, 16px);
@include ico2x("linkclip-sm", 16px, 16px);
@include ico2x("caret-up-darkgray-sm", 16.5px, 16.5px);
@include ico2x("caret-down-darkgray-sm", 16.5px, 16.5px);
@include ico2x("check-blue", 12px, 12px);
@include ico2x("caption-tag-no", 14.5px, 14.5px);
@include ico2x("caption-tag-yes", 14.5px, 14.5px);
@include ico2x("caption-exist", 14.5px, 14.5px);
@include ico2x("violation-sm", 16px, 16px);

// 전체메뉴
// @include ico-svg("gnb-schedule", 16px, 16px);
// @include ico-svg("gnb-medication", 16px, 16px);

// Alerts
// @include ico-svg("lg-warning", 56px, 56px);
// @include ico-svg("lg-info", 56px, 56px);

// pages
// @include ico-svg("tv", 20px, 20px);
// @include ico-svg("messege", 16px, 16px);

@include ico2x("arrow-left-gray", 24px, 24px);

// 에디터
@include ico2x("handle", 16px, 16px);
@include ico2x("undo", 16px, 16px);
@include ico2x("redo", 16px, 16px);
@include ico2x("paragraph-sm", 16px, 16px);
@include ico2x("bold", 16px, 16px);
@include ico2x("italic", 16px, 16px);
@include ico2x("underline", 16px, 16px);
@include ico2x("bullet-list", 16px, 16px);
@include ico2x("ordered-list", 16px, 16px);
@include ico2x("table", 16px, 16px);
@include ico2x("link", 16px, 16px);
@include ico2x("strike", 16px, 16px);
@include ico2x("plus-sm", 16px, 16px);
@include ico2x("del-sm", 16px, 16px);
@include ico2x("cellmerge-sm", 16px, 16px);
@include ico2x("celldivision-sm", 16px, 16px);
@include ico2x("columntitle-sm", 16px, 16px);
@include ico2x("rowtitle-sm", 16px, 16px);
@include ico2x("no-repeat-sm", 16px, 16px);

// 공유하기
@include ico2x("notify", 16px, 16px);
@include ico2x("profile-sm", 20.5px, 20px);
@include ico2x("link-sm-white", 12.5px, 12px);
