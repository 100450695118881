.footer-landing {
	background: $gray700;
	color: $font-white;
	// padding: 60px $spacing 60px + $promotion-banner-height; // 배너 높이만큼 공간 확보
	padding: 60px $spacing 60px 0;

	.container {
		position: relative;
		max-width: 1200px;
		margin: auto;
		padding: 0 !important;
	}

	.footer-menu {
		a,
		button {
			font-size: 14px;
			font-weight: inherit;
		}
	}

	.address {
		// margin-right: 40px;
		word-break: keep-all;
		font-style: normal;
		color: $gray400;
		font-size: $font-size-xs;
		line-height: 1.3;
	}

	.company-name {
		display: block;
		font-weight: 700;
		color: $font-white;
		font-size: $font-size-sm;

		.ci {
			display: block;
			height: 24px;
			margin-bottom: 20px;
		}
	}

	.company-info {
		margin-top: 8px;

		li {
			+ li {
				margin-top: 4px;
			}
		}

		span {
			opacity: 0.4;
			margin: 0 0.5em;
		}
	}

	.copyright {
		margin-top: 8px;
		font-size: 10px;
	}

	.sns-list {
		display: flex;
		gap: 12px;
		img {
			width: 36px;
			height: 36px;
			vertical-align: top;
		}
	}

	@include media-breakpoint-up(md) {
		.footer-menu {
			display: flex;
			gap: 40px;
			margin-bottom: 40px;
		}

		.sns-list {
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}

	@include media-breakpoint-between(md, lg) {
		padding-left: 24px !important;
	}

	@include media-breakpoint-down(sm) {
		// padding: $spacing $spacing $spacing + 64px;
		padding: $spacing;
		.footer-menu {
			li {
				+ li {
					margin-top: 24px;
				}
			}
		}

		.address {
			margin-top: 40px;

			.company-info {
				line-height: 20px;

				li {
					+ li {
						margin-top: 6px;
						padding-top: 6px;
						border-top: 1px solid $gray600;
					}
				}

				span {
					display: block;
					font-size: 0;
					line-height: 0;
				}

				a,
				a[href^="tel"] {
					color: inherit !important;
					text-decoration: none !important;
					pointer-events: none;
					cursor: default;
				}
			}
		}

		.sns-list {
			margin-top: 40px;
		}
	}
}
