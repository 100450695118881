@mixin clearfix {
	&:after {
		display: block;
		clear: both;
		content: "";
	}
}

@mixin ir {
	display: inline-block;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	vertical-align: middle;
	overflow: hidden;

	text-indent: -9999px;
	overflow: hidden;
	text-align: left;
}

@mixin label-focus($border-radius: 2px) {
	&:focus {
		+ label,
		+ .label {
			outline: 2px solid #000;

			@if ($border-radius) {
				border-radius: $border-radius;
			}
		}
	}

	&:not(:focus-visible) {
		+ label,
		+ .label {
			outline: 0;
		}
	}
}

//
// icons
//
$icon-size-xs: 8px;
$icon-size-sm: 12px;
$icon-size-md: 16px;
$icon-size-lg: 24px;
$icon-size-xl: 80px;

$icon-sizes: () !default;
$icon-sizes: map-merge(
	(
		"xs": $icon-size-xs,
		"sm": $icon-size-sm,
		"md": $icon-size-md,
		"lg": $icon-size-lg,
		"xl": $icon-size-xl
	),
	$icon-sizes
);

// 1:1 사이즈 아이콘 : PC 전용
@mixin ico($name, $w: 25px, $h: 25px, $url: #{$url-img}) {
	.ico-#{$name} {
		width: $w;
		height: $h;
		line-height: $h;
		background-image: url("#{$url}/ico-#{$name}.png");
	}
}

// PC 모바일 겸용 아이콘 : 이미지 2배수 저장 @2x
@mixin ico2x($name, $w: 25px, $h: 25px, $url: #{$url-img}) {
	.ico-#{$name} {
		width: $w;
		height: $h;
		line-height: $h;
		background-image: url("#{$url}/ico-#{$name}@2x.png");
	}
}

// PC 모바일 이미지 별도 저장시 : 모바일 이미지만 @2x
@mixin ico2($name, $w: 25px, $h: 25px, $w-m: $w, $h-m: $h, $url: #{$url-img}) {
	@include media-breakpoint-up(lg) {
		.ico-#{$name} {
			width: $w;
			height: $h;
			line-height: $h;
			background-image: url("#{$url}/ico-#{$name}.png");
		}
	}

	@include media-breakpoint-down(md) {
		.ico-#{$name} {
			width: $w-m;
			height: $h-m;
			line-height: $h-m;
			background-image: url("#{$url}/ico-#{$name}@2x.png");
		}
	}
}

@mixin text-ellipsis($row: 2, $line-height: 20px, $height-fixed: false) {
	@if $row > 1 {
		display: -webkit-box;
		-webkit-line-clamp: $row;
		-webkit-box-orient: vertical;
		white-space: normal;
	} @else {
		max-width: 100%;
		white-space: nowrap;
	}

	@if $height-fixed {
		height: $row * $line-height;
		max-height: none;
	} @else {
		max-height: $row * $line-height;
	}

	line-height: $line-height;
	text-overflow: ellipsis;
	overflow: hidden;

	// Flex나 Grid 레이아웃에서 최소 너비를 설정해 너비가 늘어나는 문제 방지
	min-width: 0;
	flex-shrink: 1;
}

// @mixin container {
// 	max-width: $max-width;

// 	//@if ($mode="responsive") {
// 	@if ($mode) {
// 		@include media-breakpoint-down(md) {
// 			width: auto;
// 		}
// 	}
// }

@mixin scrollbar($thumb-width: 4px, $track-padding: 6px, $thumb-color: rgba(#000, 0.15), $track-color: transparent) {
	// css 브라우저 스크롤바 스타일 지정, 바꾸는 방법 알아보기
	// https://webisfree.com/2019-01-08/css-브라우저-스크롤바-스타일-지정-바꾸는-방법-알아보기

	::-webkit-scrollbar {
		width: $track-padding * 2 + $thumb-width;
		height: $track-padding * 2 + $thumb-width;
		background-color: transparent;
	}

	::-webkit-scrollbar-thumb {
		background-color: $thumb-color;
		border-radius: ($track-padding * 2 + $thumb-width) * 0.5;
		background-clip: padding-box;
		border: $track-padding solid transparent;

		&:hover {
			background-color: darken($thumb-color, 5%);
		}

		&:active {
			background-color: darken($thumb-color, 10%);
		}
	}

	::-webkit-scrollbar-track {
		background-color: $track-color;
	}

	::-webkit-scrollbar-corner {
		background-color: $track-color;
	}

	// Firefox
	@supports (-moz-appearance: none) {
		* {
			scrollbar-width: thin;
			scrollbar-color: $thumb-color $track-color;
		}

		&:hover {
			scrollbar-color: darken($thumb-color, 5%) $track-color;
		}

		&:active {
			scrollbar-color: darken($thumb-color, 10%) $track-color;
		}
	}
}

@mixin hover-effect {
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
}
