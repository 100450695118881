.contrast-analysis {
	position: relative;
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	background: $bg-white;
	overflow-y: scroll;
	@include scrollbar;
	@include media-breakpoint-up(lg) {
		padding-right: 24px - 16px;
	}

	.title-area {
		flex: 0 0 auto;
		display: flex;

		@include media-breakpoint-up(lg) {
			align-items: center;
			justify-content: space-between;
			padding: 24px 0 16px 24px;
		}

		@include media-breakpoint-down(md) {
			flex-direction: column;
			gap: 8px;
			padding: 16px;

			.right-area {
				display: flex;
				justify-content: flex-end;
			}
		}

		.title3 {
			margin: 0;
			font-weight: 700;
			line-height: 25px;
			letter-spacing: -0.36px;
		}

		.analysis-info {
			font-size: $font-size-md;
			font-weight: 400;
			line-height: 21px;
			letter-spacing: -0.24px;
			color: $font-gray;
		}

		.left-area {
			display: inline-flex;
			align-items: center;
			gap: 8px;
		}
	}

	.level {
		display: inline-flex;
		align-items: center;
		gap: 13px;
		height: 24px;
		margin-right: 4px;
		padding: 0 8px;
		border-radius: 100px;
		border: 1px solid #5bcd9e;
		background: #e2fff3;

		* {
			font-size: $font-size-sm;
			line-height: 18px;
			font-weight: 500;
			letter-spacing: -0.14px;
		}

		span {
			&:first-child {
				position: relative;
				font-weight: 700;

				&::before {
					content: "";
					position: absolute;
					top: calc(50% - 5px);
					right: -8px;
					width: 1px;
					height: 10px;
					border-radius: 100px;
				}
			}
		}

		&.level-a {
			border: 1px solid $gray300;
			background: $bg-lightgray;
			color: $font-default;

			span {
				&:first-child {
					&::before {
						background: $gray300;
					}
				}
			}
		}
		&.level-aa {
			border: 1px solid #5bcd9e;
			background: #e2fff3;
			color: #085b39;

			span {
				&:first-child {
					&::before {
						background: #5bcd9e;
					}
				}
			}
		}
		&.level-aaa {
			border: 1px solid $border-lightprimary;
			background: $bg-lightprimary;
			color: $font-primary;

			span {
				&:first-child {
					&::before {
						background: $primary400;
					}
				}
			}
		}
	}

	&.step-hide {
		.right-area {
			visibility: hidden;
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
	}

	.contrast-info-container {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
	}

	.no-result {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		font-weight: bold;
		font-size: $font-size-lg;
		text-align: center;

		@include media-breakpoint-down(md) {
			padding: 60px 0 80px;
		}
	}

	.btn-close {
		position: absolute;
		top: 30px;
		right: 8px;
		@include media-breakpoint-down(sm) {
			top: 17px;
		}
	}
}

.contrast-info-item {
	position: relative;
	background: $white;
	cursor: pointer;
	transition: 0.1s;
	transition-property: opacity;
	padding: 24px 0 24px 24px;
	@include media-breakpoint-down(md) {
		padding: 16px;
	}

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 24px;
		width: calc(100% - 24px);
		height: 1px;
		background: #d9d9d9;
		@include media-breakpoint-down(md) {
			left: 16px;
			right: 16px;
			width: auto;
		}
	}

	&:last-child {
		border-bottom: none;
	}

	&:not(.active) {
		opacity: 0.5;
		transition: all 0.3s;

		&:hover {
			opacity: 1;

			.info-item {
				&-before {
					background: $bg-lightgray;
				}
			}
		}
	}

	&.active {
		.info-item {
			&-before {
				background: $bg-lightgray;
			}
		}
	}

	> dt {
		display: flex;
		align-items: center;
		height: 21px;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		margin-bottom: 12px;

		.num {
			display: inline-block;
			min-width: 20px;
			height: 20px;
			margin-right: 4px;
			border-radius: 50%;
			background: $bg-darkgray;
			text-align: center;
			font-size: $font-size-xs;
			font-weight: 500;
			line-height: 20px;
			color: $font-white;
		}

		.checking-area {
			display: inline-flex;
			align-items: center;
			gap: 2px;
			height: 21px;
			margin-left: 4px;
			padding: 0 6px;
			border-radius: 100px;
			background: #fee2e2;
			font-size: $font-size-xs;
			font-weight: 500;
			color: $red600;
		}
	}

	> dd {
		display: flex;
		border-radius: 12px;
		border: 1px solid $gray300;
		overflow: hidden;
	}

	.info-item {
		flex: 0 0 50%;
		padding: 19px;

		.label {
			display: flex;
			align-items: center;
			gap: 3px;
			margin: 12px 0 4px;
			font-size: 12px;
			font-weight: 500;
			color: $font-gray;

			small {
				font-size: 10px;
				font-weight: normal;
				color: $font-lightgray;
			}

			.level {
				gap: 9px;
				height: 16px;
				margin-right: 0;
				padding: 0 6px;

				* {
					font-size: 10px;
					line-height: 10px;
				}

				span {
					&:first-child {
						font-weight: 500;

						&::before {
							top: calc(50% - 3.5px);
							right: -5px;
							height: 7px;
						}
					}
				}
			}
		}

		&-before {
			background: $bg-gray;
			border-right: 1px solid $gray300;
		}

		&-after {
			position: relative;
			background: $white;
			&::before {
				content: "";
				box-sizing: border-box;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 24px;
				height: 24px;
				border: 1px solid $gray300;
				border-radius: 50%;
				background: $white url(#{$url-img}/ico-arrow-double-right-@2x.png) center center no-repeat;
				background-size: 12px 12px;
			}
		}

		.title {
			display: flex;
			align-items: center;
			gap: 4px;
			font-size: $font-size-sm;
			font-weight: 700;
			line-height: 18px;
			letter-spacing: -0.14px;
			color: $font-default;
		}

		.sub-ject {
			font-size: 10px;
			font-weight: 400;
			line-height: 13px;
			letter-spacing: -0.1px;
			color: $font-gray;
		}

		.text {
			font-size: $font-size-md;
			font-weight: 700;
			line-height: 21px;
			letter-spacing: -0.24px;
		}

		.color-info {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 8px;

			> .color-info-item,
			> .chip-item {
				flex: 1 1 50%;
			}
		}

		.color-info-item {
			&:first-child {
				color: $font-lightgray;
			}
		}

		.check-item-sm {
			width: 50%;
		}

		.preview-pannel {
			display: flex;
			justify-content: space-between;
			align-items: center;

			margin-top: 8px;
			padding: 12px;
			border: 1px solid $gray300;
			border-radius: 12px;
		}

		.contrast-ratio {
			flex: 0 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			min-width: 120px;
			height: 38px;
			padding: 0 9px;
			border-radius: 8px;
			border: 1px solid $gray300;
			background: $bg-white;
			box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);

			span {
				font-size: $font-size-xs;
				font-weight: 400;
				line-height: 16px;
				letter-spacing: -0.12px;
				color: $font-gray;
			}

			strong {
				display: inline-flex;
				align-items: center;
				gap: 4px;
				font-size: $font-size-sm;
				font-weight: 700;
				line-height: 18px;
				letter-spacing: -0.14px;
				color: $font-default;
			}

			.ico {
				min-width: 16px;
			}
		}

		.chip-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 40px;
			padding: 0px 12px;
			gap: 10px;
			border-radius: 8px;
			border: 1px solid $gray200;
			background: $white;
			text-transform: uppercase;

			&.checked {
				border: 2px solid $primary500;
				padding: 0px 11px;
				background: $bg-lightprimary;
			}
		}

		.check-item-xs {
			position: relative;
			padding: 0px 12px 0 36px;
			border: 1px solid $border-base;

			input {
				position: absolute;
				width: 1px;
				height: 1px;
				opacity: 0;
			}

			&:before {
				position: absolute;
				top: calc(50% - 8px);
				left: 11px;
				box-sizing: border-box;
				display: inline-block;
				width: 16px;
				height: 16px;
				vertical-align: middle;
				border-radius: 2px;
				background-color: $bg-white;
				content: "";

				transition-duration: 0.2s;
				transition-property: border-color, box-shadow, background-color;
				will-change: border-color, box-shadow, background-color;
				border-radius: 50%;
				box-shadow: inset 0 0 0 1px $border-base;
			}

			.color-value {
				color: $font-default;
				margin-bottom: 2px;
			}

			&.checked {
				padding: 0px 11px 0 35px;

				&:before {
					box-sizing: border-box;
					border-color: $border-active;
					background-color: $bg-white;
					box-shadow: 0 0 0 5px $bg-primary inset;
				}

				&:hover {
					&:before {
						box-shadow: inset 0 0 0 5px $bg-primary;
					}
				}

				&.disabled {
					border-color: $gray300;
					background: $bg-lightgray;

					&:before {
						border-color: $gray300;
						background-color: $bg-white;
						box-shadow: 0 0 0 5px $gray300 inset;
					}
				}

				&.readonly {
					border-color: $gray300;
					background: $bg-white;

					&:before {
						border-color: $gray300;
						background-color: $bg-white;
						box-shadow: 0 0 0 5px $gray300 inset;
					}
				}
			}

			&:hover {
				background: $bg-lightprimary;
				cursor: pointer;

				&:before {
					box-shadow: inset 0 0 0 1px $bg-gray;
					background-color: $bg-gray;
				}
			}

			&.disabled {
				border-color: $gray200;
				background: $bg-white;

				&::before {
					background: $bg-lightgray;
					box-shadow: inset 0 0 0 1px $gray200;
				}
			}
		}

		.chip {
			flex: 0 0 auto;
			width: 24px;
			height: 24px;
			border-radius: 50%;
			border: 1px solid $gray200;
		}

		.color-value {
			display: inline-flex;
			align-items: center;
			gap: 10px;

			font-size: $font-size-sm;
			font-weight: 500;
			line-height: 18px;
			letter-spacing: -0.14px;
		}

		.btn-question {
			position: absolute;
			bottom: 63px;
			right: 24px;
		}
	}

	@media (max-width: 1788px) {
		> dd {
			flex-direction: column;
		}

		.info-item {
			&-before {
				border-right: none;
				border-bottom: 1px solid $gray300;
			}

			&-after {
				&::before {
					content: "";
					position: absolute;
					left: 50%;
					top: 0;
					background: $white url(#{$url-img}/ico-arrow-double-bottom@2x.png) center center no-repeat;
					background-size: 12px 12px;
				}
			}
		}
	}
}
