// 공통 팝업
// 이 작업 휴지통으로 이동 팝업
.delete-sub-text {
	margin-top: 4px;
	font-size: $font-size-sm;
	font-weight: 400;
	line-height: 130%;
	letter-spacing: -0.14px;
	color: $font-gray;
}

// 작업명 변경 팝업
.change-work-name-popup {
	.btn-group {
		flex-wrap: nowrap;
		gap: 8px;
	}

	.characters-long {
		display: inline-flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		margin-top: 9px;
		font-size: $font-size-sm;
		font-weight: 400;
		line-height: 130%;
		letter-spacing: -0.14px;
		color: $font-lightgray;
	}
}

// 더보기 레이어팝업
.more-popup {
	.popup-inner {
		width: 179px;
		border-radius: 12px;
	}

	.popup-body {
		padding: 8px 6px;
		overflow-y: hidden;

		.btn-group {
			gap: 0;
			margin-top: 0;
		}

		.btn {
			justify-content: flex-start;
			width: 100%;
			padding: 0 12px;
			font-size: $font-size-sm;
			font-weight: 500;
			line-height: 130%;
			letter-spacing: -0.14px;
			color: $font-darkgray;
			text-align: left;

			&:hover,
			&:active {
				background: $bg-lightgray;
			}
		}
	}
}

// 테이블 형태 목록
.workitem-wrap {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	.workitem-header {
		flex: 0 0 auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 40px;

		@include media-breakpoint-down(md) {
			height: auto;
			.title1 {
				white-space: nowrap;
			}

			.btn-area {
				display: none;
			}
		}
	}

	.title-area,
	.btn-area {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;
		gap: 12px;
	}

	.workitem-body {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		height: 100%;
		margin-top: 24px;

		@include media-breakpoint-down(md) {
			overflow-x: auto;
			margin-left: -20px;
			margin-right: -20px;
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.btn-area {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 41px;

		.btn-search {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 28px;
			height: 28px;
			border-radius: 4px;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				top: 2px;
				left: 48px;
				width: 1px;
				height: 24px;
				border-radius: 100px;
				background: $gray300;
			}
		}
	}

	.selected-option {
		display: flex;
		align-items: center;
		height: 40px;
		padding: 0 16px;
		border-radius: 9px;
		border: 1px solid $border-base;
		background: $bg-white;

		.option-name {
			display: inline-flex;
			align-items: center;
			gap: 6px;
		}

		.btn-cancel {
			padding: 5px 0 5px 10px;
			border-radius: 0;
		}

		&.hidden {
			display: none;
		}
	}

	// .status-area {
	// 	margin-left: 16px;
	// }

	.checked-btn-area {
		display: flex;
		align-items: center;
		white-space: nowrap;
	}

	.checked-num {
		margin-right: 16px;
		font-size: $font-size-sm;
		font-weight: 500;
		line-height: 18px;
		letter-spacing: -0.14px;
		color: $primary500;
	}

	.btn-outline-gray {
		& + .btn-outline-gray {
			margin-left: 6px;
		}
	}

	.more-action {
		display: inline-block;
		width: 40px;
		height: 40px;
		border-radius: 8px;

		&:hover {
			background: $bg-gray;
		}
		&.on {
			background: $bg-gray;
		}

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
		}
	}
}

// 카드 형태 목록
.workitem-card-wrap {
	.sort-area {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 20px;
		height: 24px;
	}

	.btn-sort {
		transition: all 0.3s;
		overflow: hidden;

		&.on {
			transform: rotate(180deg);
		}
	}

	.btn-sort-division {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		gap: 4px;
		font-size: $font-size-md;
		font-weight: 400;
		text-align: center;
		line-height: 21px;
		color: $font-lightgray;
	}

	.dropdown {
		width: auto;
		min-width: 56px;
		dd {
			position: absolute;
			top: 22px;
			left: initial;
			right: 20px;
		}
	}

	.data-area {
		display: inline-flex;
		align-items: center;
		gap: 9px;
	}

	.file-size {
		font-size: $font-size-xs;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: -0.12px;
		color: $font-gray;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: 2px;
			right: -5px;
			width: 1px;
			height: 12px;
			border-radius: 100px;
			background: $gray300;
		}
	}
	.date-time,
	.last-date {
		font-size: $font-size-xs;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: -0.12px;
		color: $font-gray;
	}
}
