.layout-default {
	#content {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;

		& > div {
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;
			gap: 20px;
		}
	}

	.content-box {
		flex: 1 1 auto;
	}

	@include media-breakpoint-up(lg) {
		position: relative;
		display: flex;
		flex-direction: row;
		height: 100%;
		min-width: $max-width;
		overflow: hidden;
		&.mode-public {
			flex-direction: column;
		}
	}

	@include media-breakpoint-up(md) {
		background-color: $bg-lightgray;

		.container {
			padding: $spacing-md;
			padding-right: $spacing-md - 16px; // 우측 scrollbar 영역 계산
			overflow-y: scroll;
			@include scrollbar;
		}

		.content-box {
			padding: 40px;
			border-radius: 36px;
			background: $bg-white;
		}
	}

	@include media-breakpoint-down(md) {
		display: flex;
		flex-direction: column;
		min-height: 100%;

		.container {
			padding: $mobile-spacing;
		}
	}
}
