.card-list {
	display: flex;
	flex-wrap: wrap;
	margin: 10px -10px -10px -10px;

	li {
		position: relative;
		width: calc(20% - 20px);
		// min-width: 248px;
		padding: 16px;
		margin: 10px;
		border-radius: 16px;
		// border: 1px solid $gray200;
		// background: $bg-white;
		background: $gray100;

		&:hover {
			background: $bg-lightgray;
		}

		&.checked {
			position: relative;
			background: $bg-lightprimary;

			&::before {
				content: "";
				position: absolute;
				top: -1px;
				left: -1px;
				width: 100%;
				height: 100%;
				border-radius: 18px;
				border: 2px solid $border-primary;
			}
		}
	}

	.check-item {
		position: absolute;
		top: 18px;
		left: 16px;
	}

	.more-action-wrap {
		position: absolute;
		top: 18px;
		right: 20px;
		width: 24px;
		height: 24px;

		.more-popup {
			position: absolute;
			left: auto;
			right: -1px;
		}

		.more-action,
		.btn-action {
			width: 100%;
			height: 100%;
			border-radius: 4px;
		}
	}

	.card-header {
		display: flex;
		align-items: center;
		height: 24px;
		padding: 0 28px;
	}

	.card-body {
		margin-top: 10px;
		margin-bottom: 10px;
		width: 100%;
		height: 140px;
		max-height: 140px;

		.img {
			display: inline-flex;
			width: 100%;
			height: 100%;
			max-height: 140px;
			border-radius: 12px;
			// border: 1px solid $gray300;
			background: $bg-lightgray;
			overflow: hidden;

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: top;
			}

			&.no-img {
				align-items: center;
				justify-content: center;
				background: rgba(#fff, 0.5);

				img {
					width: 64px;
					height: 64px;
					object-fit: cover;
					object-position: center;
				}
			}
		}
	}

	.card-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 20px;
	}

	h3.file-name {
		display: flex;
		align-items: center;
		gap: 4px;
		@include text-ellipsis(1, 1);

		.file-name {
			font-size: $font-size-md;
			font-weight: 500;
			letter-spacing: -0.24px;
			color: $font-default;
			text-align: left;
			@include text-ellipsis(1, 1);
			line-height: 21px;
		}
	}

	.label-shared {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-width: 20px;
		height: 20px;
		border-radius: 50%;
		border: 1px solid $gray300;
		background: $bg-lightgray;
	}

	.service-type {
		min-width: 20px;
		height: 20px;

		.ico {
			width: 100%;
			height: 100%;
			margin-bottom: 2px;
		}
	}

	.data-area {
		display: inline-flex;
		align-items: center;
		gap: 9px;
	}

	.file-size {
		font-size: $font-size-xs;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: -0.12px;
		color: $font-gray;
	}
	.date-time,
	.last-date {
		font-size: $font-size-xs;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: -0.12px;
		color: $font-gray;
	}

	@media (max-width: 1782px) {
		li {
			width: calc(25% - 20px);
			flex: 0 0 auto;
		}
	}
	@media (max-width: 1514px) {
		li {
			width: calc(33.33% - 20px);
		}
	}
	@include media-breakpoint-down(md) {
		li {
			width: calc(50% - 20px);
		}
	}
	@include media-breakpoint-down(sm) {
		li {
			width: calc(100% - 20px);
		}
	}
}
