#widget {
	.community-widget {
		display: flex;
		flex-direction: column;
		gap: 40px;

		.title {
			display: flex;
			align-items: center;
			height: 24px;
			color: var(--gray-text-primary, #1f2937);
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 12px;
		}
	}

	.popular {
		position: relative;
		margin-top: 8px;

		.popular-resources {
			padding: 12px 8px;
			border-radius: 20px;
			background: var(--white, #fff);

			.info {
				max-width: 188px;
			}
		}

		.more {
			position: absolute;
			right: 0;
			top: 0;
			height: 24px;
			display: flex;
			align-items: center;
		}
	}

	.sns-list {
		padding: 8px;
		border-radius: 20px;
		background: var(--white, #fff);

		li {
			+ li {
				border-top: 1px solid var(--gray200, #e5e7eb);
			}
		}

		a {
			display: flex;
			padding: 12px;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: 4px;
			align-self: stretch;

			&:hover,
			&:focus {
				border-radius: 16px;
				background: var(--gray100, #f3f4f6);
			}
		}

		strong {
			display: flex;
			align-items: center;
			gap: 4px;
		}

		span {
			font-size: $font-size-sm;
		}
	}

	@include media-breakpoint-up(lg) {
		width: $widget-width;
		flex: 0 0 $widget-width;
	}

	@include media-breakpoint-up(md) {
		position: sticky;
		top: 40px;
		max-height: calc(100vh - 80px);
	}

	@include media-breakpoint-between(sm, md) {
		margin-top: 40px;

		.community-widget {
			display: grid;
			gap: 20px;
			grid-template-columns: repeat(2, 1fr);
			grid-auto-rows: minmax(min-content, max-content);
		}
	}

	@include media-breakpoint-down(sm) {
		padding: $mobile-spacing * 2 $mobile-spacing;
	}
}
