.d-up-lg {
	@include media-breakpoint-down(md) {
		display: none !important;
	}
}

.d-up-md {
	@include media-breakpoint-down(sm) {
		display: none !important;
	}
}

.d-down-md {
	@include media-breakpoint-up(lg) {
		display: none !important;
	}
}

.d-down-sm {
	@include media-breakpoint-up(md) {
		display: none !important;
	}
}
