.community-page {
	.page-header {
		position: sticky;
		top: -40px;
		text-align: center;
	}

	.category-filter {
		border-top: 1px solid $gray100;
		padding-top: 24px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 12px;

		.row {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 12px;
		}

		.btn {
			color: var(--gray-text-secondary, #4b5563);
			&.active {
				font-weight: 500;
				color: $font-primary;
			}
		}

		@include media-breakpoint-down(xxl) {
			font-size: $font-size-sm;
			gap: 8px;

			.row {
				gap: 8px;
			}
		}
	}

	.default-nodata {
		height: auto;
	}

	.article-list {
		margin-top: 24px;
		display: flex;
		// flex-direction: column;
		flex-wrap: wrap;
		gap: 20px;
	}

	@include media-breakpoint-down(sm) {
		.mode-public & {
			width: 100%;

			.category-filter {
				max-width: 340px;
				margin: auto;
			}
		}
	}
}

.community-input {
	width: 510px;
	max-width: 100%;
	margin: 24px auto;

	fieldset {
		position: relative;
		display: flex;
		gap: 20px;
		align-items: center;
	}

	.emoji {
		flex: 0 0 auto;
		display: flex;

		// display: none;

		width: 48px;
		height: 48px;
		justify-content: center;
		align-items: center;
		border-radius: 24px;
		background: var(--gray200, #e5e7eb);

		padding: 0;
		font-size: 20px;
		line-height: 1;
	}

	.form-control {
		height: 72px;
		border-radius: 50px;
		padding: 0 94px 0 24px;
		border: 1px solid var(--primary300, #bfdbfe);
		background: #fff;
		box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
		font-size: 18px;

		&::placeholder {
			color: var(--gray-text-disabled, #6b7280);
			font-size: 18px;
		}
	}

	.btn-md {
		position: absolute;
		right: 12px;
		display: flex;
		width: 70px;
		height: 40px;
		border-radius: 20px;
		font-size: 14px;
	}

	@include media-breakpoint-down(sm) {
		.form-control {
			font-size: 16px;

			&::placeholder {
				font-size: 16px;
			}
		}

		.emoji {
			display: none;
		}
	}
}
