// DashboardPage
.dashboard-page {
	gap: 32px !important;

	@include media-breakpoint-up(xxxl) {
		.promotion-banner {
			flex: 1 1 360px;
		}
	}
}
