// dropdown
.dropdown {
	flex: 0 1 auto;
	display: inline-block;
	min-width: 80px;
	width: 100%;
	max-width: 100%;
	vertical-align: middle;

	&.full {
		width: 100%;
	}

	&.dropdown-up {
		.table-scroll-x & {
			// &.on {
			// .btn-select {
			// 	border-radius: 0 0 9px 9px;
			// }
			// }

			dd {
				top: auto;
				bottom: 34px;
			}

			// .option-list {
			// 	border-radius: 9px 9px 0px 0px;
			// }
		}
	}

	// &:hover {
	// 	.btn-select {
	// 		border-color: $border-hover;
	// 	}
	// }

	dt {
		position: relative;
	}

	.select {
		color: $font-default;
		position: relative;
	}

	.btn-select {
		width: 100%;
		height: $form-height-md;
		padding: 0 42px 0 16px;

		font-size: $font-size-base;
		text-align: left;
		white-space: nowrap;

		background-size: $icon-size-md;
		background-repeat: no-repeat;
		background-position: right 16px center;
		background-image: url(#{$url-img}/ico-caret-down-gray-md@2x.png);
		background-color: $bg-white;

		border-radius: 9px;
		border: 1px solid $border-base;

		&:disabled {
			border-color: $border-light;
			pointer-events: none;
			background-color: $bg-gray;
		}
		span {
			display: flex;
			align-items: center;
			line-height: 1.3;
		}
		.ico {
			margin-right: 8px;
		}
	}
	.btn-cancel {
		position: absolute;
		top: calc(50% - $form-height-md/2 + 2px);
		right: 2px;

		width: 40px;
		height: $form-height-md - 4px;

		background-color: $bg-white;
		border-radius: 8px;
		> button {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			padding: 0 16px 0 10px;
			border-radius: 8px;
		}
	}
	&.on {
		.btn-select {
			background-image: url(#{$url-img}/ico-caret-up-gray-md@2x.png);
		}
	}

	dd {
		display: none;
		position: absolute;
		top: $form-height-md;
		left: 0;
		right: 0;
		z-index: 100;
	}

	.option-list {
		min-width: 235px;
		// max-height: 328px;
		padding: 8px;
		overflow-y: auto;
		border: 1px solid $gray300;
		border-radius: 12px;
		background-color: $bg-white;
		box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);

		li {
			width: 100%;
		}

		a,
		button {
			display: flex;
			align-items: center;
			padding: 12.5px 12px;
			border-radius: 6px;
			font-size: $font-size-base;
			white-space: nowrap;

			&:hover {
				background: $bg-lightgray;
			}

			&:active {
				background: $bg-lightgray;
				// 	background-color: $bg-primary;
				// 	color: $font-primary;
			}
		}

		.ico {
			margin-right: 8px;
		}

		button {
			width: 100%;
			text-align: left;
		}
	}

	&.on {
		border-color: $border-light;
		position: relative;
		z-index: 10;

		// .btn-select {
		// 	border-radius: 9px 9px 0 0;
		// }

		dd {
			display: block;
		}
	}

	&-sm {
		height: 21px;
		flex: 0 1 auto;
		display: inline-block;
		// min-width: 80px;
		vertical-align: middle;

		.select {
			position: relative;
			display: inline-block;
		}

		.btn-select {
			height: 20px;
			padding: 0 12px 0 0;

			background-size: 8px auto;
			background-repeat: no-repeat;
			background-position: right 0 center;
			background-image: url(#{$url-img}/ico-arrow-down-sm@2x.png);
			background-color: $bg-white;
			border: none;
			border-radius: 0;
			font-size: $font-size-base;
			font-weight: 400;
			text-align: right;
			color: $font-lightgray;
			line-height: 1;

			span {
				display: inline-block;
				line-height: 1;
			}
		}

		dd {
			display: none;
			position: absolute;
			top: 22px;
			right: 0;
			z-index: 100;
			min-width: 136px;
		}

		.option-list {
			min-width: calc(100% + 20px);
			padding: 8px;
			overflow-y: auto;
			border: 1px solid $gray300;
			border-radius: 12px;
			background-color: $bg-white;
			box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);

			li {
				width: 100%;
			}

			a,
			button {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				height: 40px;
				padding: 0 12px;
				border-radius: 6px;
				font-size: $font-size-sm;
				font-weight: 500;
				line-height: 18px;
				letter-spacing: -0.14px;
				color: $font-default;
				white-space: nowrap;

				&:hover {
					background: $bg-lightgray;
				}
				&:active {
					background: $bg-lightgray;
				}
			}

			button {
				width: 100%;
				text-align: left;
			}
		}

		&.on {
			.btn-select {
				background-image: url(#{$url-img}/ico-arrow-up-sm@2x.png);
			}
			dd {
				display: block;
			}
		}
	}
}
