table {
	width: 100%;
	border-spacing: 0;
}

.table {
	// width: 100%;
	// border-collapse: collapse;

	table {
		table-layout: fixed;
		// width: calc(100% + 1px);
		// margin-right: -1px;
		border-collapse: collapse;
		border-left: hidden;
		border-right: hidden;
		border-bottom: 1px solid $gray200;
	}

	thead {
		word-break: keep-all;
	}

	thead ~ tbody,
	thead ~ tfoot {
		text-align: center;
	}

	caption + tbody,
	colgroup + tbody {
		> tr:first-child {
			> th,
			> td {
				border-top: 0;
			}
		}
	}

	th {
		height: 48px;
		font-size: $font-size-md;
		font-weight: 400;
		text-align: center;
		color: $font-lightgray;
	}
	td {
		height: 76px;
		border-top: 1px solid $gray200;
		text-align: center;
	}

	thead {
		text-align: center;
	}
}

// 작업 리스트
.table-task {
	width: 100%;
	min-width: 900px;

	tr {
		&.on {
			background: $bg-lightprimary;
		}
	}
	th {
		height: 48px;
		text-align: center;

		&.text-left {
			.btn-sort {
				justify-content: flex-start;
			}
		}

		.btn-sort {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			gap: 4px;
			width: 100%;
			font-size: $font-size-md;
			font-weight: 400;
			text-align: center;
			line-height: 21px;
			color: $font-lightgray;

			.ico {
				transform: rotate(0deg);
			}

			&.sort {
				.ico {
					transform: rotate(180deg);
				}
			}
		}
	}
	td > a {
		display: flex;
		align-items: center;
	}

	.service-type {
		+ .task-name {
			margin-left: 12px;
		}
	}
	// .check-item-wrap {
	// 	background-color: $bg-white;
	// }
	.more-action-wrap {
		position: relative;

		.more-popup {
			position: absolute;
			left: auto;
			right: 0;
		}
	}
	tbody > tr:hover {
		cursor: pointer;
		background-color: $bg-lightgray;
		.check-item-wrap,
		.more-action-wrap {
			background-color: $bg-lightgray;
		}
	}

	.task-name {
		width: calc(100% - 48px);
		text-align: left;
		display: flex;
		align-items: center;
	}
	.file-name {
		// flex: 1 1 auto;
		display: inline-block;
		text-align: left;
		@include text-ellipsis(1, 1.4);
	}
	.label-shared {
		flex: 0 0 auto;
		display: flex;
		align-items: center;
		gap: 2px;
		margin-left: 8px;
		padding: 6px 8px 6px 6px;
		border-radius: 100px;
		border: 1px solid $gray300;
		background: $bg-lightgray;
		color: $font-gray;
		font-size: $font-size-xs;
		font-weight: 500;
	}
	.file-type,
	.file-size,
	.date-time,
	.last-date {
		font-size: $font-size-sm;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: -0.14px;
		color: $font-gray;
	}
}

// 데어터 없을 경우
.default-nodata {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	min-height: 480px;

	.nodata-warp {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 24px;
	}

	p {
		font-size: $font-size-md;
		font-weight: 400;
		line-height: 130%;
		letter-spacing: -0.24px;
		text-align: center;
		color: $font-gray;
	}

	img {
		width: 84px;
		height: 100px;
	}
}
