#root {
	height: 100%;
}

.btn-totop {
	position: fixed;
	z-index: 10;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);

	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: 1px solid var(--gray300, #d1d5db);
	background: var(--white, #fff);
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);

	transition: 0.3s;

	&.hide {
		opacity: 0;
		visibility: hidden;
	}

	&.show {
		opacity: 1;
		visibility: visible;
	}
}

// .wrapper {
// 	min-height: 100vh;
// }

#skip {
	li {
		list-style: none;
	}

	a {
		display: block;
		position: absolute;
		top: 0;
		margin-bottom: -1px;
		width: 100%;
		height: 1px;
		text-align: center;
		text-decoration: none;
		font-size: 18px;
		overflow: hidden;
		z-index: 600;

		&:focus,
		&:active {
			height: 45px;
			line-height: 45px;
			color: #fff;
			background: #666;
		}
	}
}
