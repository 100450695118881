/* 사이드 바 */
$sidebar-header-height: 76px;
$sidebar-header-mobile-height: 68px;
// $bubble-shadow: 1px 1px 13px 2px rgba(0, 0, 0, 0.15);

#sidebar {
	flex: 0 0 $sidebar-width;
	height: 100vh;
	top: 0;
	background-color: $gray50;
	border-right: 1px solid $gray300;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
	position: sticky;
	z-index: 200;

	display: flex;
	flex-direction: column;

	font-size: $font-size-sm;

	.sidebar-header {
		flex: 0 0 auto;
	}

	.sidebar-body {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		overflow-y: auto;
	}

	#gnb-area {
		flex: 1 1 auto;
	}

	.nodata {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		justify-content: center;
		height: 100%;

		strong {
			display: block;
			margin-bottom: 4px;
			line-height: 18.2px;
			font-weight: 500;
		}
		span {
			font-size: 12px;
			line-height: 15.6px;
			color: $font-lightgray;
		}
	}

	@include media-breakpoint-down(md) {
		display: none;
		height: unset;
		border: unset;

		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.sidebar-header {
	display: flex;
	height: $sidebar-header-height;
	align-items: center;
	justify-content: space-between;

	padding: 20px;

	.sidebar-title {
		flex: 0 0 auto;

		a {
			display: block;
			font-size: 10px;
		}

		img {
			vertical-align: top;
			height: 24px;
		}
	}

	@include media-breakpoint-down(md) {
		display: none;
	}
}

.sidebar-body {
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(md) {
		padding-top: 20px;
	}

	.site-search {
		flex: 0 0 auto;
		padding: 0 20px 16px;
	}

	.btn-search {
		justify-content: space-between;
		width: 100%;
		height: $form-height-sm;
		padding: 7px 15px;
		border-radius: 9px;
		background-color: $bg-gray;
		border: 1px solid $gray200;

		font-size: $font-size-sm;
		color: $font-lightgray;
		vertical-align: middle;
		line-height: $form-height-md - 2px - 14px;

		& + .nav-area-top {
			margin-top: 16px;
		}
	}

	.label {
		display: inline-flex;
		height: 18px;
		padding: 0 6px 0 4px;
		justify-content: center;
		align-items: center;
		border-radius: 100px;
		font-size: 10px;
		font-weight: 500;

		&.new {
			color: var(--black, #000);
			background: var(--secondary500, #bafd02);
			// border: 1px solid #000;
		}

		&:before {
			content: "";
			background-image: url("data:image/svg+xml,%3Csvg%20width%3D%228%22%20height%3D%228%22%20viewBox%3D%220%200%208%208%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M3.68605%200.239006C3.77449%20-0.0796677%204.22634%20-0.079669%204.31478%200.239004L4.95268%202.53752C4.97753%202.62706%205.03937%202.70175%205.1227%202.74286L7.07772%203.70742C7.32025%203.82708%207.32025%204.17292%207.07772%204.29258L5.1227%205.25714C5.03937%205.29825%204.97753%205.37294%204.95268%205.46248L4.31478%207.76099C4.22634%208.07967%203.77449%208.07967%203.68605%207.761L3.04815%205.46248C3.0233%205.37294%202.96146%205.29825%202.87813%205.25714L0.923109%204.29258C0.680578%204.17292%200.680578%203.82708%200.92311%203.70742L2.87813%202.74286C2.96146%202.70175%203.0233%202.62706%203.04815%202.53752L3.68605%200.239006Z%22%20fill%3D%22black%22%2F%3E%3C%2Fsvg%3E");
			background-size: contain;
			background-repeat: no-repeat;
			width: 8px; /* 아이콘 크기 설정 */
			height: 8px; /* 아이콘 크기 설정 */
		}
	}

	.nav-area-top {
		.ico-link-md {
			margin-bottom: 2.5px;
		}
		.list-sidebar {
			&::after {
				content: none;
			}
		}
	}

	.nav-area-mid {
		padding: 16px 20px;

		.count {
			min-width: 25px;
			text-align: right;
			font-size: $font-size-xs;
			color: $font-lightgray;
			& + .ico {
				margin-left: 4px;
			}
		}
		.list-sidebar {
			li.on {
				.ico-arrow-down-sm {
					background: url(#{$url-img}/ico-arrow-up-sm@2x.png);
					background-size: 8px auto;
				}
			}
		}
	}
	.list-sidebar {
		position: relative;
		width: 100%;

		&::after {
			position: absolute;
			top: -16px;
			left: 0;
			right: 0;
			width: 100%;
			height: 1px;

			background-color: $gray200;
			content: "";
		}

		> li {
			padding-left: 0;
			& + li {
				margin-top: 4px;
			}

			&::before {
				content: none;
			}

			a {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 4px 8px;
				border: 1px solid transparent;
				border-radius: 8px;
				font-size: $font-size-sm;
				line-height: 1.5;
				color: $font-default;

				&:hover {
					border: 1px solid $gray300;
					background: $bg-lightgray;
				}
			}
			&.on {
				> a {
					border: 1px solid $gray300;
					background: $bg-lightgray;
				}
			}
			.text {
				font-weight: 500;
			}
		}
		.tit {
			display: flex;
			align-items: center;
			gap: 8px;

			.ico {
				min-width: 16px;
			}
		}
	}

	.nav-area-tools {
		flex: 0 0 auto;
		padding: 16px 20px;

		.list-sidebar {
			li {
				+ li {
					margin-top: 2px;
				}
				a {
					height: 40px;
					font-size: 15px;
					padding: 0 8px;
				}

				.ico {
					width: 24px;
					height: 24px;
				}
			}
		}
	}
}

.sidebar-footer {
	flex: 0 0 auto;
	margin: 0 20px;

	.contact-area {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 4px;
		padding: 0 0 20px;
		font-size: $font-size-sm;

		.line {
			width: 1px;
			height: 18px;
			flex: 0 0 auto;
			background: #d9d9d9;
		}

		.btn {
			flex: 1 1 auto;
			gap: 6px;
			font-size: inherit;
			padding: 0;
		}

		.btn-sm {
			font-size: inherit;
		}

		.btn-md {
			flex: 1 1 100%;
		}
	}

	// 프로필
	.user-info {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid $gray200;
		padding: 16px 0;

		.user-info-area {
			flex: 1 1 auto;
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			.profile {
				display: flex;
				width: 100%;
			}
			.profile-thumb {
				flex: 0 0 auto;
				position: relative;
				display: inline-block;
				width: 32px;
				height: 32px;
				border-radius: 50%;
				background-color: $bg-secondary;
				vertical-align: middle;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
				& + .profile-name {
					margin-left: 8px;
				}
			}
			.profile-name {
				flex: 1 1 auto;

				display: flex;
				align-items: center;
				width: calc(100% - 32px);
				text-align: left;
				font-size: $font-size-sm;

				.name {
					@include text-ellipsis(2, 1em);
					+ .more-info {
						margin-left: 8px;
					}
				}
			}
		}

		.use-info-num {
			flex: 0 0 auto;
			margin-left: 8px;

			a {
				display: flex;
				align-items: center;
			}
			.ico {
				flex: 0 0 auto;
				+ .use-num {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin-left: 4px;
				}
			}
			.remain-text {
				flex: 0 0 auto;
				font-weight: 400;
				color: $font-gray;
			}
			.num {
				flex: 1 1 auto;
				margin-left: 4px;
				font-weight: 700;
			}
		}
	}
}

// 알람
.alarm-popup {
	margin-left: 24px;
}

.setting-popup {
	margin-bottom: 16px;

	.popup-inner {
		width: 129px;
		border-radius: 12px;
	}
	.popup-body {
		padding: 8px;
		overflow-y: hidden;
	}
	.info-detail {
		.btn-group {
			gap: 0;
			margin: 0;
		}
		.btn {
			display: flex;
			white-space: nowrap;
			justify-content: space-between;
			width: 100%;
			padding: 0 12px;
			font-weight: 500;
			color: $font-default;
			&:hover {
				background-color: $bg-lightgray;
			}
		}
	}
}
