#top-banner {
	flex: 0 0 auto !important;
	flex-direction: row !important;
	text-align: center;
	display: flex;
	justify-content: center;
	width: 100%;
	background: var(--black, #000);

	// transition: 0.3s;

	// &.slide-up {
	// 	transform: translateY(-100%);
	// }

	.inner {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 12px 8px;
	}

	.link {
		display: flex;
		align-items: center;
		gap: 8px;

		.message {
			color: var(--secondary500, #bafd02);
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			text-align: center;
		}

		.btn {
			display: flex;
			align-items: center;
			gap: 2px;
			color: var(--white, #fff);
			text-align: center;

			font-size: 14px;
		}
	}

	.btn-banner-close {
		position: absolute;
		height: 24px;
		right: 60px;

		.text {
			font-size: $font-size-sm;
			font-weight: normal;
			color: var(--white, #fff);
		}
	}

	@at-root .btn-expand-banner {
		position: fixed;
		top: 0;
		right: 60px;
		border-radius: 0 0 12px 12px;
		padding: 2px 8px 4px;

		font-size: 11px;

		color: #fff;
		background: #000;

		opacity: 0.6;
		z-index: 20;
	}

	@include media-breakpoint-down(sm) {
		display: block;

		.inner {
			justify-content: space-between;
		}

		.link {
			gap: 2px;
			flex-direction: column;
			align-items: start;
			height: auto;
		}

		.btn-banner-close {
			position: static;
		}
	}
}
