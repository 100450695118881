//
// Global variables
//

// 사이트 기본 설정
// $mode: "responsive" !default;

// 이미지 경로
$url-img: "~@/assets/images" !default;

//
// Color palette
//

// default
$default: #333 !default;
$white: #fff !default;
$black: #000 !default;

// gray scale
$gray50: #f9fafb !default;
$gray100: #f3f4f6 !default;
$gray200: #e5e7eb !default;
$gray300: #d1d5db !default;
$gray400: #9ca3af !default;
$gray500: #6b7280 !default;
$gray600: #4b5563 !default;
$gray700: #1f2937 !default;

// primary scale
$primary100: #eff6ff !default;
$primary200: #dbeafe !default;
$primary300: #bfdbfe !default;
$primary400: #60a5fa !default;
$primary500: #1364ff !default;
$primary600: #0658f5 !default;
$primary700: #003fb7 !default;

// secondary scale
$secondary500: #bafd02 !default;

// Dynamic scale
$red100: #ffe2d1 !default;
$red500: #ef4444 !default;
$red600: #dc2626 !default;

$green600: #059669 !default;

$violet600: #a855f7 !default;

$orange600: #f97316 !default;

//
// Functional Color palette
//

// font
$font-default: $gray700 !default;
$font-white: $white !default;
$font-black: $black !default;

$font-gray: $gray600 !default;
$font-lightgray: $gray500 !default;
$font-whitegray: $gray400 !default;
$font-darkgray: $gray700 !default;

$font-primary: $primary600 !default;
$font-lightprimary: $primary600 !default;
$font-darkprimary: $primary700 !default;

$font-secondary: $secondary500 !default;

$font-red: $red600 !default;
$font-green: $green600 !default;
$font-violet: $violet600 !default;
$font-orange: $orange600 !default;

$font-invalid: $font-red !default;
$font-valid: $font-green !default;
// $font-notice: $font-orange !default;

// background
$bg-default: $gray500 !default;
$bg-white: $white !default;
$bg-black: $black !default;

$bg-gray: $gray200 !default;
$bg-lightgray: $gray100 !default;
$bg-darkgray: $gray600 !default;

$bg-primary: $primary500 !default;
$bg-lightprimary: $primary100 !default;
$bg-brightprimary: $primary200 !default;
$bg-darkprimary: $primary600 !default;

$bg-secondary: $secondary500 !default;

// 기본

$bg-red: $red500 !default;
$bg-green: $green600 !default;

$bg-invalid: $bg-red !default;
$bg-valid: $bg-green !default;

// border
$border-base: $gray400 !default;
$border-light: $gray100 !default;
$border-dark: $gray500 !default;

$border-primary: $primary500 !default;
$border-lightprimary: $primary400 !default;
$border-darkprimary: $primary600 !default;

$border-secondary: $secondary500 !default;

// status
$placeholder-font-color: $font-lightgray !default;
$disabled-font-color: $font-lightgray !default;

$border-active: $border-primary;
$border-hover: $border-darkprimary;
$border-disabled: $border-lightprimary;

$border-invaild: $red500 !default;

//
// Color Map
//

$colors: () !default;
$colors: map-merge(
	(
		"default": $font-default,
		"white": $font-white,
		"black": $font-black,
		"gray": $font-gray,
		"lightgray": $font-lightgray,
		"darkgray": $font-darkgray,

		"primary": $font-primary,
		"lightprimary": $font-primary,
		"darkprimary": $font-darkprimary,

		"secondary": $font-secondary,
		"red": $font-red,
		"green": $font-green,

		"invalid": $font-invalid,
		"valid": $font-valid
	),
	$colors
);

$dark-bg-colors: () !default;
$dark-bg-colors: map-merge(
	(
		"bg-default": $bg-default,
		"bg-darkgray": $bg-darkgray,
		"bg-primary": $bg-primary,
		"bg-lightprimary": $bg-lightprimary,
		"bg-darkprimary": $bg-darkprimary,

		"bg-secondary": $bg-secondary,

		"bg-invalid": $bg-invalid,
		"bg-valid": $bg-valid
	),
	$dark-bg-colors
);

$light-bg-colors: () !default;
$light-bg-colors: map-merge(
	(
		"bg-lightgray": $bg-lightgray,
		"bg-gray": $bg-gray,
		"bg-lightprimary": $bg-lightprimary,
		"bg-white": #fff
	),
	$light-bg-colors
);

//
// 글꼴
//

$letter-spacing: -0.015em !default;
$letter-spacing-sm: -0.01em !default;
$letter-spacing-lg: -0.02em !default;
$letter-spacing-mobile: $letter-spacing !default;
$line-height: 1.3 !default;

$font-family-base: // -apple-system, BlinkMacSystemFont,
	"Spoqa Han Sans Neo", "malgun gothic", Arial, sans-serif !default;
$font-family-code: "IBM Plex Mono", "monaco", Consolas, "Courier New", Courier, Arial, "Spoqa Han Sans Neo",
	"malgun gothic", "굴림", sans-serif !default;

$font-family-title: $font-family-base !default;
$font-family-mobile: $font-family-base !default;

$font-size-xs: 12px !default;
$font-size-sm: 14px !default;
$font-size-md: 16px !default;
$font-size-lg: 18px !default;
$font-size-xl: 24px !default;
$font-size-xxl: 28px !default;
$font-size-xxxl: 40px !default;

$font-size-base: $font-size-md !default;

//
// 그리드
//

// breakpoints
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 1025px,
	xl: 1280px,
	xxl: 1440px,
	xxxl: 1600px
) !default;

// 기본값
$min-width: 360px !default;
$max-width: 1280px !default;
$min-width-lg: 1440px !default;

// spacing
$spacing-xs: 8px;
$spacing-sm: 24px;
$spacing-md: 40px;
$spacing-lg: 60px;
$spacing: $spacing-sm;

// PC
$spacing: 20px !default;
$titlebar-height: 76px !default;
$breadcrumb-height: 0px !default;
$sidebar-width: 252px !default;
$sidebar-width-min: 280px !default;
$widget-width: 320px !default;
$footer-height: 80px !default;
$promotion-banner-height: 80px;

// Mobile
$mobile-spacing: 20px !default;
$mobile-topnav-height: 35px !default;
$mobile-header-height: 64px !default;
$mobile-breadcrumb-height: 30px !default;
$mobile-footer-height: 195px !default;

// $mobile-allmenu-header: 157px !default;
// $mobile-allmenu-footer: 50px !default;

//
// Component
//

// bullets
$font-pagetitle: 32px !default;
$font-title1: $font-size-xxl !default;
$font-mobile-title1: $font-size-md !default;

// buttons
$btn-gap-xs: 4px;
$btn-gap-sm: 6px;
$btn-gap-md: 6px;
$btn-gap-lg: 8px;
$btn-gap-xl: 8px;

$btn-gap-base: $btn-gap-md;

// forms
$form-height-xs: 24px;
$form-height-sm: 36px;
$form-height-md: 40px;
$form-height-lg: 48px;
$form-height-xl: 56px;

$form-height-base: $form-height-xl;

// status
$shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.2);
