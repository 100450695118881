.table-caption-checker {
	flex: 1 1 auto;

	.title {
		margin-top: 0;
		margin-bottom: 16px;
		font-size: $font-size-lg;
		font-weight: 700;
		line-height: 25px;
		color: $font-default;
	}

	.box {
		display: flex;
		align-items: center;
		padding: 11px;
		border-radius: 8px;

		& + .box {
			margin: 0;
		}

		.ico-area {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			min-width: 32px;
			height: 32px;
			margin-right: 12px;
			border-radius: 50%;
			border: 1px solid $gray300;
			background: $bg-white;
		}

		.caption {
			display: inline-flex;
			align-items: center;
			gap: 4px;
			font-size: $font-size-sm;
			font-weight: 500;
			line-height: 18px;
			letter-spacing: -0.14px;
			color: $font-default;

			.code {
				display: inline-block;
				height: 22px;
				padding: 0 4px;
				border-radius: 4px;
				border: 1px solid $gray300;
				background: $bg-white;
				font-family: $font-family-code;
				font-size: $font-size-xs;
				font-weight: 500;
				line-height: 20px;
				letter-spacing: -0.12px;
				color: #e1382f;
			}
		}

		.caption-cancel {
			// font-family: $font-family-code;
			font-size: $font-size-sm;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: -0.14px;
			color: $font-default;
			text-decoration: line-through;
		}
	}

	dl {
		margin-top: 16px;
	}

	dt {
		gap: 13px;
		padding-right: 85px;
		font-size: $font-size-md;
		font-weight: 500;
		line-height: 21px;
		color: $font-default;

		.text-gray {
			position: relative;
			margin-left: 13px;
			font-weight: 400;

			&::before {
				content: "";
				position: absolute;
				top: 7px;
				left: -6px;
				width: 1px;
				height: 12px;
				border-radius: 100px;
				background: $gray300;
			}
		}

		@include media-breakpoint-down(xl) {
			.text-gray {
				display: block;
				margin-left: 0;
				margin-top: 2px;
				font-size: $font-size-sm;
				line-height: 18px;

				&::before {
					display: none;
				}
			}
		}
	}

	dd {
		margin-top: 8px;
		position: relative;

		.btn-copy {
			gap: 2px;
			position: absolute;
			top: -8px;
			transform: translateY(-100%);
			right: 0;
			height: 21px;
			line-height: 21px;
			margin: 0;
			padding: 0 6px;
			border-radius: 12px;
			border: 1px solid $gray300;
			background: $bg-lightgray;
			font-weight: 400;
			font-size: 10px;
			color: $font-default;
			z-index: 50;
		}
	}

	.caption-info {
		padding-bottom: 16px;
	}

	.caption-suggestions {
		padding-top: 16px;
		border-top: 1px solid #d9d9d9;

		.title {
			display: flex;
			align-items: center;
			gap: 4px;

			span {
				font-size: $font-size-xs;
				font-weight: 400;
				color: $font-gray;
			}

			@include media-breakpoint-down(xl) {
				flex-wrap: wrap;

				span {
					flex: 1 1 100%;
					line-height: 16px;
				}
			}
		}

		.check-item-sm {
			width: 100%;

			[type="radio"] {
				+ label {
					width: 100%;
					padding: 11px 11px 11px 43px;
					border-radius: 8px;
					border: 1px solid $border-base;
					background: $bg-white;

					font-size: $font-size-sm;
					font-weight: 400;
					line-height: 18px;
					color: $font-default;

					&::before {
						position: absolute;
						top: 13px;
						left: 16px;
						margin: 0;
					}

					&:hover {
						background: $bg-lightprimary;
					}
				}
			}

			[type="radio"]:checked {
				+ label {
					z-index: 0;

					&::after {
						content: "";
						position: absolute;
						top: -1px;
						left: -1px;
						z-index: -1;
						width: calc(100% - 2px);
						height: calc(100% - 2px);
						border: 2px solid $border-primary;
						border-radius: 8px;
						background: $bg-lightprimary;
					}
				}
			}
		}
	}
}
