.promotion-banner {
	text-align: left;
	color: $font-white;
	// background: $bg-black;
	background: #001aff;
	overflow: hidden;

	.text {
		font-size: 14px;
		line-height: 1.4;

		span {
			opacity: 0.85;
		}

		strong {
			font-weight: 500;
		}

		.text-lg {
			font-weight: 700;
			display: block;
			margin-top: 2px;
		}
	}

	.btn-cta {
		padding: 0px 12px;
		transition: 0.3s;

		&.btn-md {
			font-size: 14px;
			font-weight: normal;
		}

		.ico:last-child {
			margin-right: -4px;
		}
	}

	// 하단 고정 띠배너 : 랜딩
	// &.type-band {
	// 	position: fixed;
	// 	bottom: 0;
	// 	left: 0;
	// 	right: 0;
	// 	z-index: 100;
	// 	transition: 0.6s;

	// 	&.hide {
	// 		transform: translateY(110px);
	// 	}

	// 	&.bg-active {
	// 		background: $bg-secondary;
	// 		color: $font-black;

	// 		.btn-primary {
	// 			color: $font-white;
	// 			background: $bg-black;
	// 		}
	// 	}

	// 	.banner-content {
	// 		max-width: 560px;
	// 		margin: auto;
	// 		display: flex;
	// 		align-items: center;
	// 		justify-content: space-between;

	// 		.img-gift {
	// 			position: absolute;
	// 			left: 0;
	// 			width: 126px;
	// 			bottom: -25px;
	// 		}
	// 	}

	// 	@include media-breakpoint-up(md) {
	// 		.banner-content {
	// 			position: relative;
	// 			padding-left: 138px;
	// 			height: 80px;
	// 		}
	// 	}

	// 	@include media-breakpoint-down(sm) {
	// 		.banner-content {
	// 			height: 64px;
	// 			padding: 0 12px;
	// 			max-width: 380px;
	// 			justify-content: space-between;

	// 			.img-gift {
	// 				position: static;
	// 				width: 39px;
	// 			}

	// 			.text {
	// 				font-size: 12px;
	// 				flex: 1 1 auto;
	// 				margin-left: 2px;

	// 				.text-lg {
	// 					font-size: 14px !important;
	// 				}
	// 			}

	// 			.btn-cta {
	// 				height: 32px;
	// 				padding: 0 10px;
	// 			}
	// 		}
	// 	}
	// }

	// 카드 중형(기본형) : 커뮤니티 위젯
	&.type-card {
		display: flex;
		flex-direction: column;
		position: relative;
		flex: 0 0 auto;
		border-radius: 16px;

		.banner-content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			flex: 1 1 auto;
			padding: 24px;

			.text {
				flex: 1 1 auto;
			}

			.img-gift {
				position: absolute;
				right: -58px;
				bottom: -40px;
				width: 230px;
			}

			.btn-cta {
				margin-top: 20px;
			}
		}
	}

	// 카드 대형 : 메인
	&.type-card-lg {
		display: flex;
		flex-direction: column;
		position: relative;
		flex: 0 0 auto;
		border-radius: 32px;

		.banner-content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			flex: 1 1 auto;
			padding: 40px 48px;

			.text {
				flex: 1 1 auto;
				font-size: 16px !important;
				word-break: keep-all;
			}

			.text-lg {
				margin-top: 8px;
				font-size: 28px !important;
			}

			.img-gift {
				position: absolute;
				right: -112px;
				bottom: -80px;
				width: 460px;
			}

			.btn-cta {
				margin-top: 20px;
			}
		}

		@include media-breakpoint-down(md) {
			.banner-content {
				padding: 28px;

				.text-lg {
					font-size: 18px !important;
				}

				.img-gift {
					position: absolute;
					top: 50%;
					right: -62px;
					top: unset;
					transform: translateY(0);
					bottom: -28px;
					width: 230px;
				}

				.btn-cta {
					font-size: 14px;
				}
			}
		}
	}

	// 카드 소형 : 대시보드 패널
	&.type-card-sm {
		display: flex;
		flex-direction: column;
		position: relative;
		flex: 0 0 auto;
		border-radius: 20px;

		.banner-content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			flex: 1 1 auto;

			.text {
				flex: 1 1 auto;
			}

			.img-gift {
				position: absolute;
				right: -58px;
				bottom: -40px;
				width: 230px;
			}

			.btn-cta {
				margin-top: 20px;
			}
		}
	}

	// 가로로 긴 밴드형 : 설정 하단
	&.type-band {
		display: flex;
		flex-direction: column;
		position: relative;
		flex: 0 0 auto;
		padding: 16px 24px 16px 110px;
		border-radius: 20px;

		.banner-content {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.img-gift {
				position: absolute;
				left: -56px;
				top: 50%;
				transform: translateY(-50%);
				width: 230px;
			}

			.text {
				flex: 1 1 auto;
				margin-left: 8px;
			}
		}
	}
}
