//
// 앨리지수
//
.setting-activity-page {
	.my-flower-pot {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 4px;

		@at-root .user-level-tag {
			display: inline-flex;
			align-items: center;
			height: 28px;
			padding: 0 6px;
			border-radius: 6px;
			border: 1px solid $gray200;
			color: $font-default;
			font-size: 14px;
			line-height: 1;
			background: rgba($bg-gray, 0.8);

			&.small {
				height: 19px;
				padding: 0 3px;
				font-size: 12px;
				border-radius: 4px;
				border-color: transparent;
			}
		}
	}

	.plant-history {
		position: relative;
		padding-left: 20px;

		&::before {
			content: "";
			position: absolute;
			left: 10px;
			top: 8px;
			bottom: 10px;
			width: 2px;
			background: $gray200;
		}

		li {
			position: relative;
			padding-left: 10px;

			+ li {
				margin-top: 10px;
			}

			&::before {
				content: "";
				position: absolute;
				left: -16px;
				top: 0;
				margin-top: 8px;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background: $gray300;
				border: 2px solid #fff;
			}

			.undefined {
				min-width: 48px;
				justify-content: center;
				border-color: transparent;

				color: $gray500;
				opacity: 0.6;
				border-color: transparent;
			}

			// &:not(.active) {
			// 	.user-level-tag {
			// 		color: $gray500;
			// 		opacity: 0.6;
			// 		border-color: transparent;
			// 	}
			// }

			&.active {
				position: relative;
				&::before {
					border-color: $gray200;
					background: $bg-primary;
					animation: blink 1.5s infinite; // 깜빡거리는 애니메이션 적용
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
