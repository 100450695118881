.axe-table-validator {
	position: relative;
	flex: 1 1 auto;
	overflow-y: scroll;
	padding: 20px 4px 20px 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;

	.violations {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		gap: 16px;

		.title {
			color: $font-default;
		}

		.btn-group-flex {
			position: sticky;
			bottom: 0;
		}
	}

	.result-list {
		display: flex;
		gap: 8px;
		padding: 16px;
		border-radius: 12px;
		border: 1px solid $gray300;
		background: $bg-lightgray;

		.icon {
			flex: 0 0 auto;
			width: 32px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $gray300;
			border-radius: 50px;
			background: $bg-white;
		}
	}

	.violation-item {
		flex: 1 1 auto;
		line-height: 1.3;

		.a11y-message {
			font-weight: bold;
			margin-bottom: 4px;
		}

		.desc {
			font-size: $font-size-sm;
			font-weight: 500;
		}

		.help {
			margin-top: 2px;
			font-size: $font-size-xs;
			font-weight: 400;
			color: $font-gray;
		}
	}

	.item-find {
		margin-top: 8px;
	}

	.find-title {
		font-size: $font-size-xs;
	}

	.violation-list {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin-top: 4px;
		gap: 4px;

		.btn {
			min-width: 24px;
			padding: 0 4px;
			font-size: $font-size-sm;
		}

		// .btn-pointer {
		// 	flex: 1 1 auto;
		// 	display: flex;
		// 	flex-direction: column;
		// 	align-items: stretch;
		// 	gap: 4px;

		// 	padding: 8px;
		// 	gap: 4px;
		// 	border-radius: 12px;
		// 	border: 1px solid $gray300;
		// 	background: $gray200;
		// 	text-align: left;

		// 	// &:hover {
		// 	// 	border: 2px solid $gray600;
		// 	// 	padding: 7px;
		// 	// }

		// 	&:focus-within {
		// 		border: 2px solid $red500;
		// 		padding: 7px;
		// 	}

		// 	.text {
		// 		color: $gray600;
		// 		font-size: 12px;
		// 		line-height: 16px;
		// 		font-style: normal;
		// 		font-weight: 500;
		// 		span {
		// 			text-decoration-line: underline;
		// 		}
		// 	}
		// }

		// .code {
		// 	padding: 4px 6px;
		// 	border-radius: 6px;
		// 	border: 1px solid $gray300;
		// 	background: $gray100;
		// 	font-size: 10px;
		// 	color: $red500;
		// 	font-family: $font-family-code;
		// }
	}

	.no-result {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 12px;

		padding: 24px;
		font-weight: bold;
		font-size: $font-size-lg;
		text-align: center;
	}

	.tooltip {
		position: absolute;
		bottom: 88px;
		left: calc(50% + 10px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 2px;
		width: 272px;
		transform: translateX(-50%);
		padding: 16px 8px;
		border-radius: 12px;
		background: $gray700;

		&::before {
			content: "";
			position: absolute;
			bottom: -14px;
			left: calc(50% - 12px);
			width: 24px;
			height: 14px;
			background: url(#{$url-img}/img-tooltip@2x.png) 0 0 no-repeat;
			background-size: 24px 14px;
		}

		p {
			font-size: $font-size-sm;
			font-weight: 700;
			line-height: 18px;
			text-align: center;
			color: $font-white;
		}

		.subject {
			font-weight: 400;
		}
	}

	.btn-group-flex {
		position: relative;

		.btn {
			&:focus,
			&:hover {
				+ .tooltip {
					display: block;
				}
			}
		}
	}

	// .result-status {
	// 	padding: 8px 12px;
	// }

	// .result-area {
	// 	padding: 0 12px;
	// }

	// .table-result-item {
	// 	border-radius: 12px;
	// 	border: 1px solid $gray300;
	// 	background: $bg-white;

	// 	&.hide {
	// 		display: none;
	// 	}

	// 	&.active {
	// 		border: 3px solid $red500;
	// 		.title-area {
	// 			padding: 6px 6px 8px;
	// 		}
	// 		.info {
	// 			padding: 8px 6px 6px;
	// 		}
	// 	}
	// }

	// .title-area {
	// 	display: flex;
	// 	padding: 8px;
	// 	justify-content: space-between;
	// 	align-items: center;
	// 	.status {
	// 		display: flex;
	// 	}
	// }

	// .info {
	// 	display: flex;
	// 	flex-direction: column;
	// 	gap: 8px;
	// 	border-top: 1px solid $gray300;
	// 	padding: 8px;

	// 	dd {
	// 		margin-top: 2px;
	// 	}

	// 	.btn {
	// 		width: 100%;
	// 	}
	// }

	// .table-analysis {
	// 	.title3 {
	// 		margin-top: 0;
	// 	}
	// 	.table-content-analysis {
	// 		padding: 20px;
	// 		dt {
	// 			font-weight: bold;
	// 		}
	// 	}
	// 	.axe-table-result {
	// 		border-top: 1px solid $gray300;
	// 		padding: 20px;
	// 		dt {
	// 			font-weight: bold;
	// 		}
	// 	}
	// }
}
