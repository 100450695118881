.article-item {
	flex: 0 0 auto;
	width: calc(50% - 10px);
	align-self: flex-start;
	display: flex;
	flex-direction: column;
	// flex-wrap: wrap;
	gap: 16px;

	border-radius: 16px;
	padding: 32px;
	background: var(--gray100, #f3f4f6);

	.emojj {
		line-height: 1;
	}

	&.tool-img2html {
		background: #f0f6ff;

		.emoji {
			background: #bfdbfe;
		}
	}
	&.tool-contrast {
		background: #f8f0ff;

		.emoji {
			background: #e4cbf9;
		}
	}
	&.tool-seo {
		background: #fff5ee;

		.emoji {
			background: #fdc9a7;
		}
	}
	&.tool-table {
		background: #e8faf5;

		.emoji {
			background: #c6e8df;
		}
	}

	dt {
		display: flex;
		align-items: center;
		gap: 8px;
		font-size: $font-size-sm;
	}

	.emoji {
		display: flex;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		background: var(--gray200, #e5e7eb);
	}

	.name {
		font-weight: 700;
		color: var(--gray-text-primary, #1f2937);
	}

	.date {
		display: flex;
		align-items: center;
		gap: 8px;
		font-size: $font-size-sm;
		color: var(--gray500, #6b7280);

		&::before {
			content: "";
			width: 3px;
			height: 3px;
			border-radius: 50%;
			background: var(--gray300, #d1d5db);
		}
	}

	.content {
		font-weight: 500;
		flex: 1 1 auto;
		// word-break: keep-all;
	}

	&.small {
		.content {
			font-size: $font-size-sm;
		}
	}

	&.large {
		.content {
			font-size: $font-size-lg;
		}
	}

	.info {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		font-size: $font-size-sm;
		color: var(--gray500, #6b7280);

		button {
			&:hover {
				text-decoration: underline;
				text-underline-offset: 3px;
				text-decoration-color: $gray400;
			}
		}

		.tag-list {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 4px;
		}

		.tool-name {
			display: flex;
			align-items: center;
			gap: 4px;

			&::before {
				content: "";
				width: 3px;
				height: 3px;
				border-radius: 50%;
				background: var(--gray300, #d1d5db);
				margin-right: 4px;
			}

			.ico {
				width: 16px;
				height: 16px;
			}
		}
	}

	@include media-breakpoint-down(xxl) {
		.mode-logged & {
			width: 100%;
		}
	}

	@include media-breakpoint-down(sm) {
		.mode-public & {
			width: 100%;
		}
	}
}
