.form-control {
	width: 100%;
	max-width: 100%;
	appearance: none;
	text-align: left;
	border: 1px solid $border-base;
	color: $default;
	background: $bg-white;
	vertical-align: middle;

	font-size: 16px;
	height: $form-height-base;
	border-radius: 8px;
	padding: 10px 19px 10px 19px;
	line-height: $form-height-base - 2px - 8px;

	transition-duration: 0.2s;
	transition-property: color, border-color, box-shadow;
	will-change: color, border-color, box-shadow;

	&::-ms-clear {
		display: none;
	}

	// 사이즈
	&-xs {
		min-width: 182px;
		border: 1px solid $border-base;

		height: $form-height-xs;
		padding: 4px 8px;
		border-radius: 4px;
		font-size: $font-size-xs;

		color: $default;
		vertical-align: middle;
		line-height: $form-height-sm - 2px - 14px;
		background: $bg-white;

		&.invalid {
			border-color: $border-invaild !important;
			color: $font-invalid;

			+ .invalid-message {
				display: block;
				color: $font-invalid;
			}
		}

		&:hover {
			border-color: $border-hover;
		}

		&:focus {
			outline: 0;
			position: relative;
			border-color: $border-active;
		}

		&:disabled,
		&[readonly="readonly"],
		&.readonly {
			background-color: $bg-gray;
			opacity: 1;

			&::-ms-value {
				color: $disabled-font-color !important;
			}
		}

		&:disabled {
			color: $disabled-font-color;

			&:hover,
			&:focus {
				box-shadow: none;
				border-color: $gray200;
			}
		}

		&:read-only:not(select):not(span) {
			background-color: $bg-lightgray;
			opacity: 1;
		}

		&:is(select) {
			min-width: 132px;
			max-width: 100%;
			padding-right: 30px;
			// background: $bg-white url(#{$url-img}/ico-caret-down-gray-sm@2x.png) no-repeat right 12px center;
			background-size: 16px 16px;
			appearance: none;

			&::-ms-expand {
				display: none;
			}

			// &:disabled {
			// 	&:hover {
			// 		border-color: $border-base;
			// 	}
			// }

			&:hover {
				border-color: $border-hover;
			}

			&:focus {
				outline: 0;
				position: relative;
				border-color: $border-active;
			}

			&.invalid {
				border-color: $border-invaild !important;
				color: $font-invalid;

				+ .invalid-message {
					display: block;
					color: $font-invalid;
				}
			}

			&:disabled,
			&[readonly="readonly"],
			&.readonly {
				background-color: $bg-gray;
				opacity: 1;

				&::-ms-value {
					color: $disabled-font-color !important;
				}
			}

			&:read-only:not(select):not(span) {
				// color: $disabled-font-color;
				background-color: $bg-lightgray;
				opacity: 1;
			}
		}

		&.full {
			min-width: 100%;
		}
	}

	&-sm {
		min-width: 182px;
		border: 1px solid $border-base;

		height: $form-height-sm;
		padding: 7px 12px;
		border-radius: 8px;
		font-size: $font-size-base;

		color: $default;
		vertical-align: middle;
		line-height: $form-height-sm - 2px - 14px;
		background: $bg-white;

		&.invalid {
			border-color: $border-invaild !important;
			color: $font-invalid;

			+ .invalid-message {
				display: block;
				color: $font-invalid;
			}
		}

		&:hover {
			border-color: $border-hover;
		}

		&:focus {
			outline: 0;
			position: relative;
			border-color: $border-active;
		}

		&:disabled,
		&[readonly="readonly"],
		&.readonly {
			background-color: $bg-gray;
			opacity: 1;

			&::-ms-value {
				color: $disabled-font-color !important;
			}
		}

		&:disabled {
			color: $disabled-font-color;

			&:hover,
			&:focus {
				box-shadow: none;
				border-color: $gray200;
			}
		}

		&:read-only:not(select):not(span) {
			background-color: $bg-lightgray;
			opacity: 1;
		}

		&:is(select) {
			min-width: 132px;
			max-width: 100%;
			padding-right: 30px;
			// background: $bg-white url(#{$url-img}/ico-caret-down-gray-sm@2x.png) no-repeat right 12px center;
			background-size: 16px 16px;
			appearance: none;

			&::-ms-expand {
				display: none;
			}

			// &:disabled {
			// 	&:hover {
			// 		border-color: $border-base;
			// 	}
			// }

			&:hover {
				border-color: $border-hover;
			}

			&:focus {
				outline: 0;
				position: relative;
				border-color: $border-active;
			}

			&.invalid {
				border-color: $border-invaild !important;
				color: $font-invalid;

				+ .invalid-message {
					display: block;
					color: $font-invalid;
				}
			}

			&:disabled,
			&[readonly="readonly"],
			&.readonly {
				background-color: $bg-gray;
				opacity: 1;

				&::-ms-value {
					color: $disabled-font-color !important;
				}
			}

			&:read-only:not(select):not(span) {
				// color: $disabled-font-color;
				background-color: $bg-lightgray;
				opacity: 1;
			}
		}

		&.full {
			min-width: 100%;
		}
	}

	&-md {
		min-width: 200px;
		border: 1px solid $border-base;

		height: $form-height-md;
		padding: 7px 15px;
		border-radius: 9px;
		font-size: $font-size-base;

		color: $font-default;

		vertical-align: middle;
		line-height: $form-height-md - 2px - 14px;
		background: $bg-white;

		&.invalid {
			border-color: $border-invaild !important;
			color: $font-invalid;

			+ .invalid-message {
				display: block;
				color: $font-invalid;
			}
		}

		&:hover {
			border-color: $border-hover;
			color: $font-default;
		}

		&:focus {
			outline: 0;
			position: relative;
			border-color: $border-active;
		}

		&:disabled,
		&[readonly="readonly"],
		&.readonly {
			border: 1px solid $gray200;
			background-color: $bg-lightgray;
			color: $disabled-font-color;
			opacity: 1;

			&::-ms-value {
				color: $disabled-font-color !important;
			}

			&:hover,
			&:focus {
				box-shadow: none;
			}
		}

		&:read-only:not(select):not(span) {
			background-color: $bg-lightgray;
			opacity: 1;
		}

		&:is(select) {
			min-width: 132px;
			max-width: 100%;
			padding-right: 30px;
			// background: $bg-white url(#{$url-img}/ico-caret-down-gray-sm@2x.png) no-repeat right 12px center;
			background-size: 16px 16px;
			appearance: none;

			&::-ms-expand {
				display: none;
			}

			// &:disabled {
			// 	&:hover {
			// 		border-color: $border-base;
			// 	}
			// }

			&:hover {
				border-color: $border-hover;
			}

			&:focus {
				outline: 0;
				position: relative;
				border-color: $border-active;
			}

			&.invalid {
				border-color: $border-invaild !important;
				color: $font-invalid;

				+ .invalid-message {
					display: block;
					color: $font-invalid;
				}
			}

			&:disabled,
			&[readonly="readonly"],
			&.readonly {
				background-color: $bg-gray;
				opacity: 1;

				&::-ms-value {
					color: $disabled-font-color !important;
				}
			}

			&:read-only:not(select):not(span) {
				// color: $disabled-font-color;
				background-color: $bg-lightgray;
				opacity: 1;
			}
		}

		&.full {
			min-width: 100%;
		}

		// 플레이스 홀더
		&::-webkit-input-placeholder {
			color: $placeholder-font-color;
			opacity: 1;
			font-size: 16px;
			font-weight: 400;
		}

		&:-ms-input-placeholder {
			color: $placeholder-font-color;
			opacity: 1;
			font-size: 16px;
			font-weight: 400;
		}
	}

	&:is(select) {
		// display: none;
		// opacity: 0;
		// visibility: hidden;
		min-width: 132px;
		max-width: 100%;
		line-height: normal;
		padding-right: 30px;
		padding-top: 7px;
		padding-bottom: 7px;
		// background: $bg-white url(#{$url-img}/ico-select-arrow.svg) no-repeat right 11px center;
		background-size: 12px 7px;

		&::-ms-expand {
			display: none;
		}

		// &:disabled {
		// 	background: $bg-white url(#{$url-img}/ico-select-arrow.svg) no-repeat right 11px center;
		// }
	}

	&:is(textarea) {
		height: auto;
		min-height: 120px;
		padding: 16px;
		padding-right: 0;
		overflow-y: scroll;
		line-height: inherit;
		resize: none;
		background: $bg-white url(#{$url-img}/ico-textarea@2x.png) no-repeat;
		background-size: 5.5px 5.5px;
		background-position: right 6px bottom 6px;

		&.form-control-xxl {
			font-size: $font-size-lg;
			&::placeholder {
				font-size: $font-size-lg;
			}
		}
	}

	// 상태
	&.invalid {
		border-color: $border-invaild !important;
		color: $font-invalid;

		+ .invalid-message {
			display: block;
			color: $font-invalid;
		}
	}

	&:hover {
		border-color: $border-hover;
		@include hover-effect;
	}

	&:focus {
		outline: 0;
		position: relative;
		// border-color: $border-active;
		border-color: $border-hover;
		@include hover-effect;
	}

	&:disabled {
		background-color: $bg-lightgray;
		opacity: 1;

		&::-ms-value {
			color: $disabled-font-color !important;
		}
	}

	&:disabled {
		// border-color: $border-base;
		border-color: $gray200;
		color: $disabled-font-color;

		&:hover,
		&:focus {
			box-shadow: none;
			border-color: $gray200;
		}
	}

	// 플레이스 홀더
	&::-webkit-input-placeholder {
		color: $placeholder-font-color;
		opacity: 1;
		font-size: 16px;
		font-weight: 400;
	}

	&:-ms-input-placeholder {
		color: $placeholder-font-color;
		opacity: 1;
		font-size: 16px;
		font-weight: 400;
	}
}

// 자동완성 입력 시 배경색 제거
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-text-fill-color: $default;
	-webkit-box-shadow: 0 0 0px 1000px $bg-white inset;
	box-shadow: 0 0 0px 1000px $bg-white inset;
}

// checkbox, radio
.check-item {
	position: relative;
	cursor: pointer;
	$size-sm: 16px;
	$size-md: 20px;

	[type="checkbox"],
	[type="radio"] {
		position: absolute;
		width: 1px;
		height: 1px;
		opacity: 0;

		+ .label,
		+ label {
			position: relative;
			display: inline-block;
			cursor: pointer;

			&:before {
				box-sizing: border-box;
				display: inline-block;
				width: $size-md;
				height: $size-md;
				// border: 1px solid $border-base;
				vertical-align: middle;
				border-radius: 2px;
				margin-top: -2px;
				margin-right: 5px;
				background-color: $bg-white;
				content: "";

				transition-duration: 0.2s;
				transition-property: border-color, box-shadow, background-color;
				will-change: border-color, box-shadow, background-color;
			}

			&.sr-only {
				position: static;
				width: $size-md;
				height: $size-md;
				vertical-align: middle;
				margin: 0;
				overflow: visible;

				&:before {
					margin-right: 0;
				}
			}
		}
	}

	[type="checkbox"] {
		+ .label,
		+ label {
			&::before {
				border-radius: 3px;
				border: 1px solid $border-base;
			}
		}

		&:checked {
			+ .label,
			+ label {
				&:before {
					border-color: $border-primary;
					background: $bg-primary url(#{$url-img}/ico-check-white-md@2x.png) no-repeat center -2px;
					background-size: $size-md;
				}
			}

			&:hover {
				+ .label,
				+ label {
					&:before {
						border-color: $border-primary;
						background-color: $bg-primary;
						@include hover-effect;
					}
				}
			}

			&:disabled {
				+ .label,
				+ label {
					&:before {
						background-color: $bg-gray;
						@include hover-effect;
					}
				}
			}
		}

		&:disabled {
			+ .label,
			+ label {
				&:before {
					border-color: $border-base;
					background-color: $bg-gray;
				}
			}

			&:checked {
				+ .label,
				+ label {
					&:before {
						border-color: $border-base;
						// background: $bg-gray url(#{$url-img}/ico-check-lightgray-sm@2x.png) no-repeat center -2px;
						background-size: $size-sm;
					}
				}
			}
		}

		&:hover {
			+ .label,
			+ label {
				&:before {
					border-color: $border-base;
					background-color: $bg-gray;
					@include hover-effect;
				}
			}
		}

		&:focus {
			+ .label,
			+ label {
				&:before {
					border-color: $border-active;
					@include hover-effect;
				}
			}
		}
	}

	[type="radio"] {
		+ .label,
		+ label {
			&:before {
				border-radius: 50%;
				box-shadow: inset 0 0 0 1px $border-base;
			}
		}

		&:checked {
			+ .label,
			+ label {
				&:before {
					box-sizing: border-box;
					border-color: $border-active;
					background-color: $bg-white;
					box-shadow: 0 0 0 5px $bg-primary inset;
				}
			}

			&:hover {
				+ .label,
				+ label {
					&:before {
						box-shadow: inset 0 0 0 5px $bg-primary;
					}
				}
			}
		}

		&:hover {
			+ .label,
			+ label {
				&:before {
					box-shadow: inset 0 0 0 1px $bg-gray;
					background-color: $bg-gray;
				}
			}
		}

		&:disabled {
			+ .label,
			+ label {
				&:before {
					box-shadow: 0 0 0 1px $border-base inset;
					background-color: $bg-gray;
				}
			}

			&:checked {
				+ .label,
				+ label {
					&:before {
						box-shadow: 0 0 0 4px $border-base inset;
						background-color: $bg-gray;
					}
				}
			}
		}
	}

	&-sm {
		position: relative;
		cursor: pointer;

		[type="checkbox"],
		[type="radio"] {
			position: absolute;
			width: 1px;
			height: 1px;
			opacity: 0;

			+ .label,
			+ label {
				position: relative;
				display: inline-block;
				cursor: pointer;

				&:before {
					content: "";
					box-sizing: border-box;
					display: inline-block;
					width: $size-sm;
					height: $size-sm;
					vertical-align: middle;
					border-radius: 2px;
					margin-top: -4px;
					margin-right: 5px;
					background-color: $bg-white;
					content: "";

					transition-duration: 0.2s;
					transition-property: border-color, box-shadow, background-color;
					will-change: border-color, box-shadow, background-color;
				}

				&.sr-only {
					position: static;
					width: $size-sm;
					height: $size-sm;
					vertical-align: middle;
					margin: 0;
					overflow: visible;

					&:before {
						margin-right: 0;
					}
				}
			}
		}

		[type="checkbox"] {
			+ .label,
			+ label {
				&:before {
					border-radius: 3px;
					border: 1px solid $border-base;
				}
			}

			&:checked {
				+ .label,
				+ label {
					&:before {
						border-color: $border-active;
						background: $bg-primary url(#{$url-img}/ico-check-white-sm@2x.png) no-repeat center center;
						background-position: center -2px;
						background-size: $size-sm;
					}
				}

				&:hover {
					+ .label,
					+ label {
						&:before {
							border-color: $border-primary;
							background-color: $bg-primary;
							@include hover-effect;
						}
					}
				}

				&:disabled {
					+ .label,
					+ label {
						&:before {
							border-color: $border-base;
							background-color: $bg-gray;
							@include hover-effect;
						}
					}
				}
			}

			&:hover {
				+ .label,
				+ label {
					&:before {
						border-color: $border-base;
						background-color: $bg-gray;
						@include hover-effect;
					}
				}
			}

			&:focus {
				+ .label,
				+ label {
					&:before {
						border-color: $border-active;
						// background-color: $bg-gray;
						@include hover-effect;
					}
				}
			}

			&:disabled {
				+ .label,
				+ label {
					&:before {
						border-color: $border-base;
						background-color: $bg-gray;
					}
				}

				&:checked {
					+ .label,
					+ label {
						&:before {
							border-color: $border-base;
							// background: $bg-gray url(#{$url-img}/ico-check-lightgray-lg@2x.png) no-repeat center;
							background-size: $size-md;
						}
					}
				}
			}
		}

		[type="radio"] {
			+ .label,
			+ label {
				&:before {
					border-radius: 50%;
					box-shadow: inset 0 0 0 1px $border-base;
				}
			}

			&:checked {
				+ .label,
				+ label {
					&:before {
						box-sizing: border-box;
						border-color: $border-active;
						background-color: $bg-white;
						box-shadow: 0 0 0 5px $bg-primary inset;
					}
				}

				&:hover {
					+ .label,
					+ label {
						&:before {
							box-shadow: inset 0 0 0 5px $bg-primary;
						}
					}
				}
			}

			&:hover {
				+ .label,
				+ label {
					&:before {
						box-shadow: inset 0 0 0 1px $bg-gray;
						background-color: $bg-gray;
					}
				}
			}

			&:disabled {
				+ .label,
				+ label {
					&:before {
						box-shadow: 0 0 0 1px $border-base inset;
						background-color: $bg-gray;
					}
				}

				&:checked {
					+ .label,
					+ label {
						&:before {
							box-shadow: 0 0 0 6px $border-base inset;
							background-color: $bg-gray;
						}
					}
				}
			}
		}
	}
}

// 토글형 체크박스
.check-switch {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
	align-items: center;
	cursor: pointer;

	.label {
		position: relative;
		display: inline-flex;
		align-items: center;
		text-align: center;
		overflow: hidden;
		z-index: 0;

		min-width: 42px;
		height: 26px;
		border-radius: 15px;
		padding-left: 28px;
		padding-right: 8px;
		background-image: linear-gradient(to bottom, #ebe9e9, #ebe9e9);

		color: #fff;
		font-size: 12px;
		line-height: 1;
		text-align: center;
		white-space: nowrap;

		transition-duration: 0.3s;
		transition-property: background-color, hover;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			// background-image: $bg-gradient;
			transition: opacity 0.3s;
			opacity: 0;
		}

		&:after {
			content: "";
			position: absolute;
			top: 2px;
			left: 2px;
			width: 22px;
			height: 22px;
			border-radius: 50%;
			background-color: $bg-white;
			transition-duration: 0.3s;
		}

		span {
			display: block;
			line-height: 23px;
		}

		.text-checked {
			display: none;
			opacity: 0;
			transition-duration: 0.3s;
			transition-timing-function: ease-in;
		}
	}

	input {
		position: absolute;
		width: 1px;
		height: 1px;
		opacity: 0;

		&:checked {
			+ .label {
				padding-left: 12px;
				padding-right: 23px;

				&:before {
					opacity: 1;
				}

				&:after {
					left: calc(100% - 24px);
				}

				.text-uncheck {
					display: none;
					opacity: 0;
				}

				.text-checked {
					display: inline;
					opacity: 1;
				}
			}
		}

		// &:focus {
		// 	+.label {
		// 		// @include hover-effect;
		// 	}
		// }

		&:disabled {
			+ .label {
				opacity: 0.5;
				// color: transparent;
			}
		}
	}
}

// 버튼형 체크박스
.check-item-btn {
	[type="checkbox"],
	[type="radio"] {
		position: absolute;
		width: 1px;
		height: 1px;
		opacity: 0;
		@include label-focus;

		+ .label,
		+ label {
			border: 1px solid transparent;
			cursor: pointer;
			color: var(--gray-text-primary, #1f2937);
			background: var(--gray100, #f3f4f6);
		}

		&:checked {
			+ .label,
			+ label {
				// background: $bg-primary;
				// color: #fff;
				border-radius: 6px;
				border: 1px solid var(--priamry400, #60a5fa);
				background: var(--primary-primary-5, #eff5ff);
				color: var(--primary700, #003fb7);
			}
		}
	}

	.label,
	label {
		padding: 0 8px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		// height: $form-height-sm;
		height: 28px;
		border-radius: 8px;
		background: #f3f3f3;
		transition: background 0.3s;
		justify-content: center;
		align-items: center;
		gap: 4px;
	}
}

// 원형 체크박스
.check-item-circle {
	[type="checkbox"],
	[type="radio"] {
		position: absolute;
		width: 1px;
		height: 1px;
		opacity: 0;

		&:checked {
			.label,
			+ label {
				background: $bg-primary;
				color: #fff;
			}
		}
	}

	.label,
	label {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 38px;
		height: 38px;
		border-radius: 50%;
		background: #f3f3f3;

		transition: background 0.3s;
	}
}
