//
// 내 계정
//
.setting-account-page {
	.content-inner {
		min-height: calc(100vh - 297px);
	}

	.content-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.profile {
		.con-area {
			align-items: center;
			gap: 16px;

			.img {
				width: 64px;
				height: 64px;
				border-radius: 50%;
				overflow: hidden;

				img {
					width: 64px;
					height: 64px;
					object-fit: cover;
				}
			}

			.user-info {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 2px;

				.user-type {
					color: $font-darkgray;
					background: $bg-gray;
					font-size: 12px;
					border-radius: 4px;
					padding: 3px 4px;
					line-height: 1;
				}
			}
		}
	}
}

// 전화번호 변경
.change-number-popup {
	.guide-text {
		margin-top: 9px;
		font-size: $font-size-sm;
		font-weight: 400;
		line-height: 18px;
		color: $font-lightgray;
	}
}

// 설정 프로필 - 사진 변경
.popup-profile-img {
	display: block;
	width: 80px;
	height: 80px;
	margin: 32px auto 0;
	overflow: hidden;
	border-radius: 50%;

	img {
		width: 80px;
		height: 80px;
		object-fit: cover;
	}
}

.change-photo-popup {
	.box-outline {
		margin-top: 20px;
		margin-bottom: 0;
		padding: 12px;
		border-radius: 8px;
	}

	.info-area {
		position: relative;
		padding-left: 20px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		strong {
			font-size: $font-size-xs;
			line-height: 130%;
			color: $font-default;
		}

		&::before {
			content: "";
			position: absolute;
			top: 1px;
			left: 0;
			width: 12px;
			height: 12px;
			background: url(#{$url-img}/ico-info-xs@2x.png);
			background-size: 12px 12px;
		}
	}
	.bul-list {
		margin-top: 4px;

		li {
			position: relative;
			padding-left: 18px;
			font-size: $font-size-xs;
			font-weight: 400;
			line-height: 130%;
			letter-spacing: -0.12px;
			color: $font-gray;

			&::before {
				content: "";
				position: absolute;
				top: calc(50% - 2px);
				left: 6px;
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background: $bg-darkgray;
			}
		}
	}
}

// 비밀번호 변경
.change-pw-popup {
	.form-group {
		padding-bottom: 0;
	}

	.form-item {
		margin-top: 39px;

		&:first-child {
			margin-top: 0;
		}

		& + .error-text,
		& + .guide-text,
		& + .waiting-text {
			margin-top: 9px;
		}

		dt {
			margin-bottom: 9px;
			font-size: $font-size-md;
			font-weight: 500;
			line-height: 130%;
			color: $font-default;

			label {
				display: inline-block;
			}
		}
	}

	.input-group-form {
		.form-control {
			& + .guide-text,
			& + .error-text,
			& + .waiting-text {
				margin-top: 9px;
			}
		}

		.guide-text {
			& + .form-control {
				margin-top: 12px;
			}

			&.error {
				color: $font-red;
			}
		}
	}

	.error-text {
		font-size: $font-size-sm;
		font-weight: 400;
		line-height: 18px;
		color: $font-red;

		& + .form-item,
		& + .form-control,
		& + .input-btn-group {
			margin-top: 12px;
		}
	}
	.guide-text {
		font-size: $font-size-sm;
		font-weight: 400;
		line-height: 18px;
		color: $font-lightgray;

		& + .form-item {
			margin-top: 40px;
		}
		& + .form-control {
			margin-top: 12px;
		}

		&.error {
			color: $font-red;
		}
	}
	.waiting-text {
		font-size: $font-size-sm;
		font-weight: 400;
		line-height: 18px;
		color: $font-green;
	}
}

// 보조 이메일
.change-secondary-email-popup {
	.form-group {
		padding-bottom: 0;
	}

	.error-text {
		margin-top: 9px;
		font-size: $font-size-sm;
		font-weight: 400;
		line-height: 18px;
		color: $font-red;
	}
}

// 서비스 탈퇴
.secession-check-list {
	li {
		+ li {
			margin-top: 20px;
		}
	}

	.check-item {
		& + .input-group-form {
			margin-top: 12px;
		}

		[type="checkbox"] {
			& + label:before {
				margin-right: 8px;
			}
		}
	}
}

.btn-red {
	background: $red500;
	color: $font-white;

	&:disabled {
		color: $font-white;
		background: #fca5a5;
	}
}

.leave-btn-area {
	margin: 24px 0;
}
