.layout-home,
.layout-full {
	min-height: 100vh;
	// display: flex;
	// flex-direction: column;

	.util-area {
		display: flex;
		align-items: center;
		gap: 20px;
	}

	.profile-wrap {
		.profile {
			display: flex;
			align-items: center;
			gap: 8px;
		}

		.profile-thumb {
			flex: 0 0 auto;
			position: relative;
			display: inline-block;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background-color: $bg-secondary;
			vertical-align: middle;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.profile-name {
			// display: flex;
			// align-items: center;
			// text-align: left;
			flex: 1 1 auto;
			@include text-ellipsis(1, 1em);
			font-size: $font-size-base;
			max-width: 80px;
		}

		@include media-breakpoint-down(sm) {
			.profile-thumb {
				width: 22px;
				height: 22px;
			}

			.profile-name {
				font-size: $font-size-sm;
			}
		}
	}

	#content {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;

		// overflow-y: scroll;
		// padding-left: $spacing-md;
		// padding-right: $spacing-md - 16px; // 우측 scrollbar 영역 계산
	}

	.container {
		flex: 1 1 auto;
		padding: 60px 0;
	}

	.profile-more-popup {
		margin-top: 4px;
		padding-left: 10px;

		.popup-inner {
			width: 92px;
			border-radius: 12px;
		}
		.popup-body {
			padding: 8px;
			overflow-y: hidden;
		}
		.info-detail {
			.btn-group {
				gap: 0;
				margin: 0;
			}
			.btn {
				display: flex;
				white-space: nowrap;
				justify-content: space-between;
				width: 100%;
				padding: 0 12px;
				border-radius: 6px;
				font-weight: 500;
				color: $font-default;

				&:hover {
					background-color: $bg-lightgray;
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.container {
			padding: $spacing;
		}
	}
}

.layout-full {
	@include media-breakpoint-up(md) {
		.header-full {
			width: unset;
			position: fixed;
			left: 0;
			right: 16px;
			background: none;
		}
		.container {
			padding-top: 120px;
		}
	}

	@include media-breakpoint-down(sm) {
		.header-full {
			position: sticky;
			top: 0;
			z-index: 10;
			border-bottom: 1px solid #e5e7eb;
		}
	}

	#content {
		position: relative;
	}
}
