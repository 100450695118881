.content-tooladd {
	display: flex;
	flex-direction: column;

	.info-area {
		position: relative;
		padding-left: 24px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		strong {
			font-size: $font-size-md;
			font-weight: 500;
			line-height: 21px;
			color: $font-default;
		}

		&::before {
			content: "";
			position: absolute;
			top: 1px;
			left: 0;
			width: 20px;
			height: 20px;
			background: url(#{$url-img}/ico-info-md@2x.png);
			background-size: 20px 20px;
		}

		.list {
			font-size: 15px;
		}
	}
}

.sample-images {
	display: flex;
	gap: 12px;

	.sample-image-item {
		transition: all 0.2s ease;
		border: 1px solid #e5e7eb;
		border-radius: 4px;
		overflow: hidden;

		&:hover {
			border-color: #2563eb;
			transform: translateY(-2px);
			box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
		}

		img {
			width: 70px;
			height: 100px;
			object-fit: cover;
			vertical-align: top;
		}
	}
}

.tooladd-wrap {
	flex: 1 1 auto;

	display: flex;
	flex-direction: column;
	// align-items: center;
	// justify-content: flex-start;
	// width: 800px;
	// margin: 0 auto;

	.tooladd-header {
		.page-title {
			display: flex;
			align-items: center;
			// justify-content: center;
			margin-bottom: 32px;
		}
		.ico + span {
			margin-left: 12px;
		}
	}

	.tooladd-body {
		.text-guide {
			font-size: $font-size-md;
		}
	}
	.input-link {
		position: relative;
		display: block;
		height: 56px;

		.form-control {
			height: 100%;
			padding-left: 44px;
			border-radius: 11px;
		}

		&::after {
			position: absolute;
			top: calc(50% - 10px);
			left: 20px;

			display: block;
			width: 20px;
			height: 20px;
			background: url(#{$url-img}/ico-linkclip-md@2x.png);
			background-size: 20px auto;

			content: "";
		}
		& + .error-text {
			margin-top: 9px;
		}
	}
	textarea.form-control {
		height: 100px;
		min-height: auto;
		border-radius: 12px;
		font-size: $font-size-base;

		&::placeholder {
			font-size: $font-size-base;
		}
	}
	.error-text {
		font-size: $font-size-sm;
		font-weight: 400;
		color: $red600;
	}

	.between-text {
		display: block;
		margin: 16px 0;
		text-align: center;
	}

	.text-guide {
		font-weight: 700;
		color: $font-default;
		+ * {
			margin-top: 12px;
		}
	}
}

// 이미지 명도대비 최적화
.conformity-level {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 12px;

	li {
		width: 50%;

		label {
			z-index: 0;
			width: 100%;
			padding: 11px 12px 11px 40px;
			border-radius: 100px;
			word-break: keep-all;

			&::before {
				content: "";
				position: absolute;
				top: calc(50% - 10px);
				left: 12px;
				margin-top: 0 !important;
			}

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;
				width: calc(100% - 2px);
				height: calc(100% - 2px);
				border: 1px solid $gray200;
				border-radius: 100px;
				background: $bg-white;
			}

			&:hover {
				&::after {
					background: $bg-lightprimary;
				}
			}
		}

		input:checked {
			& + label {
				&::after {
					top: -1px;
					width: calc(100% - 4px);
					height: calc(100% - 4px);
					border: 2px solid $border-primary;
					background: $bg-lightprimary;
				}
			}
		}

		.level {
			display: inline-block;
			font-size: $font-size-md;
			font-weight: 700;
			line-height: 21px;
			letter-spacing: -0.24px;
			color: $font-default;
		}
		.contrast {
			display: inline-block;
			margin-left: 4px;
			font-size: $font-size-md;
			font-weight: 700;
			line-height: 21px;
			letter-spacing: -0.24px;
			color: $font-lightgray;
		}

		.badge {
			display: inline-block;
			margin-left: 4px;
			min-width: 36px;
			padding: 0 2px;
			height: 21px;
			border-radius: 5px;
			background: #e2fff3;
			font-size: $font-size-sm;
			font-weight: 500;
			line-height: 18px;
			letter-spacing: -0.14px;
			color: #007241;
			text-align: center;
			line-height: 21px;

			&.hot {
				color: $red600;
				background: rgba($red600, 0.1);
			}
		}

		.label {
			display: block;
			margin-top: 4px;
			font-size: $font-size-sm;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: -0.14px;
		}
	}

	@include media-breakpoint-down(sm) {
		// 	flex-direction: column;
		// 	width: 100%;

		li {
			// width: 100%;
			label {
				&::after {
					border-radius: 20px;
				}
			}
		}
	}
}
.contrast-page {
	.tool-file-upload {
		.upload-area {
			@include media-breakpoint-up(md) {
				height: 278px;
			}
		}
	}
}

// 이미지 HTML 변환기
.img2html-page {
	.tool-file-upload {
		.upload-area {
			@include media-breakpoint-up(md) {
				height: calc(100vh - 641px);
			}
		}
	}
}

.step-area {
	display: inline-flex;
	align-items: center;
	height: 36px;
	padding: 7px;
	gap: 16px;
	border-radius: 100px;
	border: 1px solid $gray200;
	background: $bg-lightgray;

	li {
		display: inline-flex;
		align-items: stretch;
		gap: 4px;

		&:first-child {
			position: relative;

			&::before {
				content: "";
				position: absolute;
				top: calc(50% - 0.5px);
				right: -12px;
				width: 8px;
				height: 1px;
				border-radius: 100px;
				background: $gray400;
			}

			&.current {
				.ico {
					display: none;
				}
			}

			.num {
				background: $bg-darkprimary;
				border-color: $border-darkprimary;
				color: $font-white;
			}
		}

		&.current {
			.num {
				background: $bg-darkprimary;
				border-color: $border-darkprimary;
				color: $font-white;
			}
			.txt {
				font-weight: 700;
				color: $font-default;
			}
		}

		.num {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			border: 1px solid $gray300;
			background: $bg-white;
			font-size: $font-size-xs;
			font-weight: 500;
			color: $font-gray;
			text-align: center;
		}

		.txt {
			font-size: $font-size-sm;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: -0.14px;
			color: $font-gray;
		}
	}

	@media (max-width: 1480px) {
		li:not(.current) {
			.txt {
				display: none;
			}
		}
	}
}
