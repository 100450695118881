.seowork-page {
	.title-wrap {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		min-height: 30px;
		padding-right: 157px;

		.wrap-left {
			display: flex;
			flex-direction: column;
			gap: 4px;
		}

		.title3 {
			margin: 0;
		}

		.guide-text {
			font-size: $font-size-sm;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: -0.14px;
			color: $font-gray;
		}
	}

	.seowork-content {
		margin-top: 12px;

		.list-metatitle {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			align-items: center;
			gap: 12px;
		}

		.check-item-sm {
			display: inline-flex;
			align-items: center;
			min-height: 45px;

			[type="checkbox"],
			[type="radio"] {
				+ .label,
				+ label {
					padding: 11px 11px 11px 35px;
					border-radius: 8px;
					border: 1px solid $border-base;
					background: $bg-white;
					position: relative;

					&::before {
						position: absolute;
						top: 13px;
						left: 11px;
						margin: 0;
					}

					&:hover {
						background: $bg-lightprimary;
					}
				}
			}

			[type="checkbox"]:checked,
			[type="radio"]:checked {
				+ .label,
				+ label {
					z-index: 0;

					&::after {
						content: "";
						position: absolute;
						top: -1px;
						left: -1px;
						z-index: -1;
						width: calc(100% - 2px);
						height: calc(100% - 2px);
						border: 2px solid $border-primary;
						border-radius: 8px;
						background: $bg-lightprimary;
					}
				}
			}
		}

		.btn-copy {
			position: absolute;
			top: 0;
			right: 0;
			height: 30px;
			padding: 5px 8px 6px;
			border-radius: 100px;
			background: $bg-gray;
			font-size: $font-size-sm;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: -0.14px;
			color: $font-default;

			&:disabled {
				background: $bg-gray;
				color: $font-lightgray;
				pointer-events: none;
			}
		}

		.box-outline {
			padding: 24px;
			border-radius: 12px;

			.seo-description {
				pointer-events: none;
				font-size: $font-size-md;
				font-weight: 400;
				line-height: 21px;
				letter-spacing: -0.24px;
				color: $font-default;
			}
		}
	}

	.list-metaimg {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 12px;
		margin-bottom: 24px;

		.img-item {
			position: relative;
			display: flex;
			align-items: center;
			width: 200px;
			height: 200px;
			border: 1px solid $border-base;
			border-radius: 10px;
			overflow: hidden;

			> img {
				position: absolute;
				width: 100%;
				height: auto;
			}

			&:hover {
				&::after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 48px;
					background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
				}

				.btn {
					z-index: 10;
					opacity: 1;
				}
			}
		}
		.btn {
			position: absolute;
			top: 10px;
			right: 11px;
			opacity: 0;
		}
	}

	.comingsoon-text {
		font-size: $font-size-sm;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: -0.14px;
		color: $font-lightgray;
	}
}

.seowork-inner {
	flex: 1 1 auto;

	@include media-breakpoint-up(lg) {
		display: flex;
		flex-direction: row;
		height: 100%;
	}

	.codemirror-area {
		flex: 0 0 486px;
		display: flex;
		flex-direction: column;

		// width: 486px;
		padding: 24px;
		@include media-breakpoint-up(lg) {
			border-right: 1px solid $gray300;
		}
	}
	.header-codemirror {
		flex: 0 0 auto;
		height: 104px;
		margin-bottom: 24px;
		padding-bottom: 24px;
		border-bottom: 1px solid $gray300;

		.title3 {
			margin-top: 0;
		}
	}
	.body-codemirror {
		flex: 1 1 auto;

		display: flex;
		flex-direction: column;

		background: $gray50;
		border: 1px solid $gray200;
		border-radius: 11px;

		overflow: hidden;
	}

	.btn-question {
		display: inline-flex;
		align-items: center;
		font-size: $font-size-sm;
		font-weight: 500;
		color: $font-gray;
		vertical-align: middle;

		.ico + span {
			margin-left: 4px;
		}
	}

	.meta-ara {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		gap: 24px;

		padding: 40px 24px;
		@include media-breakpoint-up(lg) {
			padding-right: 8px;
		}

		overflow-y: scroll;
		form {
			display: flex;
			flex-direction: column;
			gap: 24px;
		}
		.item {
			position: relative;
			padding-bottom: 24px;
			border-bottom: 1px solid $gray300;
			&:last-child {
				border: none;
			}

			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}

// 코드미러 영역
.seo-codemirror-area {
	flex: 1 1 atuo;
	overflow-y: scroll;
	@include scrollbar;

	&.cm-theme {
		background-color: $gray50;
		// border: 1px solid $gray200;
		// border-radius: 11px;
	}

	.cm-gutter {
		// width: 30px;
		background-color: $bg-lightgray;
	}
	.cm-gutters {
		border-right: 1px solid $gray300;
	}
	.cm-foldGutter span[title="Fold line"] {
		display: inline-block;
		position: relative;
		width: 12px;
		height: 12px;
		margin-top: 4px;
		font-size: 0;
		line-height: 0;
		overflow: hidden;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 12px;
			height: 12px;
			background: url(#{$url-img}/ico-code-mirror-arrow@2x.png) 0 0 no-repeat;
			background-size: 12px 12px;
		}
	}
	.cm-foldGutter span[title="Unfold line"] {
		display: inline-block;
		position: relative;
		width: 12px;
		height: 12px;
		margin-top: 4px;
		font-size: 0;
		line-height: 0;
		overflow: hidden;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 12px;
			height: 12px;
			background: url(#{$url-img}/ico-code-mirror-arrow@2x.png) 0 0 no-repeat;
			background-size: 12px 12px;
			transform: rotate(270deg);
		}
	}

	.cm-gutterElement {
		width: 22px;
		padding-right: 8px;
		text-align: right;
		color: $gray500;
	}
	.cm-lineNumbers {
		.cm-gutterElement {
			width: 38px;
			padding-left: 8px;
			padding-right: 0;
			text-align: left;
		}
	}

	.cm-content {
		padding: 8px 0;
		font-family: $font-family-code;
		color: $font-lightgray;
		line-height: 18px;
	}
}

// 메타태그 내보내기 팝업
.popup-seocreate {
	.popup-inner {
		max-width: 1200px;
		max-height: calc(100vh - 60px);

		.popup-body {
			padding: 24px;
			overflow: hidden;
		}

		.popup-footer {
			display: flex;
			position: relative;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding-top: 25px;
			@include media-breakpoint-down(sm) {
				gap: 8px;
				flex-direction: column;
				padding-top: 16px;
				margin-top: 0;
			}

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 24px;
				width: calc(100% - 48px);
				height: 1px;
				background: $gray300;
			}

			.btn-group {
				.btn {
					min-width: auto;
				}
			}

			.wrap-left {
				.guide-area {
					display: inline-flex;
					align-items: center;
					padding: 0 20px;
					height: 56px;
					border-radius: 100px;
					border: 1px solid $gray300;
					background: $bg-lightgray;

					.ico {
						margin-right: 8px;
						flex: 0 0 auto;
					}

					p {
						font-size: $font-size-md;
						font-weight: 500;
						line-height: 21px;
						letter-spacing: -0.24px;
						color: $font-default;
					}
				}
			}
			.wrap-right {
				display: flex;
				flex-wrap: nowrap;
				gap: 6px;
			}
		}
	}

	.seocreate-result {
		.cm-editor {
			padding: 8px 0;
		}
		.cm-mergeView .ͼ1 {
			height: 100% !important;
		}

		.box {
			border-radius: 12px;

			strong {
				display: inline-flex;
				align-items: center;
				gap: 8px;
				font-size: $font-size-md;
				font-weight: 500;
				line-height: 21px;
				letter-spacing: -0.24px;
				color: $font-default;
			}
		}

		.title-area {
			@include media-breakpoint-up(lg) {
				display: flex;
				align-items: flex-end;
			}

			strong {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				gap: 4px;
			}

			.num {
				display: inline-block;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				background: $bg-darkgray;
				text-align: center;
				line-height: 20px;
				font-family: $font-family-base;
				font-size: $font-size-xs;
				font-weight: 500;
				color: $white;
			}

			.title3 {
				flex: 0 0 50%;
				@include media-breakpoint-up(lg) {
					display: flex;
					align-items: center;
					gap: 4px;
				}
				margin-top: 0;
				margin-bottom: 12px;
				font-size: $font-size-md;
				font-weight: 700;
				line-height: 21px;
				letter-spacing: -0.24px;
				color: $black;
			}

			code {
				display: inline-block;
				padding: 0 4px;
				border-radius: 4px;
				border: 1px solid $gray300;
				background: $bg-lightgray;

				font-family: $font-family-code;
				font-size: $font-size-sm;
				font-weight: 500;
				letter-spacing: -0.14px;

				&.font-green {
					color: #386500;
				}
				&.font-red {
					color: #e1382f;
				}
			}

			.btn-xs {
				font-size: $font-size-sm;
			}
		}

		.ͼ1 .cm-content {
			white-space: break-spaces;
			word-break: break-word;
			overflow-wrap: anywhere;
			flex-shrink: 1;
		}

		.ͼ1 .cm-content {
			padding: 0;
			font-family: $font-family-code;
			font-size: $font-size-sm;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: -0.14px;
			color: #bdee81;
		}

		.ͼ2r,
		.ͼ2l,
		.ͼ2o,
		.ͼ2k {
			color: #bdee81;
		}

		.ͼ2v {
			color: $font-lightgray;
			font-style: normal;
		}

		.cm-gutterElement {
			padding: 0 8px;
			text-align: left;
			font-family: $font-family-code;
			font-size: $font-size-sm;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: -0.14px;
			color: $font-lightgray;
		}

		.cm-gutter.cm-foldGutter,
		.cm-gutter.cm-changeGutter {
			border: 1px solid white !important;
			display: none !important;
		}

		.ͼ1 .cm-line {
			padding: 0 4px;
		}

		.diff-area {
			.code-mirror-merge {
				height: calc(100vh - 314px);
				overflow-y: auto;
			}

			.cm-merge-a,
			.cm-merge-b {
				min-height: calc(100vh - 314px);
				overflow: hidden;
			}
			.cm-merge-a {
				border-top-left-radius: 12px;
				border-bottom-left-radius: 12px;
				opacity: 0.9;
			}
			.cm-merge-b {
				border-top-right-radius: 12px;
				border-bottom-right-radius: 12px;
				background: #2e3235;
			}
		}
	}
}

// SEO 메타태그는 어떻게 사용되나요? 팝업
.popup-seo-guide {
	.popup-inner {
		max-height: calc(100vh - 60px);
	}

	.seo-guide-list {
		li {
			& + li {
				margin-top: 20px;
			}

			&:first-child {
				.img-box {
					height: 300px;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}

			&:last-child {
				.img-box {
					display: inline-flex;
					align-items: center;
					justify-content: center;
					padding: 33px 0;

					img {
						width: 322px;
						height: auto;
					}
				}
			}
		}

		.title-area {
			display: flex;
			align-items: center;
			gap: 8px;

			.num {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				background: $bg-default;
				font-size: $font-size-sm;
				font-weight: 500;
				line-height: 130%;
				letter-spacing: -0.14px;
				color: $font-white;
				text-align: center;
			}

			p {
				font-size: $font-size-sm;
				font-weight: 400;
				line-height: 18px;
				letter-spacing: -0.14px;
				color: $font-default;
			}
		}

		.img-box {
			width: 724px;
			margin: 8px 0 0 28px;
			border-radius: 8px;
			border: 1px solid $gray300;
			background: $bg-lightgray;
			overflow: hidden;

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}
}
