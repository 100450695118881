/* 불릿 시스템 */

.page-title {
	font-size: $font-pagetitle;
	font-weight: 700;
	line-height: 1.4;
	letter-spacing: $letter-spacing-lg;
	color: $font-default;
}

// 타이틀
// .title1 {
// 	position: relative;
// 	display: flex;
// 	align-items: center;

// 	&:before {
// 		flex: 0 0 auto;
// 		content: "";
// 	}

// 	.btn {
// 		vertical-align: initial;
// 		flex: 0 0 auto;
// 	}
// }
