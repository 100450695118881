.image-viewer {
	position: relative;
	.tools {
		position: absolute;
		right: 19px;
		top: 19px;
		display: flex;
		gap: 4px;
		z-index: 2;

		.btn-xs {
			width: 24px;
			height: 24px;
			padding: 0;
		}
	}

	.react-transform-wrapper {
		margin: 0 auto;
		padding: 82px 0;
		overflow: visible;
		cursor: grab;

		&:active {
			cursor: grabbing;
		}

		.item-container {
			position: relative;
		}
		vertical-align: top;
		img {
			max-width: none;
		}
		.pin-list {
			position: absolute;
			top: 0;
			left: 0;
		}
		.pin-item {
			$strock-width: 2px;
			position: absolute;
			opacity: 0.6;

			&:active,
			&:hover,
			&.active {
				opacity: 1;
			}

			.pin-keep {
				position: absolute;
			}
			.pin {
				position: absolute;
				left: 0;
				top: 0;
				min-width: 49px;
				transform: translateY(-99%);
				border-radius: 4px 4px 0 0;
				background: $gray700;

				span {
					display: flex;
					align-items: center;
					gap: 2px;
					height: 19px;
					padding: 4px 4px 2px 4px;
					font-size: 10px;
					white-space: nowrap;
					color: $font-white;
					line-height: 13px;
				}
			}
			.bbox {
				vertical-align: top;
				border: 2px solid $gray700;
				border-radius: 0px 4px 4px 4px;
				margin-top: -1px;
			}

			&.active {
				z-index: 50;

				.pin {
					background: $red600;
				}
				.bbox {
					border: 2px solid $red600;
				}
			}
		}

		@include media-breakpoint-down(md) {
			padding: 40px 0;
		}
	}
}
