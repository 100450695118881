/*
	Dropzone.js
	drag And Drop file upload
 */

.file-upload {
	position: relative;
	width: 100%;
	min-height: 64px;

	&.col2 {
		.preview-area {
			display: flex;
			flex-wrap: wrap;
			column-gap: 4px;

			.file-item {
				min-width: 0;
				flex: 0 0 calc(50% - 2px) !important;
			}
		}
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.5;
		filter: grayscale(100%);
	}

	.upload-area {
		width: 100%;
		min-height: 160px;
		padding: 20px;
		background-color: $bg-white;
		border: 1px dashed $border-base;
		border-radius: 16px;
	}

	.guide-text {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 65px;
		text-align: center;

		&::before {
			content: "";
			position: absolute;
			top: -52px;
			left: calc(50% - 20px);
			width: 40px;
			height: 40px;
			background: url(#{$url-img}/img-file-upload@2x.png) 0 0 no-repeat;
			background-size: 40px 40px;
		}

		p {
			font-weight: 400;
			font-size: $font-size-sm;
			letter-spacing: -0.01em;
			line-height: 18px;
			color: $font-darkgray;

			.btn {
				display: inline-block;
				vertical-align: top;
				font-weight: 400;
				font-size: $font-size-sm;
				letter-spacing: -0.01em;
				line-height: 18px;
				text-decoration: underline;
				text-underline-offset: 2px;
				color: $primary500;
			}
		}

		span {
			margin-top: 4px;
			font-weight: 400;
			font-size: $font-size-xs;
			line-height: 130%;
			letter-spacing: -0.01em;
			color: $font-lightgray;
		}
	}

	.file-item {
		position: relative;
		overflow: hidden;
		margin-bottom: 20px;

		.preview {
			width: 100px;
			height: 100px;
			border: 1px solid $gray300;
			border-radius: 50%;
			overflow: hidden;
			margin: 0 auto;
		}

		.preview-img {
			width: 100px;
			height: 100px;
			object-fit: cover;
		}
	}

	.pdf-info {
		.filename {
			display: block;
			margin-bottom: 4px;
			font-weight: 500;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.preview-text {
			font-size: 14px;
			color: #666;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}

.tool-file-upload {
	margin-top: 32px;

	&.file-upload-pdf {
		margin-top: 0;

		::before {
			display: none;
		}

		.guide-text {
			margin-top: 0;
		}

		.upload-area {
			max-height: none;
			min-height: 120px;
		}

		.file-item {
			padding: 16px 20px;
		}
	}

	.upload-area {
		display: flex;
		justify-content: center;
		align-items: center;
		max-height: 300px;
		border: 2px dashed $border-base;
		border-radius: 18px;
		background: $bg-lightgray;
	}

	.guide-text {
		position: relative;
		margin-top: 60px;

		&::before {
			content: "";
			position: absolute;
			top: -60px;
			left: calc(50% - 24px);
			width: 48px;
			height: 48px;
			background: url(#{$url-img}/img-file-upload2@2x.png) 0 0 no-repeat;
			background-size: 48px 48px;
		}

		p {
			font-size: $font-size-lg;
			font-weight: 500;
			// line-height: 25px;

			.btn {
				font-size: inherit;
				font-weight: inherit;
				line-height: inherit;
				text-decoration: none;
			}
		}

		span {
			font-size: $font-size-sm;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: -0.14px;
		}
	}

	.file-item {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 10;
		width: 350px;
		max-width: calc(100% - 40px);
		height: calc(100% - 40px);
		max-height: 350px;
		margin: 0;
		padding: 32px;
		border-radius: 12px;
		border: 1px solid $gray300;
		background: $bg-white;
		overflow: hidden;
		transform: translate3d(-50%, -50%, 0);

		.preview {
			width: auto;
			height: 100%;
			margin: 0 auto;
			border: none;
			border-radius: 0;
			overflow: hidden;
		}

		.preview-img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.btn-delete {
		position: absolute;
		top: 12px;
		right: 12px;
		width: 16px;
		height: 16px;

		.ico {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
