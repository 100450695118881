$loading-size-xs: 12px !default;
$loading-size-sm: 12px !default;
$loading-size-md: 16px !default;
$loading-size-lg: 20px !default;
$loading-size-xl: 28px !default;
$default-color: #fff;

@mixin spinner($size, $color: $default-color, $thickness: 2px) {
	width: $size;
	height: $size;
	border: $thickness solid rgba($color, 0.5);
	border-right-color: $color;
	border-radius: 50%;
}

@mixin spinner-animation {
	animation: spin 1s infinite linear;
}

.spinner-container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px;
}

.loading-spinner {
	@include spinner($loading-size-md);
	@include spinner-animation;

	&-xs {
		@include spinner($loading-size-xs);
	}
	&-sm {
		@include spinner($loading-size-sm);
	}
	&-md {
		@include spinner($loading-size-md);
	}
	&-lg {
		@include spinner($loading-size-lg);
		border-width: 2px;
	}
	&-xl {
		@include spinner($loading-size-xl);
		border-width: 3px;
	}
}

.ico-loading {
	&-xs {
		width: $loading-size-xs;
		height: $loading-size-xs;
	}
	&-sm {
		width: $loading-size-sm;
		height: $loading-size-sm;
	}
	&-md {
		width: $loading-size-md;
		height: $loading-size-md;
	}
	&-lg {
		width: $loading-size-lg;
		height: $loading-size-lg;
	}
	&-xl {
		width: $loading-size-xl;
		height: $loading-size-xl;
	}
}

.btn-loading {
	&.btn-primary:disabled {
		color: $font-white;
		background: $bg-primary;
	}

	&.btn-invalid:disabled {
		color: $font-white;
		background: $bg-invalid;
	}

	&.btn-lightgray:disabled {
		color: $font-default;
		background: $bg-lightgray;
	}

	&.btn-gray:disabled {
		color: $font-white;
		background: $bg-default;
	}

	&.btn-dark:disabled {
		color: $font-white;
		background: $bg-darkgray;
	}

	&.btn-outline-gray:disabled {
		color: $font-default;
		background: $bg-white;
		border: 1px solid $border-base;
	}

	&.btn-outline-primary:disabled {
		color: $primary500;
		background: $bg-white;
		border: 1px solid $border-primary;
	}
}

@keyframes spin {
	to {
		transform: rotate(1turn);
	}
}
