// 계정 찾기
.findid-page {
	.subject {
		margin-bottom: 48px;
		font-size: $font-size-md;
		font-weight: 500;
		line-height: 130%;
		letter-spacing: -0.24px;
	}

	.subject-area {
		margin-bottom: 48px;

		.subject {
			margin-bottom: 4px;
		}

		.subtext {
			font-size: $font-size-base;
			font-weight: 400;
			line-height: 130%;
			letter-spacing: -0.24px;
			color: $font-gray;
		}
	}

	form {
		& + .btn-group {
			margin-top: 40px;
		}
	}

	.certi-number-area {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 9px;

		* {
			font-size: $font-size-sm;
			font-weight: 400;
			line-height: 130%;
			letter-spacing: -0.14px;
			color: $primary500;
		}

		&.waiting {
			p {
				color: $font-green;
			}
		}

		&.invalid {
			span,
			p {
				color: $font-red;
			}
		}
	}
}

// 계정 찾기 결과
.findid-result-page {
	.subject {
		margin-bottom: 48px;
		font-size: $font-size-md;
		font-weight: 500;
		line-height: 130%;
		letter-spacing: -0.24px;
	}

	.subject-area {
		margin-bottom: 48px;

		.subject {
			margin-bottom: 4px;
		}

		.subtext {
			font-size: $font-size-base;
			font-weight: 400;
			line-height: 130%;
			letter-spacing: -0.24px;
			color: $font-gray;
		}
	}

	.box-point {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		min-height: 120px;
		border-radius: 11px;

		p {
			font-size: $font-size-lg;
			font-weight: 700;
			line-height: 140%;
			letter-spacing: -0.36px;
			color: $font-default;
		}
	}
}
