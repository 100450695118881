.markup-guide {
	display: flex;
	flex-direction: column;
	// height: 100%;
	padding: 20px 180px 100px;
	background-color: $bg-white;
	.title2 {
		margin-top: 0;
	}
	.guide-content {
		flex: 1 1 auto;
		max-width: 1440px;
		padding: 40px;
		> div {
			margin: 40px 0;
		}
	}
}
#guide-nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 180px;
	padding: 15px;
	z-index: 100;
	background-color: rgba(255, 255, 255, 0.95);
	.sidebar-title {
		width: 100px;
	}
	.nav-title {
		margin: 20px 0;
		padding: 10px 0;
		border-top: 1px solid $border-base;
		border-bottom: 1px solid $border-base;
		font-size: $font-size-lg;
		font-weight: 700;
	}
	// .component-list {
	// 	border-top: 1px solid $border-base;
	// }
	.link-scroll {
		display: block;
		padding: 5px 0;
		cursor: pointer;
	}
}

.gnb-list {
	display: flex;
	margin: 20px 0;
	padding-right: 24px;
	gap: 20px;

	> li {
		min-width: 100px;
		flex: 0 0 auto;
	}

	strong {
		display: block;
		font-size: 18px;
		font-weight: bold;
	}

	ul {
		margin-top: 8px;

		li {
			line-height: 1.5;
			border-left: 3px solid $gray200;
			padding-left: 8px;
		}
	}

	a:hover {
		color: $primary600;
		text-decoration: underline;
	}
}
