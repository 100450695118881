.home-page {
	.container-md {
		max-width: 1200px;
		margin: auto;
	}

	.section-home {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 80px $spacing;
		gap: 40px;
		background: $bg-black;

		.section-body {
			.container-md {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-template-rows: auto auto;
				gap: 40px;
				align-items: stretch;
			}

			.service-overview {
				grid-column: 1 / 3;
				grid-row: 1 / 2;
			}

			.promotion {
				grid-column: 1 / 2;
				grid-row: 2 / 3;

				> * {
					height: 100%;
				}
			}

			.popular {
				position: relative;
				max-width: 580px;
				grid-column: 2 / 3; // 두 번째 줄의 두 번째 열
				grid-row: 2 / 3;

				height: 320px;
				padding: 32px;
				border-radius: 32px;

				background: var(--white, #fff);
				overflow: hidden;

				&:after {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					height: 64px;
					background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);

					pointer-events: none;
				}

				.title {
					height: 34px;
					margin-bottom: 12px;
					color: var(--black, #000);
					font-size: 24px;
					font-weight: 700;
				}

				.data-list {
					a {
						padding: 8px 12px;
					}
				}

				.more {
					position: absolute;
					right: 32px;
					top: 32px;
					height: 32px;
					display: flex;
					align-items: center;
					gap: 4px;

					font-weight: 500;
				}
			}
		}

		.section-header {
			text-align: center;
			color: $font-white;

			.slogan-title {
				font-size: 40px;
				font-style: normal;
				font-weight: 700;
				line-height: 1.4;

				.a11y-embelem {
					margin-top: -6px;
					vertical-align: middle;
				}
			}

			.desc {
				margin-top: 8px;
				opacity: 0.8;
			}
		}

		@include media-breakpoint-down(md) {
			align-items: stretch;
			padding: 72px 0;

			.title {
				justify-content: center;
				font-size: 32px;

				.a11y-embelem {
					width: 32px;
					height: 32px;
				}
			}

			.section-body {
				.container-md {
					// display: flex;
					// flex-direction: column;
					column-gap: 12px;
					padding: 0 $mobile-spacing;
				}

				.popular {
					max-width: none;
				}
			}

			.cta-area {
				font-size: $font-size-sm;
			}
		}

		@include media-breakpoint-down(sm) {
			gap: 20px;

			.section-header {
				.slogan-title {
					font-size: 32px;

					svg {
						width: 32px;
						height: 32px;
					}
				}

				.desc {
					font-size: 14px;
				}
			}

			.section-body {
				.container-md {
					display: flex;
					flex-direction: column;
					padding: $mobile-spacing;
				}

				.popular {
					padding: 28px 20px;
					.more {
						top: 28px;
						right: 20px;
					}

					.data-list {
						.info {
							max-width: calc(100vw - 180px);
						}
					}
				}
			}
		}
	}

	.section-community {
		padding: 120px 20px;
		overflow: hidden;

		.section-header {
			text-align: center;

			.title {
				font-weight: bold;
				font-size: 36px;
				color: var(--black, #000);
			}

			.desc {
				margin-top: 4px;
				font-size: 16px;
				color: var(--gray-text-secondary, #4b5563);
			}
		}

		.section-body {
			padding-top: 16px;
		}

		.btn-group-center {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 20px;
		}

		.article-container {
			.default-nodata {
				height: 100px;
				min-height: auto;
			}

			+ .article-container {
				.default-nodata {
					display: none;
				}
			}

			&:first-child {
				.article-list {
					animation: scrollRight 400s linear infinite;
					animation-delay: -200s;
				}
			}

			&:last-child {
				.article-list {
					animation: scrollLeft 400s linear infinite;
					animation-delay: -100s;
				}
			}

			@keyframes scrollRight {
				from {
					transform: translateX(100%);
				}
				to {
					transform: translateX(-100%);
				}
			}

			@keyframes scrollLeft {
				from {
					transform: translateX(-100%);
				}
				to {
					transform: translateX(100%);
				}
			}
		}

		.article-list {
			margin-top: 24px;
			display: flex;
			width: max-content; // 컨텐츠 길이에 맞게 설정
			gap: 24px;

			.article-item {
				flex: 0 0 auto;
				width: 320px;
				height: 200px;
				padding: 24px;
				overflow: hidden;

				.content {
					@include text-ellipsis(3, 23px);
					max-height: none;
				}

				button {
					&:hover {
						text-decoration: none;
						cursor: text;
					}
				}
			}
		}

		@include media-breakpoint-down(md) {
			padding: 80px $mobile-spacing;
		}

		@include media-breakpoint-down(sm) {
			padding: 60px $mobile-spacing;

			.section-header {
				.title {
					font-size: 28px;
				}

				.desc {
					font-size: 14px;
				}
			}
		}
	}

	@at-root .section-cta {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 347px;
		padding: 80px $spacing;
		background: $bg-black;
		color: $font-white;
		text-align: center;

		background: url(#{$url-img}/home/bg-section-cta.png) center top;

		.title {
			font-size: 36px;
			font-style: normal;
			font-weight: 700;

			.a11y-embelem {
				width: 36px;
				height: 36px;
				margin-top: -6px;
				vertical-align: middle;
			}
		}

		.desc {
			margin-top: 8px;
			margin-bottom: 40px;
		}

		.cta-area {
			line-height: 1.4;
		}

		@include media-breakpoint-down(sm) {
			padding: 60px 0;
			height: auto;

			.title {
				font-size: 28px;

				svg {
					width: 32px;
					height: 32px;
				}
			}

			.desc {
				font-size: 14px;
			}

			.cta-area {
				font-size: $font-size-sm;
			}
		}
	}
}
