.body-community {
	background-color: $bg-lightgray;
	overflow-y: scroll;
}

.layout-community {
	// TODO : 퍼블릭모드
	// flex-direction: column;

	#content {
		background: #fff;
	}

	.page-title {
		font-size: 32px;
		font-weight: 700;
		color: var(--gray-text-primary, #1f2937);
	}

	@include media-breakpoint-up(md) {
		display: flex;
		width: 100%;

		&.mode-public {
			flex-direction: column;
		}

		#container {
			display: flex;
			justify-content: center;
			gap: 48px;

			// min-height: calc(100vh - 80px);
			max-width: 1280px;
			width: 100%;
			padding: 40px;
			margin: 0 auto;
		}

		#header {
			position: static;
			width: 100%;
		}

		#content {
			flex: 1 1 auto;
			border-radius: 36px;
			min-width: 600px;
			.container {
				padding: 48px 40px;
			}
		}

		#footer {
			flex: 1 1 auto;
		}
	}

	// @include media-breakpoint-up(lg) {
	// 	&.mode-logged {
	// 		#content {
	// 			min-width: auto;
	// 		}
	// 	}
	// }

	@include media-breakpoint-down(md) {
		#container {
			display: block;
		}
	}

	@include media-breakpoint-down(sm) {
		#content {
			.container {
				padding: 48px 20px;
			}
		}

		.page-title {
			font-size: 28px;
		}
	}

	// 로그인 후
	&.mode-logged {
		@include media-breakpoint-between(lg, xxl) {
			#container {
				gap: 24px;
				padding-right: 40px - 16px;
			}
		}
	}

	// @include media-breakpoint-down(md) {
	// 	// display: block;

	// 	.container {
	// 		padding: 40px 20px;
	// 	}
	// }

	@include media-breakpoint-down(md) {
		display: flex;
		flex-direction: column;
		min-height: 100%;

		.container {
			padding: $mobile-spacing;
		}
	}
}
