.service-overview {
	$fade-duration: 1s; // 페이드 인 지속 시간
	$chat-delay: 1s; // 채팅 시작 지연 시간
	$chat-interval: 0.6s; // 채팅 항목 간격
	$button-delay: $chat-delay + $chat-interval * 2; // 버튼 지연 시간 (2개의 채팅 항목 기준)
	$shrink-delay: 3s; // shrink 클래스 추가 지연 시간
	$total-animation-duration: $fade-duration + $button-delay + $shrink-delay + 1s; // 전체 애니메이션 시간 (버튼 노출 후 1초)

	.slider-container {
		display: flex;
		overflow: hidden;

		.service-item {
			position: relative;

			display: none;
			flex: 0 0 auto;
			width: 1200px;
			height: 400px;
			background: #fff;
			border-radius: 32px;
			overflow: hidden;
			opacity: 0;
			transition: opacity $fade-duration ease-in-out;

			&.active {
				display: flex;
				opacity: 1;
			}

			.info {
				position: relative;
				z-index: 10;

				display: flex;
				flex-direction: column;
				gap: 24px;

				padding: 40px 48px;
				color: #000;
				background: #fff;
				transition: width 1s ease-in-out $shrink-delay;

				width: 50%;

				.subject {
					display: flex;
					flex-direction: column;
					opacity: 0;
					transition: opacity $fade-duration ease-in-out;
					animation: fadeIn $fade-duration forwards;

					.copy {
						font-size: 28px;
						font-weight: bold;
						color: var(--black, #000);
					}

					.desc {
						display: none;
						margin-top: 4px;
						font-size: 16px;
						color: var(--gray-text-secondary, #4b5563);
					}
				}

				dd {
					flex: 1 1 auto;
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					.chat {
						flex: 1 1 auto;
						width: 100%;
						display: flex;
						flex-direction: column;
						gap: 12px;

						li {
							display: flex;
							gap: 8px;
							opacity: 0;
							transform: translateY(20px); /* 추가 */
							transition: opacity $fade-duration ease-in-out, transform $fade-duration ease-in-out; /* 수정 */

							&:nth-child(1) {
								animation: fadeInUp $fade-duration forwards $chat-delay; /* 수정 */
							}

							&:nth-child(2) {
								animation: fadeInUp $fade-duration forwards calc($chat-delay + $chat-interval); /* 수정 */
							}

							&.left {
								justify-content: start;
							}

							&.right {
								justify-content: end;
								flex-direction: row-reverse;

								.comment {
									border-radius: 24px 2px 24px 24px;
								}
							}

							.emoji {
								flex: 0 0 auto;
								display: flex;
								width: 32px;
								height: 32px;
								justify-content: center;
								align-items: center;
								border-radius: 16px;
								background: var(--primary300, #bfdbfe);
								font-size: 16px;
								line-height: 1;
							}

							.comment {
								max-width: 300px;
								padding: 16px;
								border-radius: 2px 24px 24px 24px;
								background: var(--gray100, #f3f4f6);
								color: var(--gray-text-secondary, #4b5563);

								strong {
									color: var(--black, #000);
								}
							}
						}
					}

					.btn {
						flex: 0 0 auto;
						opacity: 0;
						transition: opacity $fade-duration ease-in-out;
						animation: fadeIn $fade-duration forwards $button-delay;
					}
				}
			}

			.visual {
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				overflow: hidden;

				&::after {
					content: "";
					position: absolute;
					z-index: 2;
					// left: 0;
					// right: 0;
					// top: 0;
					// bottom: 0;
					left: -100px;
					right: -100px;
					top: -200px;
					bottom: -200px;
					border-radius: 100%;

					background: rgba(#fff, 0.3);
					backdrop-filter: blur(10px); /* 블러 효과 */
					transform: translate(50%, -0%, 0);
				}

				.img-content {
					width: 600px;
					height: 400px;
					vertical-align: top;
					transition: transform 0.5s ease-in-out;
				}

				// 활성화 애니메이션 처리 부분
				&.shrink {
					&::after {
						// right: 100%;
						left: 50%;
						right: 50%;
						top: 50%;
						bottom: 50%;
						transition: 1s;
					}
				}
			}
		}
	}

	.paging {
		margin-top: 24px;
		height: 28px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;
		text-align: center;
		color: #fff;

		.btn-paging {
			overflow: hidden;
			text-indent: 100%;
			white-space: nowrap;

			width: 12px;
			height: 12px;
			border: 1px solid var(--gray400, #9ca3af);
			border-radius: 6px;
			transition: 1s;

			&.active {
				font-weight: bold;
				width: 32px;
				height: 12px;
				background: var(--white, #fff);
				border-color: transparent;
			}
		}

		.btn-control {
			margin-left: 8px;
			width: 20px;
			height: 20px;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes fadeInUp {
		from {
			opacity: 0;
			transform: translateY(20px); /* 추가 */
		}
		to {
			opacity: 1;
			transform: translateY(0); /* 추가 */
		}
	}

	@include media-breakpoint-down(md) {
		.slider-container {
			.service-item {
				width: 100%;
				height: 420px;

				.info {
					height: 420px;
					padding: 40px 32px;
					.chat {
						margin-bottom: 20px;
					}
				}

				.visual {
					.img-content {
						width: auto;
						height: 100%;
					}
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.slider-container {
			.service-item {
				flex-direction: column;
				height: auto;

				&.active {
					display: flex;
				}

				.info {
					max-width: 600px;
					width: auto;
					padding: 32px 28px;
					margin: auto;
					height: auto;

					.subject {
						text-align: center;
						padding: 0 20px;
						word-break: keep-all;
					}

					dd {
						align-items: stretch;
					}

					.chat {
						font-size: 14px;
						margin-bottom: 0;
					}
				}

				.visual {
					position: relative;
					width: auto;

					.img-content {
						width: 100%;
						height: auto;
					}
				}

				.btn-group {
					display: none;
					// justify-content: center;
					// text-align: center;
				}
			}
		}
	}
}
