.title1 {
	font-size: $font-title1;
	font-weight: 700;
	letter-spacing: $letter-spacing-lg;
}

.title2 {
	margin-top: 1.5em;
	margin-bottom: 0.5em;
	font-size: $font-size-xl;
	font-weight: 700;
	letter-spacing: $letter-spacing-lg;
}

.title3 {
	margin-top: 1.5em;
	margin-bottom: 0.5em;
	font-size: $font-size-lg;
	font-weight: 700;
	letter-spacing: $letter-spacing-lg;
}

.text-sm {
	font-size: $font-size-sm !important;
}

.text-md {
	font-size: $font-size-md !important;
}

.text-lg {
	font-size: $font-size-lg !important;
}

.text-xl {
	font-size: $font-size-xl !important;
}

.text-normal {
	font-weight: normal !important;
}

.text-medium {
	font-weight: 500 !important;
}

.text-bold {
	font-weight: bold !important;
}

.text-guide {
	font-size: $font-size-sm;
	color: $font-gray;
}

.text-code {
	padding: 0.05em 0.1em;
	border-radius: 4px;
	border: 1px solid $gray200;
	background-color: $gray100;
	color: $red600;
	font-family: $font-family-code;
	font-weight: normal;
}

.text-link {
	text-decoration: underline;
	text-underline-offset: 3px;
	text-decoration-color: darken($font-whitegray, 20%);
}
