.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	border-radius: 11px;
	font-size: inherit;

	&:disabled {
		cursor: default;
	}

	&.blink {
		animation: blink 1s infinite !important;
	}
}

// 깜빡거리는 애니메이션 정의
@keyframes blink {
	0%,
	100% {
		box-shadow: 0 0 5px 2px rgba(186, 253, 2, 0.5); // #bafd02 컬러 그림자
		// background-color: $bg-primary;
	}
	50% {
		box-shadow: 0 0 10px 5px rgba(186, 253, 2, 1); // #bafd02 컬러 그림자
		// background-color: $bg-primary;
	}
}

.btn-primary {
	color: $font-white;
	background: $bg-primary;

	&:hover {
		background: $bg-darkprimary;
	}

	&:disabled {
		color: $font-white;
		background: $primary300;
	}
}

.btn-secondary {
	color: $font-black;
	background: $bg-secondary;

	// &:hover {
	// 	background: $bg-darksecondary;
	// }

	// &:disabled {
	// 	color: $font-white;
	// 	background: $secondary300;
	// }
}

.btn-invalid {
	color: $font-white;
	background: $bg-invalid;

	&:hover {
		background: $red600;
	}
}

.btn-lightgray {
	color: $font-default;
	background: $bg-lightgray;
}

.btn-gray {
	color: $font-white;
	background: $bg-default;

	&:hover {
		background: $bg-darkgray;
	}
	&:disabled {
		background: $gray300;
	}
}

.btn-dark {
	color: $font-white;
	background: $bg-darkgray;
}

.btn-black {
	color: $font-white;
	background: $bg-black;
}

.btn-outline-primary {
	color: $font-primary;
	background: $bg-white;
	border: 1px solid $border-base;

	&:hover {
		background: $bg-lightgray;
		border: 1px solid $border-dark;
	}
	&:disabled {
		color: $font-lightgray;
		background: $bg-white;
		border: 1px solid primary;
	}
}

.btn-outline-gray {
	color: $font-default;
	background: $bg-white;
	border: 1px solid $border-base;

	&:hover {
		background: $bg-lightgray;
		border: 1px solid $border-dark;
	}
	&:disabled {
		color: $font-lightgray;
		background: $bg-white;
		border: 1px solid $gray200;
	}
}

.btn-outline-none {
	color: $font-default;
	background: $bg-white;
	border: 1px solid transparent;

	&:hover {
		background: $bg-lightgray;
		// border: 1px solid $border-base;
	}

	&:disabled {
		color: $font-lightgray;
		background: $bg-white;
		border: 1px solid $gray200;
	}
}

.btn-outline-primary {
	color: $primary500;
	background: $bg-white;
	border: 1px solid $border-primary;

	&:hover {
		background: $bg-lightprimary;
		border: 1px solid $border-primary;
	}
	&:disabled {
		color: $primary400;
		background: $bg-white;
		border: 1px solid $primary300;

		.ico {
			opacity: 0.5;
		}
	}
}

.btn-outline-white {
	color: #fff;
	border: 1px solid currentColor;

	// &:hover {
	// 	background: $bg-lightprimary;
	// 	border: 1px solid $border-primary;
	// }
}

.btn-xs {
	height: $form-height-xs;
	padding: 0 8px;
	border-radius: 4px;
	font-size: 12px;
	font-weight: 500;
}

.btn-sm {
	height: $form-height-sm;
	padding: 0 8px;
	border-radius: 8px;
	font-size: 14px;
	font-weight: 500;
}

.btn-md {
	height: $form-height-md;
	padding: 0 8px;
	border-radius: 9px;
	font-size: 16px;
	font-weight: 500;
}

.btn-lg {
	height: $form-height-lg;
	padding: 0 20px;
	border-radius: 10px;
	font-size: 16px;
	font-weight: 700;
}

.btn-xl {
	height: $form-height-xl;
	padding: 0 24px;
	border-radius: 11px;
	font-size: 16px;
	font-weight: 700;
	gap: 6px;
}

.btn-block {
	display: flex;
	width: 100%;
}
