// Account 공통
.account-page {
	.btn-back {
		gap: 0;
		padding: 0;
		font-size: $font-size-md;
		color: $font-gray;
	}

	.title1 {
		margin: 40px 0 12px;
	}

	.form-group {
		padding-bottom: 0;
	}

	.form-item {
		margin-top: 39px;

		&:first-child {
			margin-top: 0;
		}

		& + .error-text,
		& + .guide-text,
		& + .waiting-text {
			margin-top: 9px;
		}

		dt {
			margin-bottom: 9px;
			font-size: $font-size-md;
			font-weight: 500;
			line-height: 130%;
			color: $font-default;

			label {
				display: inline-block;
			}
		}
	}

	.input-btn-group {
		position: relative;
	}

	.error-text {
		font-size: $font-size-sm;
		font-weight: 400;
		line-height: 18px;
		color: $font-red;

		& + .form-item,
		& + .form-control,
		& + .input-btn-group {
			margin-top: 12px;
		}
	}

	.guide-text {
		font-size: $font-size-sm;
		font-weight: 400;
		line-height: 18px;
		color: $font-lightgray;

		& + .form-item {
			margin-top: 40px;
		}
	}

	.waiting-text {
		font-size: $font-size-sm;
		font-weight: 400;
		line-height: 18px;
		color: $font-green;
	}

	.form-control {
		& + .btn-form-inline {
			position: absolute;
			right: 10px;
			top: calc(50% - 18px);
			min-width: 80px;
		}
	}

	.req {
		display: inline-block;
		position: relative;
		right: -6px;
		top: -17px;
		margin: 0 !important;
		font-size: 0;
		line-height: 0;
		color: transparent;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 4px;
			height: 4px;
			background-color: $bg-red;
			border-radius: 50%;
		}
	}

	.padding-right-100 {
		padding-right: 100px;
	}

	@include media-breakpoint-up(md) {
		max-width: 400px;
		width: 400px;
		margin: 0 auto;
	}
}

// 로그인
.login-page {
	padding-top: 28px;

	.btn-group-column {
		flex-direction: column;
		gap: 12px;
		font-weight: 700;

		.btn {
			position: relative;
			padding-left: 20px;

			&::before {
				content: "";
				position: absolute;
				top: calc(50% - 8px);
				left: 24px;
				width: 16px;
				height: 16px;
			}
		}

		.google {
			&::before {
				background: url(#{$url-img}/ico-google@2x.png);
				background-size: 16px 16px;
			}
		}
		.naver {
			&::before {
				background: url(#{$url-img}/ico-naver@2x.png);
				background-size: 16px 16px;
			}
		}

		& + p {
			margin: 20px 0;
			text-align: center;
			font-weight: 400;
			line-height: 130%;
			color: $font-lightgray;
		}
	}

	.idpw-find-list {
		position: relative;
		height: 21px;
		margin-top: 24px;

		.btn {
			position: absolute;
			height: 21px;
			padding: 0;
			font-weight: 400;
			line-height: 130%;
			letter-spacing: -0.24px;
			text-decoration: underline;
			text-underline-offset: 3px;

			&.join {
				top: 0;
				left: 0;
				color: $primary500;
			}
			&.find-id {
				top: 0;
				right: 117px;
			}
			&.reset-pw {
				top: 0;
				right: 0;
			}
		}

		@include media-breakpoint-down(sm) {
			.btn-lg {
				font-size: 13px;
			}
		}
	}

	.form-group {
		padding-bottom: 40px;
	}

	@include media-breakpoint-up(md) {
		.form-group {
			min-height: 278px;
		}
	}
}

// 로그인 팝업
.login-popup-page {
	.popup-inner {
		max-height: calc(100% - 20px);
	}

	.btn-close-popup {
		right: 24px;
	}

	.login-popup-area {
		padding-bottom: 16px;

		.btn-group-column {
			& + p {
				margin: 12px 0;
			}
		}
	}

	.form-item {
		margin-top: 20px;

		&:first-child {
			margin-top: 0;
		}

		& + .error-text,
		& + .guide-text,
		& + .waiting-text {
			margin-top: 9px;
		}

		dt {
			margin-bottom: 9px;
			font-size: $font-size-md;
			font-weight: 500;
			line-height: 130%;
			color: $font-default;

			label {
				display: inline-block;
			}
		}
	}

	.input-btn-group {
		position: relative;
	}

	.error-text {
		font-size: $font-size-sm;
		font-weight: 400;
		line-height: 18px;
		color: $font-red;

		& + .form-item,
		& + .form-control,
		& + .input-btn-group {
			margin-top: 12px;
		}
	}
	.guide-text {
		font-size: $font-size-sm;
		font-weight: 400;
		line-height: 18px;
		color: $font-lightgray;

		& + .form-item {
			margin-top: 40px;
		}
	}
	.waiting-text {
		font-size: $font-size-sm;
		font-weight: 400;
		line-height: 18px;
		color: $font-green;
	}

	.form-control {
		& + .btn-form-inline {
			position: absolute;
			right: 10px;
			top: calc(50% - 18px);
			min-width: 80px;
		}
	}

	@include media-breakpoint-up(md) {
		.login-popup-area {
			.btn-group-column {
				& + p {
					margin: 24px 0;
				}
			}
		}

		.form-item {
			margin-top: 39px;
		}

		.popup-body {
			padding-left: 40px;
			padding-right: 24px;
		}

		.login-popup-area {
			padding-top: 40px;
		}
	}
}
