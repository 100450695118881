// 회원가입
.join-page {
	padding-top: 28px;

	.pw-chk {
		margin-top: 12px;
	}

	.guide-text {
		& + .error-text {
			& + .form-item {
				margin-top: 40px;
			}
		}
	}

	.agree-area {
		margin: 60px 0 40px;

		.all-agree-check-area {
			padding-bottom: 16px;
			margin-bottom: 16px;
			border-bottom: 1px solid $gray200;

			label {
				display: inline-block;
				height: 21px;
				font-size: $font-size-base;
				font-weight: 500;
				line-height: 130%;
				letter-spacing: -0.24px;
			}
		}

		.agree-check-list {
			li {
				display: flex;
				justify-content: space-between;

				& + li {
					margin-top: 16px;
				}
			}

			.btn {
				height: 18px;
				padding: 0;
				font-size: $font-size-sm;
				font-weight: 400;
				line-height: 130%;
				letter-spacing: -0.14px;
				text-decoration: underline;
				text-underline-offset: 3px;
				color: $primary500;
			}
		}
	}

	input:disabled {
		color: $font-default;
	}

	.certi-number-area {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 9px;

		* {
			font-size: $font-size-sm;
			font-weight: 400;
			line-height: 130%;
			letter-spacing: -0.14px;
			color: $primary500;
		}

		&.waiting {
			p {
				color: $font-green;
			}
		}

		&.invalid {
			span,
			p {
				color: $font-red;
			}
		}
	}

	.input-btn-group {
		& + .error-text {
			margin-top: 9px;
		}
	}

	.input-group-form {
		& + .guide-text,
		& + .error-text {
			margin-top: 9px;
		}
	}
}
