/* 페이징 */

.pagination {
	width: 100%;
	text-align: center;
	color: $font-gray;
	margin-top: 24px;

	.page-num {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}

	a {
		position: relative;
		transition-duration: 0.3s;

		&:hover {
			background-color: $bg-lightgray;
		}
		&.active {
			background: $bg-lightprimary;
			font-weight: 500;
			color: $primary500;
		}
		&.disabled {
			pointer-events: none;
		}
	}

	a,
	strong {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		height: 40px;
		min-width: 40px;
		margin: 0 2px;
		padding: 0 2px;
		font-size: $font-size-md;
		font-weight: 400;
		color: $font-gray;

		.layout-community & {
			height: 32px;
			min-width: 32px;
			font-size: 14px;
		}
	}

	.first {
		background: url(#{$url-img}/ico-first-default@2x.png) center center no-repeat;
		background-size: 24px 24px;

		&.disabled {
			background: url(#{$url-img}/ico-first-disabled@2x.png) center center no-repeat;
			background-size: 24px 24px;
		}
	}

	.prev {
		background: url(#{$url-img}/ico-prev-default@2x.png) center center no-repeat;
		background-size: 24px 24px;

		&.disabled {
			background: url(#{$url-img}/ico-prev-disabled@2x.png) center center no-repeat;
			background-size: 24px 24px;
		}
	}

	.next {
		background: url(#{$url-img}/ico-next-default@2x.png) center center no-repeat;
		background-size: 24px 24px;

		&.disabled {
			background: url(#{$url-img}/ico-next-disabled@2x.png) center center no-repeat;
			background-size: 24px 24px;
		}
	}

	.last {
		background: url(#{$url-img}/ico-last-default@2x.png) center center no-repeat;
		background-size: 24px 24px;

		&.disabled {
			background: url(#{$url-img}/ico-last-disabled@2x.png) center center no-repeat;
			background-size: 24px 24px;
		}
	}
}
