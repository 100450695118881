.info-page {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: calc(100vh - $mobile-header-height);

	.info-inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 40px;
	}

	.logo {
		width: 142px;
		height: 30px;

		img {
			display: block;
			width: 100%;
		}
	}

	.info-area {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		padding: 0 $spacing;
		text-align: center;
		word-break: keep-all;

		img {
			width: 64px;
			height: 64px;
		}

		.message {
			margin-top: 20px;
			font-size: $font-size-xl;
			font-weight: 700;
			line-height: 140%;
			letter-spacing: -0.48px;
			color: $font-default;
		}

		.desc {
			margin-top: 8px;
			font-size: $font-size-md;
			font-weight: 400;
			line-height: 130%;
			letter-spacing: -0.24px;
			color: $font-gray;
		}

		.text-guide {
			display: flex;
			align-items: center;
			gap: 2px;
			.ico {
				margin-top: 2px;
			}
		}

		.btn-group {
			gap: 8px;
			margin-top: 40px;
		}
	}
}
