.clearfix {
	position: relative;
	@include clearfix;
}

.float-left {
	float: left !important;
}

.float-right {
	float: right !important;
}

.float-none {
	float: none !important;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.vertical-top {
	vertical-align: top !important;
}

.vertical-middle {
	vertical-align: middle !important;
}

.vertical-bottom {
	vertical-align: bottom !important;
}
