$tab-menu-size: $font-size-md;

/* 탭메뉴 */
.tabmenu {
	margin-bottom: $spacing-sm;

	// 마진 겹침 현상 해결
	&:before,
	&:after {
		content: "";
		display: table;
	}
}

.tab-list {
	display: flex;
	flex-wrap: wrap;
	text-align: center;
	font-size: $tab-menu-size;
	border-bottom: 1px solid $gray200;

	li {
		flex: 0 0 auto;
		height: 56px;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			padding: 0 16px;
		}

		span {
			@include text-ellipsis(2, 1.2em);
			font-weight: 500;
			color: $font-gray;
		}

		&.on {
			position: relative;
		}
	}

	// @include media-breakpoint-down(md) {
	// 	li {
	// 		width: 33.333%;
	// 	}
	// }

	// @include media-breakpoint-down(sm) {
	// 	li {
	// 		width: 50%;
	// 	}
	// }
}

.tabmenu-type1 {
	.tab-list {
		display: flex;
		justify-items: flex-start;
		text-align: center;
		flex-wrap: wrap;
		overflow: hidden;
		background-color: $bg-white;
		// border-radius: 10px;

		li {
			flex: 1 1 auto;
			width: 20%;
			height: 48px;

			// a {
			// 	border-radius: 10px;
			// }

			&.on {
				a {
					border: 1px solid $border-hover;
					background-color: $bg-lightprimary;
					font-weight: 700;

					span {
						color: $font-primary;
					}
				}
			}

			@include media-breakpoint-up(lg) {
				a {
					&:hover {
						border-color: transparent;
						background-color: $bg-lightgray;

						span {
							color: $font-default;
						}
					}
				}

				&.on {
					a {
						&:hover {
							border: 1px solid $border-hover;
							background-color: $bg-lightprimary;

							span {
								color: $font-primary;
							}
						}
					}
				}
			}

			&:nth-child(n + 6) {
				flex: 0 0 auto;
			}
		}

		// @include media-breakpoint-down(md) {
		// 	flex-wrap: wrap;

		// 	li {
		// 		flex: 1 1 auto;
		// 		width: 33.333%;

		// 		&:nth-child(n + 4) {
		// 			flex: 0 0 auto;
		// 		}
		// 	}
		// }

		// @include media-breakpoint-down(sm) {
		// 	li {
		// 		width: 50%;

		// 		&:nth-child(n + 3) {
		// 			flex: 0 0 auto;
		// 		}
		// 	}
		// }
	}

	&.bg-lightgray {
		border-radius: 10px;

		.tab-list {
			border: 1px solid $gray50;
			border-radius: 10px;
			background-color: $bg-lightgray !important;
		}

		li {
			@include media-breakpoint-up(lg) {
				a {
					&:hover {
						background-color: $bg-gray;
					}
				}
			}
		}
	}
}

.tabmenu-type2 {
	.tab-list {
		background-color: $bg-white;

		li {
			height: 48px;

			a {
				padding: 4px 16px;
			}

			&.on {
				a {
					position: relative;
					font-weight: 700;

					&::before {
						content: "";
						position: absolute;
						bottom: -1px;
						z-index: 10;
						left: 0;
						width: 100%;
						height: 2px;
						background: $black;
					}

					span {
						color: $font-default;
					}
				}
			}

			@include media-breakpoint-up(lg) {
				a {
					&:hover {
						background-color: $bg-lightgray;
						position: relative;

						&::before {
							content: "";
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							height: 1px;
							background: $bg-gray;
						}

						span {
							color: $font-default;
						}
					}
				}

				&.on {
					a {
						&:hover {
							background-color: $bg-lightgray;
							position: relative;

							&::before {
								content: "";
								position: absolute;
								bottom: -1px;
								left: 0;
								width: 100%;
								height: 2px;
								background: $black;
							}

							span {
								color: $font-default;
							}
						}
					}
				}
			}
		}
	}
}

.tabmenu-type3 {
	margin-bottom: 0;

	.tab-list {
		gap: 8px;
		border-bottom: 0;

		li {
			width: 28px;
			height: 28px;
			border-radius: 4px;

			&:hover {
				background: $bg-gray;
			}

			a {
				padding: 3px 1px;
			}

			&.on {
				background: $bg-gray;
			}
		}
	}
}
