.delete-page {
	.guide-text {
		font-size: $font-size-sm;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: -0.14px;
		color: $font-gray;
	}

	.btn-area {
		.btn-search {
			&::after {
				content: "";
				position: absolute;
				top: 2px;
				left: -20px;
				width: 1px;
				height: 24px;
				border-radius: 100px;
				background: $gray300;
			}
		}
	}
}

.delete-font-red {
	color: $font-red;
}
