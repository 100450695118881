.popup-feedback {
	$feedback-width-level1: 276px;
	$feedback-width-level2: 352px;
	$feedback-width: $feedback-width-level2;

	// $color1: #1364ff;
	// $color2: #a855f7;
	// $color3: #f97316;
	// $color4: #059669;

	// $bg1: mix($gray700, $color1, 80%);
	// $bg2: mix($gray700, $color2, 80%);
	// $bg3: mix($gray700, $color3, 80%);
	// $bg4: mix($gray700, $color4, 80%);

	position: absolute;
	z-index: 210;
	cursor: move;
	padding: 12px 16px;
	border-radius: 8px;
	font-size: $font-size-sm;
	color: #fff;
	background: $gray700;

	// .bg1 {
	// 	background: $bg1;
	// }

	// .bg2 {
	// 	background: $bg2;
	// }

	// .bg3 {
	// 	background: $bg3;
	// }

	// .bg4 {
	// 	background: $bg4;
	// }

	width: $feedback-width;
	right: 20px;
	bottom: 20px;

	.layout-details & {
		left: calc(50% - ($feedback-width - $sidebar-width) * 0.5);
	}

	// 사용자 레벨에 따른 UI 분기
	&.l1 {
		width: $feedback-width-level1;
		left: calc(50% - ($feedback-width-level1 - $sidebar-width) * 0.5);
	}

	.btn-text {
		color: $font-whitegray;
		text-decoration: underline;
		text-underline-offset: 3px;
		text-decoration-color: darken($font-whitegray, 20%);
	}

	.btn-minimize {
		position: absolute;
		right: 20px;
		top: 0;
		padding: 5px;
		border-radius: 0;
		line-height: 1;
		height: 25px;
		display: flex;
		align-items: center;
		font-size: 12px;
		color: $font-whitegray;
	}

	.feedback-handle {
		position: absolute;
		right: 0;
		top: 0;
		padding: 5px;
		border-radius: 0;
		line-height: 1;
	}

	.form-control {
		background: transparent;
		font-size: inherit;
		color: #fff;

		&::-webkit-input-placeholder {
			color: $gray400;
			font-size: 14px;
		}

		&:-ms-input-placeholder {
			color: $gray400;
			font-size: 14px;
		}

		&:hover,
		&:focus {
			border-color: #fff;
		}
	}

	.btn-primary {
		font-size: inherit;

		&:disabled {
			background-color: #19479b;
			color: #8f949b;
		}
	}

	.input-group {
		gap: 8px;

		.form-control {
			flex: 1 1 auto;
		}

		.btn {
			flex: 0 0 auto;
			padding: 0 16px;
			font-size: inherit;
		}
	}

	.opinion-list {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 4px;
		margin-top: 8px;

		.btn-sm {
			height: 32px;
			font-weight: normal;
			border-color: $gray500;
		}

		.btn-outline-gray {
			background: transparent;
			color: $gray300;

			&:hover {
				border-color: #fff;
			}
		}

		.input-group {
			width: 100%;
		}
	}

	.feedback-step1 {
		display: flex;
		flex-wrap: wrap;
		gap: 4px;
		align-items: center;
		padding: 4px 0;

		.message-area {
			flex: 1 1 auto;
			// word-break: keep-all !important;
			// overflow-wrap: break-word !important;
		}

		.btn-group {
			flex: 0 0 auto;
			// flex: 1 1 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 72px;
			flex-wrap: wrap;
			gap: 4px;

			.btn-report {
				width: 100%;
				font-size: $font-size-xs;
			}
		}

		.tip {
			width: 100%;
		}

		.btn {
			border-radius: 8px;
			border: 1px solid $gray500;
			height: 32px;
			padding: 0 8px;
			&:hover,
			&:focus {
				border-color: transparent;
				background: #fff;
				color: $gray700;

				.ico-thumb-up-white {
					background-image: url(#{$url-img}/ico-thumb-up-dark@2x.png);
				}

				.ico-thumb-down-white {
					background-image: url(#{$url-img}/ico-thumb-down-dark@2x.png);
				}
			}
		}
	}

	.feedback-step2 {
		.message {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&-satisfied {
			textarea {
				height: 120px !important;
			}

			.btn-group {
				display: flex;
				gap: 8px;

				.btn {
					flex: 1 1 auto;
					font-size: inherit;
				}

				.btn-outline-none {
					background: none;
					color: inherit;
					font-weight: normal;
					opacity: 0.7;
				}

				.btn-primary {
					width: 70%;
				}
			}
		}

		&-unsatisfied {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
	}

	.feedback-step3 {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 12px 0;
	}
}
