//
// 설정 공통
//
.settings {
	.settings-list {
		dl {
			display: flex;
			align-items: center;
			gap: 24px;
			min-height: 84px;
			padding: 24px 0 23px;
			border-bottom: 1px solid $gray200;

			&.sns {
				dd {
					justify-content: flex-start;
					gap: 20px;
				}
			}
		}

		dt {
			min-width: 120px;
			font-size: $font-size-base;
			font-weight: 700;
			line-height: 130%;
			letter-spacing: -0.24px;
			color: $font-default;
		}

		dd {
			display: flex;
			align-items: center;
			// justify-content: space-between;
			column-gap: 8px;
			width: 600px;
			min-height: 21px;
		}

		.con-area {
			flex: 1 1 auto;
			display: flex;
			// align-items: center;
			gap: 8px;

			&.dir-col {
				// align-content: stretch;
				flex-direction: column;
			}

			.img {
				width: 16px;
				height: 16px;

				img {
					width: 16px;
					height: 16px;
				}
			}

			p {
				font-size: $font-size-base;
				font-weight: 400;
				line-height: 130%;
				letter-spacing: -0.24px;
				color: $font-default;
			}

			.input-btn-group {
				display: flex;
				flex-direction: column;
			}
		}

		.btn-area {
			flex: 0 0 auto;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 8px;

			.btn {
				min-width: 80px;
			}
		}

		p.error-text {
			font-size: $font-size-sm;
			font-weight: 400;
			line-height: 18px;
			color: $font-red;
		}

		p.guide-text {
			margin-top: 4px;
			font-size: $font-size-sm;
			font-weight: 400;
			line-height: 18px;
			color: $font-lightgray;
		}

		@include media-breakpoint-down(sm) {
			dl {
				display: block;

				dt {
					margin-bottom: 8px;
				}

				dd {
					width: auto;
				}
			}
		}
	}

	.settings-container {
		max-width: 746px;

		.promotion-banner {
			margin-top: 32px;

			&.type-card {
				display: none;
			}

			@include media-breakpoint-down(sm) {
				&.type-band {
					display: none;
				}
				&.type-card {
					display: flex;
				}
			}
		}
	}
}
