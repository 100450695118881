.plugin-landing-page {
	// variables
	$black: #1a1a1a;
	$blue: #0066ff;
	$bg-dark: #13151a;

	// mixins
	@mixin container {
		max-width: 1200px;
		margin: 0 auto;
		padding: 0 24px;
	}

	@mixin card-base {
		background: rgba(255, 255, 255, 0.05);
		border-radius: 16px;
		padding: 32px;
	}

	.container {
		@include container;
	}

	.desc {
		text-align: center;
	}

	background: $bg-dark;
	color: white;
	line-height: 1.6;

	h1,
	h2,
	h3 {
		font-weight: bold;
		line-height: 1.4;
	}

	img {
		vertical-align: top;
	}

	.btn-xl {
		padding-left: 48px;
		padding-right: 48px;
		border-radius: 48px;
	}

	// common elements
	.badge {
		display: inline-block;
		background: rgba($secondary500, 0.1);
		border: 1px solid $secondary500;
		color: $secondary500;
		padding: 8px 16px;
		border-radius: 24px;
		margin-bottom: 24px;
	}

	.early-tag {
		padding: 8px 16px;
		border-radius: 4px;
		opacity: 0.8;
		font-weight: normal;
	}

	.button {
		display: inline-block;
		background: $blue;
		color: white;
		padding: 16px 32px;
		border-radius: 8px;
		text-decoration: none;
		font-weight: bold;
		transition: transform 0.2s;

		&:hover {
			transform: translateY(-2px);
		}
	}

	// section base
	section {
		padding: 100px 0;

		h2 {
			font-size: 2rem;
			text-align: center;
			margin-bottom: 2rem;
		}
	}

	// Hero Section
	.hero {
		background: $black;
		text-align: center;

		h1 {
			font-size: 2.5rem;
			margin-bottom: 2rem;
			font-weight: 700;
		}

		.hero-description {
			font-size: 1.25rem;
			margin-bottom: 2rem;
		}
	}

	// Features Section
	.features {
		background: linear-gradient(180deg, $black 0%, $bg-dark 100%);

		.features-grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 32px;
		}

		.feature-card {
			@include card-base;
			transition: transform 0.3s;

			svg {
				display: block;
				margin-bottom: 8px;
			}

			&:hover {
				transform: translateY(-8px);
			}

			.feature-icon {
				width: 48px;
				height: 48px;
				background: $blue;
				border-radius: 12px;
				margin-bottom: 24px;
			}

			h3 {
				margin-bottom: 1rem;
				font-size: 22px;
			}
		}
	}

	// Target Users Section
	.target-users {
		background: linear-gradient(to bottom, #13151a 0%, #13151a 40%, #0a0a0a 70%, #000 100%);

		.users-grid {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			gap: 24px;
		}

		.user-card {
			@include card-base;

			h3 {
				margin-bottom: 1rem;
			}

			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					display: flex;
					align-items: center;
					gap: 8px;
					&:before {
						content: "";
						display: block;
						width: 4px;
						height: 4px;
						border-radius: 1px;
						background-color: $secondary500;
					}
				}
			}
		}
	}

	// AI Technology Section
	.ai-tech {
		background: linear-gradient(180deg, #003db1 0%, #1364ff 100%);
		text-align: center;

		.tech-description {
			font-size: 1.25rem;
			margin-bottom: 1.5rem;
		}

		.tech-grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 32px;
		}

		.tech-card {
			background: rgba(255, 255, 255, 0.1);
			@include card-base;
			h3 {
				margin-bottom: 4px;
			}
		}

		.tech-visual {
			margin-top: 20px;
			.img-cert1 {
				max-width: 465px;
			}
			.img-cert2 {
				max-width: 391px;
			}
			.tech-cert {
				display: flex;
				justify-content: center;
				align-items: flex-end;
				gap: 32px;
			}
			.img-a11y {
				margin-top: -1px;
				max-width: 1000px;
			}
		}
	}

	// Process Section
	.process {
		.timeline {
			position: relative;
			max-width: 800px;
			margin: 40px auto 0;
			padding: 60px 0 100px;

			&:before {
				content: "";
				position: absolute;
				left: 50%;
				width: 2px;
				height: 100%;
				background: linear-gradient(to bottom, $bg-dark 0%, $blue 5%, $blue 100%);
				transform: translateX(-50%);
				top: 0;
			}
		}

		.timeline-item {
			margin-bottom: 80px;
			position: relative;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.timeline-content {
			width: calc(50% - 30px);
			@include card-base;
			position: relative;
		}

		.timeline-item:nth-child(odd) .timeline-content {
			margin-left: calc(50% + 30px);
		}

		.timeline-number {
			position: absolute;
			width: 36px;
			height: 36px;
			background: $secondary500;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			color: $black;
			left: 50%;
			top: 50%;
			margin-top: -18px;
			transform: translateX(-50%);
			z-index: 1;

			/* 그라데이션 배경 설정 */
			background: radial-gradient(
				circle at center,
				$secondary500 0%,
				$secondary500 30%,
				rgba(19, 21, 26, 0.6) 70%,
				rgba(19, 21, 26, 0.4) 90%,
				#13151a 100%
			);
			box-shadow: 0 0 15px rgba($secondary500, 0.4);
		}
	}

	// Benefits Section
	.benefits {
		background: rgba($blue, 0.05);

		.benefits-grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 24px;
		}

		.benefit-card {
			@include card-base;
			text-align: center;
		}

		.benefit-number {
			font-size: 2.5rem;
			font-weight: bold;
			color: $secondary500;
			margin-bottom: 16px;
		}
	}

	// CTA Section
	.cta {
		text-align: center;

		.cta-description {
			font-size: 1.25rem;
			margin-bottom: 1.5rem;
		}
	}

	@include media-breakpoint-down(md) {
		.container {
			padding: 0 16px;
		}

		section {
			padding: 80px 0;
		}

		.features {
			word-break: keep-all;
			.features-grid {
				grid-template-columns: repeat(3, 1fr);
				gap: 24px;
			}
		}

		.target-users {
			.users-grid {
				grid-template-columns: repeat(2, 1fr);
				gap: 16px;
			}
		}

		.ai-tech {
			.tech-grid {
				word-break: keep-all;
				grid-template-columns: repeat(3, 1fr);
				gap: 20px;
			}

			.tech-card {
				padding: 20px;
			}

			.tech-visual {
				.tech-cert {
					flex-direction: column;
					align-items: center;
					gap: 0;
				}

				.img-a11y {
					max-width: 100%;
				}
			}
		}

		.process {
			.timeline {
				padding: 40px 0 60px;
				max-width: 400px;
				margin: auto;
				&:before {
					left: 24px;
				}
			}

			.timeline-item {
				margin-bottom: 40px;
			}

			.timeline-content {
				width: calc(100% - 48px);
				margin-left: 48px !important;
			}

			.timeline-number {
				left: 24px;
				transform: translateX(-50%);
			}
		}

		.benefits {
			.benefits-grid {
				grid-template-columns: repeat(3, 1fr);
				gap: 16px;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		section {
			padding: 60px 0;
		}

		h1 {
			font-size: 1.8rem !important;
		}

		h2 {
			font-size: 1.6rem !important;
		}

		.features {
			.features-grid {
				grid-template-columns: 1fr;
				gap: 16px;
			}
		}

		.target-users {
			.users-grid {
				grid-template-columns: 1fr;
				gap: 16px;
			}
		}

		.ai-tech {
			.tech-description {
				word-break: keep-all;
			}

			.tech-grid {
				grid-template-columns: 1fr;
				gap: 16px;
			}
			.tech-visual {
				.img-cert1,
				.img-cert2 {
					max-width: 80%;
				}
			}
		}

		.benefits {
			.benefits-grid {
				grid-template-columns: 1fr;
				gap: 16px;
			}
		}

		// 모든 카드형 UI의 패딩 조정
		.feature-card,
		.user-card,
		.tech-card,
		.timeline-content,
		.benefit-card {
			padding: 24px;
		}
	}
}
