.table-acc-item {
	position: relative;
	border-radius: 12px;
	padding: 7px 23px;
	border: 1px solid $gray300;
	background: $white;
	transition: box-shadow 0.5s ease;
	overflow: hidden;

	@include media-breakpoint-down(md) {
		padding: 3px 15px;
	}

	&:hover,
	&:focus-within,
	&.active {
		box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
	}

	&.opened {
		.table-item-header {
			.step-area {
				display: none !important;
			}
		}

		.caption-text {
			display: none !important;
		}
	}

	&:not(.opened) {
		.table-item-body {
			height: 0;
			min-height: 0;
			overflow: hidden;
			border: 0;
			margin: 0;
		}
	}

	.table-item-header {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		padding: 16px 0;

		.title {
			flex: 1 0 auto;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: 36px;
			padding: 0px 12px;
			border-radius: 18px;
			background: $gray600;

			color: $white;
			font-size: 16px;
			font-weight: 700;
		}

		.left,
		.right {
			display: flex;
			align-items: center;
			gap: 12px;
		}

		.left {
			flex-grow: 0;
			flex-shrink: 1;
		}
		.right {
			flex-shrink: 0;
		}

		.badge {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			gap: 2px;
			height: 36px;
			padding: 0 11px 0 7px;
			border-radius: 100px;

			font-size: $font-size-sm;
			font-weight: 500;

			&-pass {
				border: 1px solid #5bcd9e;
				background: #ecfdf5;
				color: #085b39;
			}
			&-violation {
				border: 1px solid #fca5a5;
				background: #fef2f2;
				color: $font-red;
			}
		}

		.step-area {
			@media (max-width: 1480px) {
				li:not(.current) {
					.txt {
						display: block;
					}
				}
			}

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}

		.btn-toggle {
			min-width: 36px;
			height: 36px;
			border-radius: 50%;
			border: 1px solid $gray300;
			background: $bg-white;

			&.on {
				transform: rotate(180deg);
			}
		}
	}

	.table-item-body {
		min-height: 384px;
		border-radius: 12px;
		margin-bottom: 16px;
		border: 1px solid $gray300;
		overflow: hidden;

		@include media-breakpoint-up(lg) {
			display: flex;

			> * {
				max-height: calc(100vh - #{$titlebar-height + $footer-height + 200px});
				+ * {
					border-left: 1px solid $gray300;
				}
			}
		}

		.step-area {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 8px 20px;
			height: 56px;
			border: 0;
			border-bottom: 1px solid $gray300;
			border-radius: 0;
			background: $bg-white;

			li {
				.num {
					width: 24px;
					height: 24px;
				}
				.txt {
					font-size: $font-size-md;
					line-height: 21px;
				}
			}

			@media (max-width: 1480px) {
				li:not(.current) {
					.txt {
						display: block;
					}
				}
			}

			@include media-breakpoint-down(md) {
				padding: 8px;
				li {
					.txt {
						font-size: 14px;
					}
				}
			}
		}

		.result-area {
			display: flex;
			flex-direction: column;
			flex: 0 0 40%;
		}

		.preview-area {
			flex: 0 0 auto;
			display: flex;
			flex-direction: column;
			background: $gray100;
			overflow: hidden;
			position: relative;

			.html-preview {
				flex: 1;
				min-width: 0;
				padding: 20px;
				overflow-y: auto;
			}

			@include media-breakpoint-up(lg) {
				width: 60%;
				max-width: calc(60vw - #{$sidebar-width * 0.5 + 24px});
			}
		}
	}

	&.step1 {
		.result-area {
			.title {
				display: flex;
				align-items: center;
				gap: 4px;
				font-size: 16px;
				font-weight: bold;
				color: $font-default;

				small {
					font-size: $font-size-xs;
					font-weight: 400;
					color: $font-gray;
				}
			}
		}
	}

	&.step2 {
		.opened {
			.badge {
				display: none;
			}
		}
		.caption-text {
			display: inline-flex;
			align-items: center;
			gap: 13px;
			max-width: 600px;
			width: auto;
			height: 36px;
			padding: 0 8px;
			border-radius: 100px;
			border: 1px solid $gray200;
			background: $bg-lightgray;

			* {
				font-size: $font-size-xs;
				line-height: 16px;
			}

			.tit {
				flex: 1 0 auto;
				position: relative;
				display: inline-flex;
				align-items: center;
				gap: 4px;
				font-weight: 500;
				color: $font-gray;
				line-height: 1.3;

				&::before {
					content: "";
					position: absolute;
					top: calc(50% - 5px);
					right: -7px;
					width: 1px;
					height: 10px;
					border-radius: 100px;
					background: $gray400;
				}
			}

			.caption {
				flex: 0 1 auto;
				width: auto;
				@include text-ellipsis;
				font-weight: 400;
				color: $font-default;
				-webkit-line-clamp: 1;
				line-height: 1.3em;
			}
		}

		.caption-result {
			display: flex;
			flex-direction: column;
			flex: 1 1 auto;
			padding: 16px;

			@include media-breakpoint-up(lg) {
				padding: 20px;
				padding-right: 4px;
			}
			overflow-y: scroll;
		}

		.btn-group-flex {
			position: sticky;
			bottom: 0;
			margin-top: 24px;
		}
	}
}
