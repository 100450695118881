/* default */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;

	touch-action: manipulation;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
	-webkit-text-size-adjust: none;
	height: 100%;

	@include media-breakpoint-up(lg) {
		@include scrollbar;

		// 파이어폭스 프리픽스
		@supports (-moz-appearance: none) {
			overflow: hidden;
		}
	}
}

body {
	// min-width: $max-width;
	color: $font-default;

	font-size: $font-size-base;
	font-family: $font-family-base;
	letter-spacing: $letter-spacing;
	line-height: $line-height;

	word-wrap: break-word; // 영역 밖으로 넘치는 단어 개행
	word-break: break-all; // 글자 단위 개행
	height: 100%;
	background: $bg-white;
	// overflow-y: auto;
	// overflow-x: auto;

	@include media-breakpoint-up(lg) {
		&.body-no-overflow {
			overflow-y: hidden;
		}

		@include scrollbar;
		// 파이어폭스 프리픽스
		@supports (-moz-appearance: none) {
			overflow-y: hidden;
		}
	}
}

img {
	max-width: 100%;
	border: 0;
}

ul,
li,
ol {
	list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
	font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
dd {
	margin: 0;
}

a,
select,
button,
input,
textarea {
	font-family: inherit;
}

a {
	color: inherit;
	text-decoration: none;
}

button,
input[type="submit"] {
	margin: 0;
	padding: 0;
	border: 0;
	color: inherit;
	cursor: pointer;
	background: transparent;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

.sr-only {
	position: absolute;
	left: -9999px;
	width: 1px;
	height: 1px;
	margin: 0 !important;
	font-size: 0;
	line-height: 0;
	overflow: hidden;
}

caption.sr-only {
	position: static;
	width: auto;
	height: 0;
}

fieldset {
	display: block;
	margin: 0;
	border: 0;
	padding: 0;
}

iframe {
	border: 0;
	width: 100%;
}
