.toast {
	position: fixed;
	left: 50%;
	transform: translateX(-50%);

	display: inline-flex;
	flex-direction: row !important;
	justify-content: center;
	align-items: center;
	gap: 8px;
	max-width: 100%;
	min-height: 69px;
	padding: 20px 24px;

	border-radius: 36px;
	border: 1px solid $gray300;
	background: $bg-white;
	box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);

	font-weight: 500;
	line-height: 1;
	color: $font-darkgray;
	z-index: 1200;

	white-space: nowrap;

	// @include media-breakpoint-up(md) {
	// }
	// @include media-breakpoint-down(sm) {
	// }

	&.top {
		top: 40px;
		bottom: auto;
		animation: slide-in-top 0.5s forwards, slide-out-top 0.5s forwards 2500ms;
	}
	&.bottom {
		bottom: 40px;
		animation: slide-in-bottom 0.5s forwards, slide-out-bottom 0.5s forwards 2500ms;
	}

	&.open-top {
		top: 40px;
		bottom: auto;
		animation: open-top 0.5s forwards;
	}
	&.open-bottom {
		bottom: 40px;
		animation: open-bottom 0.5s forwards;
	}
	&.close-top {
		animation: close-top 0.5s forwards;
	}
	&.close-bottom {
		animation: close-bottom 0.5s forwards;
	}

	@keyframes slide-in-top {
		from {
			top: -80px;
			bottom: auto;
		}
		to {
			top: 40px;
			bottom: auto;
		}
	}

	@keyframes slide-out-top {
		from {
			top: 40px;
			bottom: auto;
		}
		to {
			top: -80px;
			bottom: auto;
		}
	}

	@keyframes slide-in-bottom {
		from {
			bottom: -80px;
		}
		to {
			bottom: 40px;
		}
	}

	@keyframes slide-out-bottom {
		from {
			bottom: 40px;
		}
		to {
			bottom: -80px;
		}
	}
	@keyframes open-top {
		from {
			transform: translate(-50%, -125px);
		}
		to {
			transform: translate(-50%, 0px);
		}
	}
	@keyframes open-bottom {
		from {
			transform: translate(-50%, 125px);
		}
		to {
			transform: translate(-50%, 0px);
		}
	}
	@keyframes close-top {
		from {
			transform: translate(-50%, 0px);
		}
		to {
			transform: translate(-50%, -125px);
		}
	}
	@keyframes close-bottom {
		from {
			transform: translate(-50%, 0px);
		}
		to {
			transform: translate(-50%, 125px);
		}
	}

	&.dark-theme {
		background: $gray700;
		border: 1px solid $gray700;
		color: $font-white;
	}

	.toast-icon {
		flex: 0 0 auto;
	}

	.message {
		flex: 1 1 auto;
		max-width: calc(100vw - 40px);
	}

	.btn-toast-close {
		flex: 0 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 28px;
		height: 28px;
		border-radius: 50%;
		background: $bg-darkgray;
	}
}
