.info-pannel {
	display: flex;
	gap: 24px;

	@include media-breakpoint-up(md) {
		padding: 40px;
		padding-bottom: 24px;
		border-radius: 36px;
		min-height: 260px;
		background: var(--white, #fff);
	}

	@include media-breakpoint-only(md) {
		overflow-x: auto;
	}

	@include media-breakpoint-down(sm) {
		padding: 0;
		flex-direction: column;
	}

	.col {
		position: relative;
		.title {
			height: 28px;
			margin-bottom: 12px;
			font-size: 20px;
			font-weight: 700;
		}

		.more {
			position: absolute;
			right: 0;
			top: 0;
			display: flex;
			align-items: center;
			height: 28px;
		}
	}

	.list-service {
		flex: 1 1 auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 24px;

		li {
			flex: 1 1 20%;

			.selector {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 20px;
				height: 180px;
				border-radius: 20px;

				font-size: 18px;
				font-weight: 700;
				color: $font-white;
				text-align: center;
			}

			&::before {
				content: none;
			}

			.bg-white {
				width: 48px;
				height: 48px;
				border-radius: 12px;
				background-color: $bg-white;
				background-size: 30px 30px;
			}

			.text {
				display: flex;
				gap: 2px;
				align-items: center;
				justify-content: center;
				.ico {
					flex: 0 0 auto;
					margin-right: -4px;
				}
			}
		}

		.service-img2html {
			background: $bg-primary;
		}

		.service-contrast {
			background: $violet600;
		}

		.service-seo {
			background: $orange600;
		}

		.service-table {
			background: $green600;
		}

		@include media-breakpoint-down(xxl) {
			li {
				flex: 1 1 40%;

				.selector {
					justify-content: flex-start;
					flex-direction: row;
					height: 80px;
					padding: 0 20px;
					gap: 8px;
					border-radius: 16px;

					.bg-white {
						width: 24px;
						height: 24px;
						border-radius: 6px;
						background-size: 16px 16px;
					}

					.text {
						.ico {
							display: none;
						}
					}
				}
			}
		}
	}

	.community {
		flex: 1 1 auto;
		min-width: 200px;

		.article-list {
			display: flex;
			flex-wrap: wrap;
			height: 140px;
			overflow: hidden;
			gap: 12px;

			.article-item {
				flex: 1 1 222px;
				height: 140px;
				gap: 8px;
				padding: 16px 16px 14px 16px;
				overflow: hidden;

				dt {
					white-space: nowrap;
				}

				.content {
					@include text-ellipsis(3, 1.4em, true);
				}

				button {
					&:hover {
						text-decoration: none;
						cursor: text;
					}
				}
			}
		}

		@include media-breakpoint-down(xxl) {
			.article-item {
				flex: 1 1 45%;
			}
		}
	}

	.popular-resources {
		position: inherit;
		border-radius: 20px;
		padding: 12px 16px;
		background: var(--gray100, #f3f4f6);
		height: 140px;
		overflow: hidden;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 34px;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
			pointer-events: none;
		}

		.data-list {
			a {
				width: 100%;
			}
			.info {
				width: calc(100% - 86px);
			}
		}
	}

	.popular,
	.promotion {
		overflow: hidden;
		flex: 0 0 auto;
		@include media-breakpoint-up(md) {
			min-width: 320px;
			width: 320px;
		}
	}

	.promotion-banner {
		height: 180px;
	}
}
