.linter-detail-container {
	display: flex;
	height: 100%;

	.col-code-lint {
		position: relative;
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		max-height: calc(100vh - 76px);
		overflow-y: auto;
		@include scrollbar;
		.code-mirror-html {
			flex: 1 1 auto;
		}
		.code-line-info {
			padding: 2px;
			right: 36px;
			bottom: 4px;
			position: absolute;
			font-size: $font-size-sm;
			font-family: $font-family-code;
		}
		.axe-results {
			flex: 0 0 auto;
			background: $gray100;
		}
	}

	.col-result {
		display: flex;
		flex-direction: column;
		flex: 0 0 auto;
		width: 400px;
		border-left: 1px solid $gray300;
		background: $gray100;
	}

	.step-area {
		flex: 0 0 auto;
		border-bottom: 1px solid $gray300;
		.step-list {
			display: flex;
			justify-content: space-around;
			padding: 12px;
			li {
				&.on {
					font-weight: bold;
				}
			}
		}
	}

	.results-area {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		@include scrollbar;
		.table-message {
			padding: 32px 0;
			text-align: center;
		}
	}
}
