.axe-validator {
	.title-area {
		flex: 0 0 auto;
		border-bottom: 1px solid $gray300;
		padding-left: 12px;
		font-weight: 500;
		font-size: 16px;
		line-height: 40px;
		background: $gray100;
		z-index: 10;
	}
	.axe-result-area {
		flex: 1 1 auto;
		// max-height: calc(100vh - 163px);
	}
	.axe-violation-list {
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 8px;
		padding-right: 0;
		.linter-list {
			border-radius: 12px;
			border: 1px solid $gray300;
			background: $bg-white;
		}
		dt {
			padding: 12px;
			font-weight: 500;
			border-bottom: 1px solid $gray300;
		}
		dd {
			padding: 12px;
		}

		.item-list {
			display: flex;
			flex-direction: column;
			gap: 8px;
			code {
				background-color: $gray100;
				border-radius: 4px;
				font-size: $font-size-sm;
				color: $red500;
				font-family: $font-family-code;
				white-space: pre;
			}
		}
		.title {
			.description {
				color: $green600;
				&::before {
					content: "❓";
					margin-right: 4px;
				}
			}
			// &.minor {
			// }
			// &.moderate {
			// }
			&.serious {
				.description {
					color: $red500;
					&::before {
						content: "⛔";
					}
				}
			}
			&.critical {
				.description {
					color: $red500;
					&::before {
						content: "⛔";
					}
				}
			}
		}
		.solution {
			margin: 8px 0 4px;
		}

		.fail-list {
			font-size: $font-size-sm;
			li:not(:only-child) {
				margin-left: 1em;
				list-style-type: decimal;
			}
		}
	}
}
