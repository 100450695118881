.contrast-work-detail-page {
	.contrast-container {
		position: relative;
		@include media-breakpoint-up(lg) {
			display: flex;
			height: 100%;

			.col {
				width: 50%;
				overflow: hidden;
			}

			.col-viewer-origin {
				border-right: 1px solid $gray300;
			}
		}
	}

	.col-viewer {
		overflow: hidden;
		padding: 16px;

		@include media-breakpoint-up(lg) {
			display: flex;
			flex-direction: column;
			padding: 24px;
		}

		.title-area {
			flex: 0 0 auto;
			margin-bottom: 16px;
			@include media-breakpoint-up(lg) {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.title3 {
				margin-top: 0;
				margin-bottom: 0;
				font-weight: 700;
				line-height: 36px;
				letter-spacing: -0.36px;
			}

			.level {
				display: inline-flex;
				align-items: center;
				gap: 13px;
				height: 24px;
				margin-right: 4px;
				padding: 0 8px;
				border-radius: 100px;

				* {
					font-size: $font-size-sm;
					font-weight: 500;
					line-height: 18px;
					letter-spacing: -0.14px;
				}

				span {
					&:first-child {
						position: relative;
						font-weight: 700;

						&::before {
							content: "";
							position: absolute;
							top: calc(50% - 5px);
							right: -8px;
							width: 1px;
							height: 10px;
							border-radius: 100px;
						}
					}
				}

				&.level-aa {
					border: 1px solid #5bcd9e;
					background: #e2fff3;
					color: #085b39;

					span {
						&:first-child {
							&::before {
								background: #5bcd9e;
							}
						}
					}
				}
				&.level-a {
					border: 1px solid $gray300;
					background: $bg-lightgray;
					color: $font-default;

					span {
						&:first-child {
							&::before {
								background: $gray300;
							}
						}
					}
				}
			}

			p {
				font-size: $font-size-md;
				font-weight: 400;
				line-height: 21px;
				letter-spacing: -0.24px;
				color: $font-gray;
			}

			.text-warning {
				font-weight: 700;
				color: $red600 !important;
			}

			.info {
				display: flex;
				gap: 8px;
			}
		}

		.viewer-area {
			flex: 1 1 auto;
			border-radius: 20px;
			border: 1px solid $gray300;
			background: $gray100;
			overflow: hidden;

			.loading-area {
				height: 100%;
				background: $gray50;
			}
		}

		.image-viewer {
			height: 100%;
		}
	}

	.col-analysis {
		display: flex;
		flex-direction: column;

		+ .col-viewer-result {
			display: none;
		}
	}

	.col-result-clear {
		display: flex;
		align-items: center;
		justify-content: center;
		background: $gray50;
	}
}
