.text-gray {
	color: $font-gray !important;
}

.text-lightgray {
	color: $font-lightgray !important;
}

.text-whitegray {
	color: $font-whitegray !important;
}

.text-primary {
	color: $font-primary !important;
}

.text-secondary {
	color: $font-secondary !important;
}

.text-invalid {
	color: $font-invalid !important;
}

.text-valid {
	color: $font-valid !important;
}

// .text-warning {
// 	color: $font-warning !important;
// }
