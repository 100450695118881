.table-work-detail-page {
	display: flex;
	height: 100%;

	.body-details {
		overflow-y: scroll;
		background: none;
		padding: 16px;

		@include media-breakpoint-up(lg) {
			padding: 24px;
			padding-right: 8px;
		}
	}

	.col-editor {
		position: relative;
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		padding: 24px;

		.title-area {
			flex: 0 0 auto;
			display: flex;
			justify-content: space-between;
			height: 36px;
			align-items: center;
			margin-bottom: 16px;

			.title {
				font-weight: 700;
			}
		}

		.editor-body {
			flex: 1 1 auto;
			// max-height: calc(100vh - 76px);
			display: flex;
			flex-direction: column;
			overflow: hidden;
		}

		.inner {
			display: flex;
			height: 100%;
		}
	}

	.col-result {
		display: flex;
		flex-direction: column;
		flex: 0 0 40%;
		// width: 400px;
		border-left: 1px solid $gray300;
		background: $gray100;
	}

	.results-area {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		@include scrollbar;
		.table-message {
			padding: 32px 0;
			text-align: center;
		}
	}

	.content-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 34px;
		margin-bottom: 16px;
		@include media-breakpoint-down(md) {
			flex-direction: column;
			align-items: unset;
			gap: 4px;
		}

		.title-area {
			display: flex;
			align-items: center;
			gap: 8px;

			.title {
				font-size: $font-size-lg;
				font-weight: 700;
				line-height: 25px;
				letter-spacing: -0.36px;
				color: $font-default;
			}
		}

		.guide-text {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			gap: 8px;
			min-height: 34px;
			padding: 2px 11px;
			border-radius: 100px;
			border: 1px solid #fca5a5;
			background: #fef2f2;

			font-size: $font-size-sm;
			font-weight: 500;
			line-height: 18px;
			letter-spacing: -0.14px;
			color: $font-default;
		}

		.step-area {
			background: $bg-white;
		}
	}

	.table-container {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.table-acc-loading {
		// position: relative;
		min-height: 400px;
		box-shadow: none !important;
		// border-radius: 12px;
		// padding: 7px 23px;
		// border: 1px solid $gray300;
		// background: $white;
		// overflow: hidden;
	}
}
