.loading-area {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	// min-height: 400px; // todo 확인 필요
	.loading-bar {
		flex: 1 1 auto;
	}
}

.loading-bar {
	&.full {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
	}

	&.full-dimed {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
		background: none;

		.dimed {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 400;
			background: rgba(0, 0, 0, 0.3);
			backdrop-filter: blur(10px);
		}

		.spinner {
			position: absolute;
			z-index: 500;
		}
	}

	color: $font-primary;

	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;

	$spinner-size: 80px;
	$stroke-width: 10px;

	.spinner {
		box-sizing: border-box;
		width: $spinner-size;
		height: $spinner-size;
		padding: $stroke-width * 0.5;
		overflow: visible;

		> circle {
			fill: none;
			stroke: #1264ff;
			cx: 50%;
			cy: 50%;
			r: 50%;
			stroke-width: $stroke-width;
			stroke-linecap: round;
			transform-origin: center;
		}
	}
	&.fade-enter {
		opacity: 1;
		transition: opacity 0.3s;
	}
	&.fade-leave {
		opacity: 0;
		transition: opacity 0.3s;
	}
}

.loading-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(10px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
	z-index: 100; /* 기존 컨텐츠 위에 표시 */

	// .loading-bar {}
	.loading-text {
		margin-top: 12px;
		color: $font-default;
		font-size: 16px;
		font-weight: 700;
	}
}
