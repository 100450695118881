.editor-wrap {
	.btn-h1,
	.btn-h2,
	.btn-h3 {
		display: flex;
		align-items: center;
		height: 28px;
		font-size: 14px;
		font-weight: 700;
		letter-spacing: -0.01em;
		text-align: left;
		color: $font-default;
		word-break: keep-all;
	}
	.btn-h2 {
		font-weight: 500;
	}
	.btn-h3 {
		font-weight: 400;
	}

	.left-menu {
		position: absolute;
		z-index: 1000;
		top: -48px;
		left: 0;

		display: flex;
		flex-direction: row;
		// min-width: 100px;
		// width: auto;
		width: max-content;
		height: 44px;
		padding: 7px 8px;
		background: #fff;
		border: 1px solid #d1d5db;
		border-radius: 12px;
		box-shadow: $shadow;
		text-align: left;

		& > button {
			flex: 0 0 auto;
			height: 28px;
			padding: 0 6px;
			border-radius: 5px;
			line-height: 1;

			&.is-active,
			&.on {
				background: $bg-gray;
			}
		}
		.tool-line {
			position: relative;
			height: 28px;
			padding: 0 4px;
			&::after {
				position: absolute;
				top: calc(50% - 8px);
				left: 4px;
				display: block;
				width: 1px;
				height: 16px;
				background-color: $gray300;
				content: "";
			}
		}

		&.table-menu {
			top: 0px;
			left: 20px;

			flex-direction: column;
			width: 133px;
			height: auto;
			& > button {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 6px;

				&:hover {
					background: $bg-gray;
				}
			}

			.tool-line {
				height: 1px;
				padding: 4px 0;
				&::after {
					top: calc(50% - 0.5px);
					left: 0;
					width: 100%;
					height: 1px;
				}
			}
		}
	}
}

// 스크롤 방지
.no-scroll {
	.editor-wrap .edit-area {
		padding-right: 16px;
		overflow-y: hidden;
	}
}

/* Basic editor styles */
.tiptap {
	padding: 36px 8px 24px 20px;
	margin-left: 24px;

	font-size: 16px;
	font-weight: 400;
	line-height: 1.312rem;
	letter-spacing: -0.015em;
	text-align: left;
	color: #4b5563;

	&:focus {
		outline: none;
	}

	@include media-breakpoint-down(sm) {
		padding: 25px 8px 24px 20px;
		margin-left: 12px;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 0.5em;
		margin-bottom: 0.5em;

		color: #1f2937;
		font-weight: 700;
		line-height: 1.3em;
	}

	h1 {
		font-size: 28px;
		margin-top: 0.5em;
		margin-bottom: 0.5em;
	}

	h2 {
		font-size: 24px;
	}

	h3 {
		font-size: 18px;
	}

	p,
	ul,
	ol,
	table {
		margin-top: 0.5em;
		margin-bottom: 0.5em;

		color: #1f2937;
		font-size: 16px;
		line-height: 1.4em;
	}

	ul,
	ol {
		li {
			color: #1f2937;
			font-size: 16px;
			font-weight: 400;

			p,
			ul,
			ol {
				margin-top: 0;
				margin-bottom: 0;

				+ p,
				+ ul,
				+ ol {
					margin-top: 0.2em;
				}
			}
		}
	}

	ul {
		margin-left: 12px;
		padding-left: 8px;
		li {
			list-style-type: disc;
		}
	}
	// ol,
	ol {
		// counter-reset: li;
		margin-left: 20px;
		li {
			list-style-type: decimal;
			p {
				padding-left: 8px;
			}
			// &:before {
			// 	position: absolute;
			// 	top: 4px;
			// 	left: 0px;
			// 	min-width: 16px;
			// 	// margin-right: 10px;
			// 	line-height: 1.3rem;
			// 	color: #1f2937;
			// 	box-sizing: border-box;
			// 	content: counter(li) ".";
			// 	counter-increment: li;
			// 	z-index: 50;
			// 	text-align: center;
			// }
		}
	}

	strong {
		font-weight: 700;
	}

	code {
		background-color: rgba(#616161, 0.1);
		color: #616161;
	}

	pre {
		background: #0d0d0d;
		color: #fff;
		font-family: "JetBrainsMono", monospace;
		padding: 0.75rem 1rem;
		border-radius: 0.5rem;

		code {
			color: inherit;
			padding: 0;
			background: none;
			font-size: 0.8rem;
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	blockquote {
		padding-left: 1rem;
		border-left: 2px solid rgba(#0d0d0d, 0.1);
	}

	hr {
		border: none;
		border-top: 2px solid rgba(#0d0d0d, 0.1);
		margin: 2rem 0;
	}
}

// table 메뉴
.tiptap {
	::selection {
		// background: #dbeafe;
		background: rgba(96, 165, 250, 0.2);
	}
	table {
		border-collapse: collapse;
		margin: 0;
		overflow: hidden;
		table-layout: fixed;
		width: 100%;

		color: $font-default;

		td,
		th {
			border: 1px solid $gray300;
			box-sizing: border-box;
			min-width: 1em;
			padding: 7.5px;
			position: relative;
			vertical-align: top;

			> * {
				margin-bottom: 0;
			}
		}

		th {
			background-color: $bg-gray;
			font-weight: 500;
			text-align: left;
		}

		.selectedCell {
			// background-color: $bg-brightprimary;
			&:after {
				background: rgba(96, 165, 250, 0.2);
				position: absolute;
				top: -1px;
				bottom: -1px;
				left: -1px;
				right: -1px;

				border: 1px solid $primary400;
				content: "";
				z-index: 2;
				pointer-events: none;
			}
		}

		.column-resize-handle {
			background-color: #adf;
			bottom: -2px;
			position: absolute;
			right: -2px;
			pointer-events: none;
			top: 0;
			width: 4px;
		}

		p {
			margin: 0;
		}
	}

	a {
		// color: #68cef8;
		color: $primary500;
	}

	code {
		background-color: rgba(#616161, 0.1);
		border-radius: 0.25em;
		box-decoration-break: clone;
		color: #616161;
		font-size: 0.9rem;
		padding: 0.25em;
	}
}

// Top toolbar
.editor-toolbar {
	flex: 0 0 auto;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	// gap: 10px;
	min-height: 44px;
	padding: 8px 12px;
	background-color: $gray100;
	border-bottom: 1px solid #e5e7eb;
	z-index: 100;
	& > button {
		display: inline-flex;
		align-items: center;
		justify-items: center;
		height: 28px;
		padding: 6px;

		&.is-active,
		&.on {
			border-radius: 5px;
			background: #e5e7eb;
		}
	}
	.tool-line {
		position: relative;
		height: 28px;
		padding: 0 4px;
		&::after {
			position: absolute;
			top: calc(50% - 8px);
			left: 4px;
			display: block;
			width: 1px;
			height: 16px;
			background-color: $gray300;
			content: "";
		}
	}
}

.bubble-menu {
	display: flex;
	// gap: 4px;
	// min-width: 364px;
	// width: max-content;

	padding: 7px 8px;
	background: #fff;
	border: 1px solid #d1d5db;
	border-radius: 12px;
	box-shadow: $shadow;
	z-index: 500;

	& > button {
		display: flex;
		align-items: center;
		justify-items: center;
		height: 28px;
		padding: 6px;

		&.is-active,
		&.on {
			border-radius: 5px;
			background: $bg-gray;
		}
	}

	.tool-line {
		position: relative;
		height: 28px;
		padding: 0 4px;
		&::after {
			position: absolute;
			top: calc(50% - 8px);
			left: 4px;
			display: block;
			width: 1px;
			height: 16px;
			background-color: #d1d5db;
			content: "";
		}
	}
}

.table-cell-menu {
	position: relative;
	display: flex;
	flex-direction: row;
	gap: 6px;

	// width: 133px;
	height: auto;

	button {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 6px;
		height: 28px;
		padding: 0 6px;
		border-radius: 5px;

		&:hover {
			background: $bg-gray;
		}
	}
	& > .toggle-menu {
		position: relative;
	}
	// .tool-line {
	// 	height: 1px;
	// 	padding: 4px 0;
	// 	&::after {
	// 		top: calc(50% - 0.5px);
	// 		left: 0;
	// 		width: 100%;
	// 		height: 1px;
	// 	}
	// }
}

.table-sub-menu {
	position: absolute;
	top: 40px;
	left: 50%;
	display: none;
	min-width: 120px;
	padding: 7px 8px;
	background: #fff;
	border: 1px solid #d1d5db;
	border-radius: 12px;
	box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.2);
	transition: all 0.3s ease;
	transform: translateX(-50%);

	&.active {
		display: flex;
		flex-direction: column;
	}
	& > button {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 6px;
		height: 28px;
		padding: 0 6px;
		border-radius: 5px;

		&:hover {
			background: $bg-gray;
		}
	}
}

.tippy-box {
	max-width: initial !important;
}

// 링크 페널
.link-detail-panel {
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 5px;
	padding: 12px;
	background: $bg-white;
	border: 1px solid $gray300;
	border-radius: 12px;
	box-shadow: $shadow;
	z-index: 100;

	.input-group {
		gap: 8px;
	}
	.form-control-sm {
		width: 200px;
	}
	.btn-sm {
		width: 56px;
	}
	.btn-xs {
		width: 42px;
		padding: 0px 6px;
		font-size: $font-size-sm;
		border-radius: 7px;
	}
	.detail-group {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.resize-cursor {
	cursor: ew-resize;
	cursor: col-resize;
}

// block type
// .node-rootblock {
// 	&:focus,
// 	&:hover {
// 		.node-handle,
// 		.drag-handle {
// 			opacity: 1;
// 			visibility: visible;
// 		}
// 		.node-view-content {
// 			// background-color: #eff6ff;
// 			border-radius: 4px;
// 		}
// 	}
// }

.node-view-wrapper,
.draggable-item {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	width: 100%;
	gap: 0.5rem;
	white-space: normal;

	.node-wrap {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		max-width: 64rem;
	}

	// .node-handle,
	// .drag-handle {
	// 	position: absolute;
	// 	display: flex;
	// 	width: 12px;
	// 	height: 100%;
	// 	opacity: 0;
	// 	visibility: hidden;
	// 	// transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	// 	transition-property: opacity;
	// 	transition-duration: 0.3s;
	// 	transition-timing-function: ease-in-out;
	// 	&.visible {
	// 		opacity: 1;
	// 		visibility: visible;
	// 		& + .node-view-content {
	// 			background-color: #eff6ff;
	// 			border-radius: 4px;
	// 		}
	// 	}
	// }

	// ul {
	// 	.node-handle,
	// 	.drag-handle {
	// 		position: absolute;
	// 		display: flex;
	// 		width: 12px;
	// 		&.visible {
	// 			opacity: 1;
	// 			& + .node-view-content {
	// 				background-color: #eff6ff;
	// 				border-radius: 4px;
	// 			}
	// 		}
	// 	}
	// }
	.node-view-content {
		padding: 4px 0;
	}
	.cursor-grab {
		max-height: 29px;
		margin-top: 4px;
	}
	.node-view-content {
		width: calc(100% - 22px);
		margin-left: 20px;
	}
}

.drag-handle {
	position: fixed;
	// max-width: calc(100% - 40px);
	width: 12px;
	height: 21px;
	background: url(#{$url-img}/ico-handle.svg);
	background-size: 12px auto;
	background-position: center;
	border-radius: 2px;
	opacity: 1;
	transition: opacity 0.2s ease-in;
	z-index: 100;
	cursor: grab;
}

.ProseMirror-selectednode {
	position: relative;
	background-color: #eff6ff;
	border-radius: 4px;

	// &::before {
	// 	position: absolute;
	// 	top: 0;
	// 	left: -21px;
	// 	width: 12px;
	// 	height: 21px;
	// 	background-color: red;
	// 	background: url(#{$url-img}/ico-handle.svg);
	// 	background-size: 12px auto;
	// 	background-position: center;
	// 	z-index: 100;
	// 	content: "";
	// }
}
