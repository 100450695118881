//
// 사용권
//
.setting-license-page {
	.content-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.settings-list {
		dd {
			.btn {
				min-width: 128px;
			}
		}
	}

	.license-input-item {
		dd {
			align-items: flex-start;
		}

		.con-area {
			flex-direction: column;
		}
	}

	.available-licenses-item {
		.con-area {
			flex-direction: column;
			gap: 0;
		}
	}
}

.license-results-alert {
	.popup-inner {
		width: 460px !important;
	}

	.box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 24px;
		// margin-bottom: 20px;
		border-radius: 12px;
		padding: 20px;

		.text {
			font-size: $font-size-md;
			font-weight: 700;
			line-height: 21px;
			letter-spacing: -0.24px;
		}

		.num {
			flex: 1 0 auto;
			min-width: 56px;
			font-size: $font-size-md;
			font-weight: 700;
			line-height: 21px;
			letter-spacing: -0.24px;
			color: $primary500;
			text-align: right;
		}
	}

	.license-results-notice {
		margin-top: 20px;
		line-height: 1.4;

		strong {
			color: $font-black;
			font-weight: bold;
		}

		p {
			margin-top: 8px;
		}

		.text-output {
			white-space: pre-line;
		}
	}
}

.event-code-alert {
	.popup-inner {
		width: 460px !important;
	}

	.box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 24px;
		// margin-bottom: 20px;
		border-radius: 12px;
		padding: 20px;

		.text {
			font-size: $font-size-md;
			font-weight: 700;
			line-height: 21px;
			letter-spacing: -0.24px;
		}

		.num {
			flex: 1 0 auto;
			min-width: 56px;
			font-size: $font-size-md;
			font-weight: 700;
			line-height: 21px;
			letter-spacing: -0.24px;
			color: $primary500;
			text-align: right;
		}
	}

	.license-results-notice {
		margin-top: 20px;
		line-height: 1.4;

		strong {
			color: $font-black;
			font-weight: bold;
		}

		p {
			margin-top: 8px;
		}

		.text-output {
			white-space: pre-line;
		}
	}
}

.license-popup {
	.popup-inner {
		max-height: calc(100vh - 120px);
	}
	.popup-body {
		min-height: 234px;
	}

	.license-list-area {
		display: flex;
		height: 100%;
		padding-bottom: 24px;

		> div {
			width: 100%;
		}

		.subject {
			font-size: $font-size-sm;
			font-weight: 400;
			line-height: 18px;
			color: $font-gray;
		}

		.license-list {
			margin-top: 12px;
			border-bottom: 1px solid $gray200;

			li {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 40px;
				width: 100%;
				padding: 23px 20px;
				border-top: 1px solid $gray200;
			}
			.content {
				max-width: 616px;
				width: auto;

				strong {
					font-size: $font-size-lg;
					font-weight: 700;
					line-height: 25px;
					letter-spacing: -0.36px;
					color: $font-default;
				}
				p {
					margin-top: 4px;
					font-size: $font-size-sm;
					font-weight: 400;
					line-height: 130%;
					color: $font-gray;
				}
				.date {
					display: flex;
					align-items: center;
					gap: 9px;
					margin-top: 8px;
					font-size: $font-size-sm;
					font-weight: 400;
					line-height: 18px;
					letter-spacing: -0.14px;
					color: $font-lightgray;

					span {
						& + span {
							position: relative;

							&::before {
								content: "";
								position: absolute;
								top: calc(50% - 7px);
								left: -5px;
								width: 1px;
								height: 14px;
								border-radius: 100px;
								background: $bg-gray;
							}
						}
					}
				}
			}

			.num {
				flex: 0 0 auto;
				min-width: 56px;
				width: auto;
				font-size: $font-size-md;
				font-weight: 700;
				line-height: 21px;
				letter-spacing: -0.24px;
				color: $bg-primary;
				text-align: right;
			}

			@include media-breakpoint-down(sm) {
				li {
					gap: 20px;
					padding: 20px 0;
				}

				.content {
					strong {
						font-size: 16px;
						line-height: unset;
					}
				}
			}
		}
	}

	.nodata-area {
		width: 100%;

		p {
			margin-top: 76px;
			font-size: 24px;
			font-weight: 400;
			line-height: 34px;
			letter-spacing: -0.48px;
			color: $font-lightgray;
			text-align: center;
		}
	}
}
