.layout-details {
	position: relative;
	display: flex;
	flex-direction: row;
	background-color: $bg-lightgray;

	#content {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;

		& > div {
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;
		}
	}

	@include media-breakpoint-up(lg) {
		min-width: $max-width;
		height: 100%;
		overflow: hidden;
	}

	@include media-breakpoint-down(md) {
		display: block;
	}

	.container {
		overflow: hidden;
	}

	.header-details {
		z-index: 200;

		flex: 0 0 auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		gap: 20px;

		@include media-breakpoint-up(lg) {
			min-width: $max-width - 300px;
		}

		height: $titlebar-height;
		padding: 20px 24px;

		border-bottom: 1px solid $gray300;
		background-color: $bg-white;

		.title3 {
			flex: 0 0 auto;
			display: flex;
			align-items: center;
			margin: 0;
			.ico + span {
				margin-left: 12px;
			}
		}

		.tit-file {
			flex: 0 1 auto;
			position: relative;

			display: flex;
			flex-direction: column;
			justify-content: center;

			max-width: 775px;
			padding-right: 24px;
			overflow: hidden;

			span {
				display: inline-block;
				line-height: 2;
				@include text-ellipsis(1, 1.4);
			}

			.btn-edit {
				flex: 0 0 auto;
				position: absolute;
				right: 0;
				display: flex;
				opacity: 0;
				transition: opacity 0.2s ease, visibility 0.2s ease;
				transition-delay: 0.1s;
				&:focus {
					opacity: 1;
				}
			}

			&:hover,
			&:focus {
				.btn-edit {
					position: absolute;
					right: -24px;
					right: 0;
					opacity: 1;
				}
			}
		}

		.info-area {
			text-align: right;
		}

		.save-text {
			flex: 0 0 auto;
			display: flex;
			align-items: center;
			font-size: $font-size-sm;
			.ico {
				margin-top: 2px;
				+ .time {
					margin-left: 4px;
				}
			}
			.time {
				font-weight: 400;
				+ .text {
					margin-left: 4px;
				}
			}
			.text {
				font-weight: 400;
			}
			.link {
				font-weight: 400;
				color: $primary500;
			}
		}

		.warning {
			margin-top: 2px;
			font-size: 12px;
			color: #93959a;
		}

		@include media-breakpoint-down(md) {
			justify-content: unset;
			gap: 8px;
			.title3 {
				.ico + span {
					display: none;
				}
			}
			.tit-file {
				align-items: flex-start;
			}
			.info-area {
				flex: 1 1 auto;
				.save-text {
					display: block;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			.info-area {
				display: none;
			}
		}
	}
	.body-details {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		background-color: $bg-white;
		overflow: hidden;

		.scroll-inner {
			overflow: auto;
			@include scrollbar;
		}
	}
	.footer-details {
		padding: 0 20px;
		border-top: 1px solid $gray300;
		background-color: $bg-white;

		flex: 0 0 auto;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		height: $footer-height;
		z-index: 170;

		.guide-area {
			display: flex;
			align-items: center;
			padding: 0 20px;
			height: 48px;
			border-radius: 100px;
			border: 1px solid $gray300;
			background: $bg-lightgray;

			&.complete {
				border-color: $black;
				background: $bg-black;
				p {
					color: $font-white;
				}
			}

			.ico {
				margin-right: 8px;
				flex: 0 0 auto;
			}

			p {
				font-size: $font-size-sm;
				font-weight: 500;
				line-height: 18px;
				letter-spacing: -0.14px;
				color: $font-default;
			}
		}

		.wrap-left,
		.wrap-right {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			gap: 8px;
		}

		.wrap-left {
			gap: 12px;
		}
		@include media-breakpoint-down(md) {
			height: 80px;
			display: flex;
			gap: 6px;
			padding: 12px;

			.guide-area {
				max-width: 280px;
			}

			.wrap-left,
			.wrap-right {
				gap: 6px;
			}

			.btn-lg {
				height: 36px;
				font-size: 14px;
				padding: 0 12px;
			}
		}
		@include media-breakpoint-down(sm) {
			gap: 6px;
			flex-direction: column;
			height: auto;
			align-items: unset;

			.wrap-right {
				justify-content: flex-end;
			}
		}
	}
}

.edit-popup {
	.popup-inner {
		.popup-body {
			padding: 36px 36px 20px;
		}
		.popup-footer {
			padding: 20px 36px 36px;
		}
	}
	.inner {
		margin-top: 12px;
	}
	.text-count {
		display: block;
		margin-top: 9px;
		text-align: right;
		font-size: 14px;
		color: $font-lightgray;
	}
	.btn-close-popup {
		display: none;
	}
}
