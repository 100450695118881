$dirs: (
	top: t,
	left: l,
	right: r,
	bottom: b
);
$total: 11;
$gap: 4px;

@for $i from 0 to $total {
	.m-#{$i} {
		margin: $i * $gap !important;
	}
}

@for $i from 0 to $total {
	.p-#{$i} {
		padding: $i * $gap !important;
	}
}

@each $dir, $value in $dirs {
	@for $i from 0 to $total {
		.m#{$value}-#{$i} {
			margin-#{$dir}: $i * $gap !important;
		}
	}
}

@each $dir, $value in $dirs {
	@for $i from 0 to $total {
		.p#{$value}-#{$i} {
			padding-#{$dir}: $i * $gap !important;
		}
	}
}
