.form-group {
	padding-bottom: 48px;

	dt {
		margin-bottom: 12px;
	}
}

.input-group-btn {
	display: flex;
	gap: 8px;

	.btn,
	.check-item-btn {
		flex: 1 1 20%;

		.label,
		label {
			display: flex;
			width: 100%;
		}
	}

	.input-item-btn {
		flex: 1 1 20%;
		height: 39px;
		border-radius: 8px;
		background: #f3f3f3;
		font-size: 16px;
		color: $default;
		&:active {
			background: $bg-primary;
			color: #fff;
		}
	}
	& + .input-group-btn,
	& + .input-group-form {
		margin-top: 8px;
	}
}

.input-group-period {
	display: flex;
	align-items: center;
	gap: 6px;

	.mx-datepicker {
		flex: 1 1 auto;
		width: auto;
	}
}

// 텍스트필드 초기화 버튼
.input-group-form {
	position: relative;

	.btn-input-reset {
		position: absolute;
		top: 7px;
		right: 10px;
		height: calc(100% - 10px);
		padding: 0 2px 0 8px;
	}
}

.input-group {
	display: flex;

	.input-addon {
		flex: 1 0 auto;
		margin-left: 12px;

		.btn {
			min-width: 100px;
			width: auto;
		}
	}

	& + .error-text {
		margin-top: 9px;
	}
	& + .input-btn-group {
		margin-top: 12px;
	}
}
