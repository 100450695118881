.resource-page {
	.title-area {
		text-align: center;
		margin-bottom: 16px;
	}

	.top-info {
		flex-shrink: 0;

		display: flex;
		justify-content: space-between;
		align-items: center;

		margin-bottom: 48px;
		padding: 18px 24px;
		gap: 16px;
		border-radius: 8px;
		background: var(--gray100, #f3f4f6);

		.label {
			display: inline-flex;
			align-items: center;
			justify-content: center;

			height: 17px;
			padding: 0 4px;
			margin-top: -2px;
			border-radius: 10px;

			font-size: 11px;
			// line-height: 1;
			font-weight: normal;
			vertical-align: middle;

			color: #000;
			border: 1px solid #000;
			background-color: #fff;
			animation: blinkBackground 2s infinite;

			@keyframes blinkBackground {
				0% {
					background-color: #fff;
					border-color: #000;
				}
				50% {
					background-color: transparent;
					border-color: #999;
				}
				100% {
					background-color: #fff;
					border-color: #000;
				}
			}
		}

		.btn {
			white-space: nowrap;
		}

		@include media-breakpoint-down(sm) {
			text-align: center;
			flex-direction: column;
			gap: 12px;
			// height: 85px;

			.label {
				margin-bottom: 8px;
			}
		}
	}

	.col-filter {
		display: flex;
		flex-direction: column;
		gap: 24px;
		font-size: 14px;

		.filter-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 16px;
			border-bottom: 1px solid var(--gray200, #e5e7eb);

			.title {
				font-size: $font-size-md;
				line-height: 40px;
				color: var(--gray-text-primary, #1f2937);
			}

			.btn {
				color: var(--gray-text-secondary, #4b5563);
				gap: 2px;

				&:disabled {
					opacity: 0.5;
					border-color: transparent;
				}
			}
		}

		.filter-body {
			display: flex;
			flex-direction: column;
			gap: 24px;
			font-size: 14px;
		}

		dt {
			color: var(--gray-text-primary, #1f2937);
			font-weight: 700;
			margin-bottom: 12px;
		}

		dd {
			display: flex;
			flex-wrap: wrap;
			gap: 6px;
			// background: red;

			.check-item-btn {
				white-space: nowrap;
				// flex: 1 1 calc(33.3333% - 6px);

				.label,
				label {
					display: flex;
					padding: 0 8px;
				}
			}
		}
	}

	.col-list {
		.list-control {
			// position: sticky;
			// top: 0;
			// margin-top: -20px;
			// padding-top: 20px;
			// z-index: 10;
			// background: #fff;

			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 24px;
			padding-bottom: 16px;
			border-bottom: 1px solid var(--gray200, #e5e7eb);

			.left {
				flex: 0 0 auto;
				display: flex;
				gap: 4px;
			}

			.right {
				flex: 0 0 auto;
				// flex: 1 1 auto;

				display: flex;
				align-items: center;
				// justify-content: flex-end;
				gap: 4px;

				color: var(--gray-text-disabled, #6b7280);
				font-size: 16px;
			}
		}

		.default-nodata {
			height: auto;
		}
	}

	@include media-breakpoint-up(lg) {
		.list-container {
			display: flex;
			gap: 40px;
		}

		.col-filter {
			position: sticky;
			top: 20px;
			max-height: 100vh;
			flex: 0 0 auto;

			// display: flex;
			// flex-direction: column;
			// gap: 24px;

			width: 254px;
			// font-size: 14px;

			// .filter-header {
			// 	display: flex;
			// 	justify-content: space-between;
			// 	align-items: center;
			// 	padding-bottom: 16px;
			// 	border-bottom: 1px solid var(--gray200, #e5e7eb);

			// 	.title {
			// 		font-size: $font-size-md;
			// 		line-height: 40px;
			// 		color: var(--gray-text-primary, #1f2937);
			// 	}

			// 	.btn {
			// 		color: var(--gray-text-secondary, #4b5563);
			// 		gap: 2px;

			// 		&:disabled {
			// 			opacity: 0.5;
			// 			border-color: transparent;
			// 		}
			// 	}
			// }

			// dt {
			// 	color: var(--gray-text-primary, #1f2937);
			// 	font-weight: 700;
			// 	margin-bottom: 12px;
			// }

			// dd {
			// 	display: flex;
			// 	flex-wrap: wrap;
			// 	gap: 6px;
			// 	// background: red;

			// 	.check-item-btn {
			// 		white-space: nowrap;
			// 		// flex: 1 1 calc(33.3333% - 6px);

			// 		.label,
			// 		label {
			// 			display: flex;
			// 			padding: 0 8px;
			// 		}
			// 	}
			// }
		}

		.col-list {
			flex: 1 1 auto;
			max-width: calc(100% - 294px);

			// .list-control {
			// 	display: flex;
			// 	align-items: center;
			// 	justify-content: space-between;
			// 	gap: 4px;
			// 	margin-bottom: 24px;
			// 	padding-bottom: 16px;
			// 	border-bottom: 1px solid var(--gray200, #e5e7eb);

			// 	.right {
			// 		display: flex;
			// 		align-items: center;
			// 		gap: 4px;
			// 	}
			// }

			// .default-nodata {
			// 	height: auto;
			// }
		}
	}

	@include media-breakpoint-between(md, lg) {
		.mode-public {
			.col-filter {
				.filter-body {
					flex-direction: row;
					flex-wrap: wrap;
					dl {
						flex: 1 1 40%;
					}
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.col-filter {
			position: sticky;
			top: 0;
			z-index: 10;
			// height: 300px;
			padding-bottom: 24px;
			border-bottom: 1px solid var(--gray200, #e5e7eb);

			background: #fff;
			// box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);

			gap: 12px;

			.filter-header {
				border-bottom: 0;
				padding: 0;
			}

			// .filter-body {
			// 	display: none;
			// }
		}

		.col-list {
			margin-top: 20px;

			.list-control {
				display: none;
				flex-direction: column;
				padding-bottom: 24px;

				.left {
					width: 100%;
					// display: block;
				}

				.form-control-md {
					width: 100%;
				}
			}

			.default-nodata {
				padding: 40px 0;
				min-height: auto;
			}
		}

		// .page-body {
		// 	display: flex;
		// 	flex-direction: column;
		// }

		// .list-container {
		// 	order: 1;
		// }

		// .top-info {
		// 	order: 2;
		// 	margin: 40px 0 0;
		// }
	}
}

.data-item {
	display: flex;
	flex-direction: column;
	gap: 6px;

	position: relative;
	border-radius: 16px;
	padding: 24px 0;
	// padding-right: 16px + 8px;

	.link {
		display: flex;
		border-radius: 8px;
	}

	dt {
		margin-bottom: 6px;
	}

	.icon {
		position: relative;
		width: 48px;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		font-size: 24px;
		line-height: 1;
		margin-bottom: 8px;

		.badge {
			position: absolute;
			right: 0;
			top: 0;
			margin: -1px;

			color: $red500;
			background: currentColor;

			width: 5px;
			height: 5px;
			border-radius: 50%;
			overflow: hidden;
		}
	}

	.subject {
		display: flex;
		align-items: center;
		gap: 8px;
		font-size: 18px;
		font-weight: bold;
	}

	.content {
		// word-break: break-all;
		color: var(--gray-text-secondary, #4b5563);
		font-size: 16px;
	}

	&:hover,
	&:focus-within {
		.subject {
			color: $font-primary;
			text-decoration: underline;
			text-underline-offset: 3px;
		}
		.content {
			color: $font-black;
		}
	}

	.info {
		margin-top: 12px;
		display: flex;
		flex-wrap: wrap;
		gap: 6px;
		font-size: $font-size-sm;

		span {
			display: flex;
			padding: 2px 6px;
			align-items: center;
			// gap: 4px;
			border-radius: 4px;
			border: 1px solid var(--gray300, #d1d5db);

			color: var(--gray-text-secondary, #4b5563);
			font-size: 14px;
		}

		.category {
			border-color: rgba(#000, 0.04);
		}
	}

	.btn-group {
		margin-top: 12px;
	}

	// .ico-right {
	// 	position: absolute;
	// 	right: 0;
	// }

	@include media-breakpoint-up(lg) {
		padding-left: 48px + 16px;

		.icon {
			position: absolute;
			left: 0;
			top: 24px;
		}

		// .ico-right {
		// 	position: absolute;
		// 	right: 0;
		// }
	}
}
