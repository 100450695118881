// 서비스 이용약관
.agreement-popup,
.privacy-popup {
	font-size: $font-size-sm;
	font-weight: 400;
	line-height: 130%;
	letter-spacing: -0.14px;
	color: $font-default;

	.popup-header {
		&::before {
			left: 24px;
			width: calc(100% - 48px);
		}
	}

	@include media-breakpoint-up(md) {
		.popup-header {
			min-height: 72px;
			padding: 8px 64px 8px 40px;
			&::before {
				left: 40px;
				width: calc(100% - 80px);
			}
		}

		.popup-body {
			padding: 24px 40px 24px;
			padding-right: 24px;
		}

		.btn-close-popup {
			right: 40px;
			top: 24px;
		}
	}
}

.agreement-container {
	padding: 24px;

	.agreement-title {
		margin-bottom: 12px;
	}

	@at-root .agreement-article {
		white-space: pre-wrap;

		strong {
			margin-top: 0.5em;
			margin-bottom: 0.3em;
			display: inline-block;
		}
	}
}
