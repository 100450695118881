.tally-iframe {
	position: relative;

	&:after {
		content: "";
		right: 0;
		bottom: 0;
		position: absolute;
		width: 100px;
		height: 40px + 25px;
		background: #fff;
		// background: red;

		@include media-breakpoint-up(md) {
			.modal-md & {
				width: 240px;
			}
		}
	}

	&.thank-you-page {
		@include media-breakpoint-up(md) {
			&:after {
				width: 224px;
			}
		}
	}
}

.send-feedback-popup {
	.popup-inner {
		min-height: auto;
	}

	.loading-area {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 376px;
		overflow: hidden;
	}

	iframe {
		height: 0;

		&:not(.loaded) {
			position: absolute;
		}
	}
}

.send-survey-popup {
	.popup-inner {
		min-height: auto;
	}

	.popup-body {
		padding: 16px;
		// background: #f14800;
	}

	.dimed {
		background: rgba(0, 0, 0, 0.5);
	}

	.loading-area {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 376px;
		overflow: hidden;
	}

	&.modal-sm {
		.tally-iframe {
			&::after {
				width: 60px;
			}
		}

		@include media-breakpoint-down(sm) {
			.popup-inner {
				width: 100%;
				height: 100%;
				max-height: none !important;
				margin: 0;
				border-radius: 0;
			}
		}
	}

	iframe {
		height: 0;

		&:not(.loaded) {
			position: absolute;
		}
	}
}
