.html-preview {
	$color-warning: $orange600;
	$font-color-warning: #fff;
	$color-editing: $red500;
	$font-color-editing: #fff;

	font-family: $font-family-base;
	// counter-reset: a11yWarningCounter;

	.a11y-warning,
	.a11y-editing,
	*[contenteditable="true"] {
		opacity: 1 !important;
		outline: none;
	}

	.a11y-warning {
		.passed & {
			&:is(th, td) {
				&::before {
					// display: none;
					content: "요소";
					background-color: $green600;
				}
				&::after {
					border-color: $green600;
				}
			}
			&:is(caption) {
				border-color: $green600;
				&::before {
					color: $green600;
				}
				&::after {
					display: none;
					background-color: $green600;
				}
			}
		}
	}
	.sr-only {
		position: relative;
		left: auto;
		width: auto;
		height: auto;
		margin: 0 !important;
		font-size: unset;
		line-height: initial;
		overflow: visible;
	}
	caption {
		&.sr-only {
			margin-bottom: 8px !important;
		}
	}

	// .a11y-warning {
	// 	/* 카운터를 1씩 증가시키고 내용으로 표시 */
	// 	counter-increment: a11yWarningCounter;
	// }

	.react-transform-wrapper,
	.react-transform-component {
		width: 100% !important;
	}

	table {
		position: relative;
		table-layout: fixed;
		border-collapse: collapse;
		border-spacing: 0;

		width: auto;
		min-width: 100%;

		padding: 1px 2px 2px;
		border-radius: 3px;
		background: $gray300;
		border-left: hidden;
		border-right: hidden;

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background: $bg-default;
		}

		.step2 & {
			thead,
			tbody {
				opacity: 0.5;
				pointer-events: none;
			}
		}
	}

	caption {
		min-height: 58px;
		margin-bottom: 8px;
		padding: 8px 12px;
		border: 1px solid $gray300;
		border-radius: 8px;
		background: $gray200;
		font-weight: 500;
		font-size: $font-size-md;
		font-weight: 500;
		line-height: 21px;
		letter-spacing: -0.24px;
		text-align: left;
		color: $font-default;

		&::before {
			content: "caption";
			display: block;
			margin-right: 4px;
			margin-top: -2px;
			white-space: nowrap;
			font-weight: normal;
			font-size: $font-size-xs;
			color: $font-lightgray;
		}

		// &[contenteditable="true"],
		&.a11y-warning,
		&.a11y-editing {
			padding: 7px 11px;
			border-radius: 8px;
			border: 2px solid $color-warning;
			background: $gray200;
			&:before {
				content: "caption";
				color: $color-warning;
			}
			&:after {
				content: "! 요소";
				position: absolute;
				z-index: 10;
				top: 9px;
				left: 57px;
				// transform: translate(0, -100%);
				// margin-top: -2px;
				// max-width: 100px;
				white-space: nowrap;
				font-weight: normal;
				padding: 0 4px;
				border-radius: 4px;
				font-size: 12px;
				line-height: 16px;
				color: $font-color-warning;
				background: $color-warning;
			}
		}
		&:focus-within {
			border-color: $color-editing !important;
			box-shadow: 0px 4px 20px 0px rgba(239, 68, 68, 0.25);
			&:before {
				color: $color-editing !important;
			}
			&:after {
				display: block !important;
				content: "편집중" !important;
				border-color: $color-editing !important;
				background: $color-editing !important;
			}
		}

		.step2 & {
			padding: 7px 11px;
			border-radius: 8px;
			border: 1px solid $border-primary;
			background: $bg-lightprimary;
			font-weight: 700;
			&:before {
				content: "caption";
				color: $font-lightprimary;
			}
		}
	}

	thead,
	tbody {
		position: relative;

		&::after {
			content: "";
			position: absolute;
			top: -1px;
			left: 0;
			width: 100%;
			height: 2px;
			background: $bg-default;
		}
	}

	thead {
		& + tbody {
			&::after {
				display: none;
			}
		}
	}

	th,
	td {
		height: 48px;
		line-height: 18px;
		padding: 4px 16px;
		border: 0;
		background: #fff;
		border: 1px solid $gray300;
		vertical-align: middle;
		text-align: left;
		background: #fff;
		word-break: keep-all;

		&.a11y-warning,
		&.a11y-editing {
			position: relative;
			outline: none;
			// color: $color-warning;
			// background-color: $color-warning;
			// box-shadow: inset 0 0 5px $color-warning;
			&:before {
				// display: none;
				// content: "위반 " counter(a11yWarningCounter);
				content: "! 요소";
				position: absolute;
				z-index: 10;
				top: 0;
				left: 0;
				transform: translate(0, -100%);
				margin-top: -2px;
				max-width: 100px;
				white-space: nowrap;
				font-weight: normal;
				padding: 0 4px;
				border-radius: 4px;
				font-size: 12px;
				line-height: 16px;
				color: $font-color-warning;
				background: $color-warning;
			}
			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				border-radius: 8px;
				margin: -1px;
				border: 2px solid $color-warning;
				pointer-events: none;
				z-index: 10;
			}
		}

		// &.a11y-editing {
		// &[contenteditable="true"] {
		&:focus-within {
			color: $color-editing;
			// background: $color-editing;
			// box-shadow: inset 0 0 10px $color-editing;

			&::before {
				// content: "위반 " counter(a11yWarningCounter) " 편집중";
				display: block !important;
				content: "편집중" !important;
				border-color: $color-editing !important;
				background: $color-editing !important;
			}
			&:after {
				border-color: $color-editing !important;
				box-shadow: 0px 4px 20px 0px rgba(239, 68, 68, 0.25);
			}
		}
	}

	th {
		position: relative;
		background: $primary100;
	}

	th,
	td {
		> * {
			pointer-events: none;
			max-width: 100%;
		}

		input[type="text"],
		input[type="password"],
		input[type="email"],
		input[type="search"],
		input[type="number"],
		input[type="tel"],
		input[type="url"],
		select {
			border: 1px solid $gray300;
			height: $form-height-xs;
			padding: 2px 12px;
			border-radius: 4px;
			font-size: $font-size-xs;
		}
	}
}
