
.sidebarSearchInput{
    border: none;
    background-color: #e5e7eb;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.sidebarSearchInput::placeholder{
    font-size: 16px;
    color: #6b7280!important;
    vertical-align: middle;
    line-height: 24px;

}
.sidebarSearchInput:focus {
    outline: none; /* 포커스 시 기본 아웃라인 스타일 제거 */
    border: none; /* 포커스 시 보더 제거 */
  }